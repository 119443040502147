import React, { useState,useEffect } from 'react'
import moment from "moment";
import { PieChart, Pie, Cell, ComposedChart, Scatter, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
import Loader from '../../../presentationals/Loader/Loader.component';
import { getDueOverDueCountsAPI,getInvoicedPaidAmountMonthlyAPI,getOverDueAgeSummaryAPI } from './DebtListActionCreator';
const differenceDate = () => {
    let d = new Date()
    let todaysDate = d.getDate();
    return todaysDate - 1
}

const InitalDate = (type) => {
    // const FirstDay = moment().subtract(differenceDate(), 'days');
    const year = new Date().getFullYear();
    const FirstDay = moment(`${year}-01-01`);

    if (type === 'fromDate') {
        const fromdate = FirstDay.format('YYYY-MM-DD');
        return fromdate
    } else if (type === 'toDate') {
        const todate = moment(new Date).format('YYYY-MM-DD')
        return todate
    }
}

const sortDataDescending = (data) => {
    const intervalOrder = {
        "0-30 days": 0,
        "31-60 days": 1,
        "61-90 days": 2,
        "91-120 days": 3,
        "120+ days": 4
    };

    return data.sort((a, b) => intervalOrder[b.interval] - intervalOrder[a.interval]);
};

export default function KPIDebtManagementDashboard() {
    const [loader,setLoader] =useState(false)
    const [fromDate, setFromDate] = useState(InitalDate('fromDate'))
    const [toDate, setToDate] = useState(InitalDate('toDate'))
    const [nbfcFilter,setnbfcFilter] =useState('all')
    const [dueOverDueData ,setDueOverDueData] = useState({});
    const [BankBalanceamountDue ,setBankBalanceamountDue] = useState([]);
    const [InvoicedPaidData ,setInvoicedPaidData] = useState([]);
    const [OverDueAgeSummaryData ,setOverDueAgeSummaryData] = useState([]);
    const [OverDueDuePieData ,setOverDueDuePieData] = useState([]);

    const [data, setdata] = useState(
        {
            "currentBankBalance": 457000,
            "totalAmountDue": 567802,
            "totalSuppliers": 7,
            "overdueSuppliers": 7,
            "overdueAmountBySupplier": 45678,
            "creditorDays": 23,
            "creditorTarget": '36-3',
            "creditorTargetPercentage": '+4.85',
            "bankBalanceAmountData": [{
                Balance: 40000,
                Due: 24000,
            }],
            "invoicedPaidData":[
                {
                    name: 'January',
                    Invoiced: 4000,
                    Paid: 4500,
                    creditorDays: 200,
                },
                {
                    name: 'February',
                    Invoiced: 3000,
                    Paid: 3200,
                    creditorDays: 9200,
                },
                {
                    name: 'March',
                    Invoiced: 2000,
                    Paid: 2100,
                    creditorDays: 1220,
                },
                {
                    name: 'April',
                    Invoiced: 2780,
                    Paid: 2900,
                    creditorDays: 2200,
                },
                {
                    name: 'May',
                    Invoiced: 1890,
                    Paid: 2000,
                    creditorDays: 100,
                },
                {
                    name: 'June',
                    Invoiced: 2390,
                    Paid: 2600,
                    creditorDays: 3200,
                },
                {
                    name: 'July',
                    Invoiced: 3490,
                    Paid: 3600,
                    creditorDays: 4200,
                },
                {
                    name: 'August',
                    Invoiced: 2000,
                    Paid: 2200,
                    creditorDays: 2200,
                },
                {
                    name: 'September',
                    Invoiced: 2780,
                    Paid: 2900,
                    creditorDays: 500,
                },
                {
                    name: 'October',
                    Invoiced: 1890,
                    Paid: 2000,
                    creditorDays: 1000,
                },
                {
                    name: 'November',
                    Invoiced: 2390,
                    Paid: 2500,
                    creditorDays: 1400,
                },
                {
                    name: 'December',
                    Invoiced: 3490,
                    Paid: 3700,
                    creditorDays: 1100,
                },
            ],
            "creditorsData": [
                {
                    name: 'creditorName',
                    due: 1200,
                    overdue: 1500,
                },
                {
                    name: 'creditorName',
                    due: 1400,
                    overdue: 2500,
                }
            ],
            "dueOverdueCreditorData":{
                due:4567,
               overdue:56789,
               amount:567,
               totalAmount:5678
            },
            "ageSummary":[
                {
                    name: '120 Days',
                    due: 1200,
                    overdue: 1500,
                }
            ]
        }
    )
    const handleChange = (e,type) => {
        console.log(e.target.value)
        if (type === "fromDate") {
            setFromDate(e.target.value)
        } else if(type === "toDate") {
            setToDate(e.target.value)
        }else if(type === "nbfc"){
            setnbfcFilter(e.target.value)
        }else if(type == 'applyfilter'){
            getDueOverDueCountsFunction()
            getInvoicedPaidAmountMonthlyFunction()
            getOverDueAgeSummaryFunction()
        }
    };

    useEffect(()=>{
        getDueOverDueCountsFunction()
        getInvoicedPaidAmountMonthlyFunction()
        getOverDueAgeSummaryFunction()
    },[])

    // const BankBalanceamountDue = [
    //     {
    //         name: '',
    //         Balance: 4000,
    //         Due: 2400,
    //         amt: 2400,
    //     },

    // ];
    // const InvoicedPaid = [
    //     {
    //         name: 'January',
    //         Invoiced: 4000,
    //         Paid: 4500,
    //         creditorDays: 200,
    //         amt: 2400,
    //     },
    //     {
    //         name: 'February',
    //         Invoiced: 3000,
    //         Paid: 3200,
    //         creditorDays: 9200,
    //         amt: 2210,
    //     },
    //     {
    //         name: 'March',
    //         Invoiced: 2000,
    //         Paid: 2100,
    //         creditorDays: 1220,
    //         amt: 2290,
    //     },
    //     {
    //         name: 'April',
    //         Invoiced: 2780,
    //         Paid: 2900,
    //         creditorDays: 2200,
    //         amt: 2000,
    //     },
    //     {
    //         name: 'May',
    //         Invoiced: 1890,
    //         Paid: 2000,
    //         creditorDays: 100,
    //         amt: 2181,
    //     },
    //     {
    //         name: 'June',
    //         Invoiced: 2390,
    //         Paid: 2600,
    //         creditorDays: 3200,
    //         amt: 2500,
    //     },
    //     {
    //         name: 'July',
    //         Invoiced: 3490,
    //         Paid: 3600,
    //         creditorDays: 4200,
    //         amt: 2100,
    //     },
    //     {
    //         name: 'August',
    //         Invoiced: 2000,
    //         Paid: 2200,
    //         creditorDays: 2200,
    //         amt: 2300,
    //     },
    //     {
    //         name: 'September',
    //         Invoiced: 2780,
    //         Paid: 2900,
    //         creditorDays: 500,
    //         amt: 2000,
    //     },
    //     {
    //         name: 'October',
    //         Invoiced: 1890,
    //         Paid: 2000,
    //         creditorDays: 1000,
    //         amt: 2181,
    //     },
    //     {
    //         name: 'November',
    //         Invoiced: 2390,
    //         Paid: 2500,
    //         creditorDays: 1400,
    //         amt: 2500,
    //     },
    //     {
    //         name: 'December',
    //         Invoiced: 3490,
    //         Paid: 3700,
    //         creditorDays: 1100,
    //         amt: 2100,
    //     },
    // ];

    const creditorsData = [
        {
            name: 'Tax',
            due: 1200,
            overdue: 1500,
            amt: 1200
        },
        {
            name: 'Leave Creative',
            due: 1400,
            overdue: 2500,
            amt: 1200
        },
        {
            name: 'Rastor Bike comp',
            due: 1600,
            overdue: 4500,
            amt: 1230
        },
        {
            name: 'Cylearlope',
            due: 2600,
            overdue: 5500,
            amt: 1230
        },
        {
            name: 'Cyleardlope',
            due: 2600,
            overdue: 5500,
            amt: 1230
        }
    ];
    // const AgesummaryData = [
    //     {
    //         name: '120 Days',
    //         due: 1200,
    //         overdue: 1500,
    //         amt: 1200
    //     }
    // ];

    // const OverdueDueCreditorsData = [
    //     { name: 'A', value: 80, color: '#232F63' },
    //     { name: 'B', value: 45, color: '#DBE9FFB2' },
    // ];

    const getDueOverDueCountsFunction =()=>{
        const formattedFromDate = moment(fromDate).format('DD-MM-YYYY');
        const formattedToDate = moment(toDate).format('DD-MM-YYYY');
        getDueOverDueCountsAPI(formattedFromDate,formattedToDate,'','',(callback)=>{
            if(callback && callback?.status == 200 ){
                setDueOverDueData(callback?.data)
                const graphData = [
                    {
                    name :'',
                    OverDueAmount: callback?.data?.overdueAmount || 0,
                    TotalLoanAmount: callback?.data?.totalApprovedAmount || 0,
                }
            ]
            const OverdueDueCreditorsData = [
                { name: 'Due Amount', value: callback?.data?.dueAmount, color: '#232F63' },
                { name: 'Overdue Amount', value: callback?.data?.overdueAmount, color: '#DBE9FFB2' },
            ];
            setOverDueDuePieData(OverdueDueCreditorsData)
            setBankBalanceamountDue(graphData)
        }
        })
    }
    const getInvoicedPaidAmountMonthlyFunction =()=>{
        const formattedFromDate = moment(fromDate).format('DD-MM-YYYY');
        const formattedToDate = moment(toDate).format('DD-MM-YYYY');
        setLoader(true)
        getInvoicedPaidAmountMonthlyAPI(formattedFromDate,formattedToDate,'','',(callback)=>{
            setLoader(false)
            if(callback && callback?.status == 200 && callback?.data?.length > 0){
                setInvoicedPaidData(callback?.data)
            }
        })
    }
    const getOverDueAgeSummaryFunction =()=>{
        const formattedFromDate = moment(fromDate).format('DD-MM-YYYY');
        const formattedToDate = moment(toDate).format('DD-MM-YYYY');
        getOverDueAgeSummaryAPI(formattedFromDate,formattedToDate,'','',(callback)=>{
            if(callback && callback?.status == 200 && callback?.data?.length > 0){
                sortDataDescending(callback?.data)
                setOverDueAgeSummaryData(sortDataDescending(callback?.data))
            }
        })
    }
    return (
        <>
            <div className="KPIDebtManagementDashboard">
                {
                    loader ? <Loader /> :''
                }
                <div className="MonthDateFilter" style={{ marginTop: '20px' }}>
                    <div className="row">
                        <div className="col-lg-2 col-md-3 col-xs-12">
                            <div className="inputGrp">
                                <label htmlFor="" className='labeltag'>From</label>
                                <input type="date" id='customDateInput' className='MonthinputTag' name="fromDate" value={fromDate} onChange={(e)=>handleChange(e,'fromDate')}  style={{width:'100%'}}/>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-xs-12">
                            <div className="inputGrp">
                                <label htmlFor="" className='labeltag'>To</label>
                                <input type="date" min={fromDate} id='customDateInput' className='MonthinputTag' name="toDate" value={toDate} onChange={(e)=>handleChange(e,'toDate')}  style={{width:'100%'}}/>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-xs-12">
                            <button className='FilterApply' onClick={(e)=>handleChange(e,'applyfilter')}>Apply Filter</button>
                        </div>
                        <div className="col-lg-2 col-md-3 col-xs-12" style={{float:'inline-end'}}>
                            <div className="inputGrp">
                                <label htmlFor="nbfc" className='labeltag'>NBFC</label>
                                <select className='MonthinputTag' id="nbfc" name="nbfc" value={nbfcFilter} onChange={(e)=>handleChange(e,'nbfc')} style={{width:'100%'}} >
                                    <option value="all">ALL</option>
                                    <option value="drp">DRP</option>
                                    <option value="citra">Citra</option>
                                </select>
                                 </div>
                        </div>
                    </div>
                </div>

                <div className="DebtDashboardBlock">
                    <div className="row">
                        <div className="col-lg-5 ">
                            <div className="row">
                                <div className="col-md-6 col-xs-12 newPadding">
                                    <div className="Datacard">
                                        <div className="Topsection">
                                            <h4>Current Due Amount</h4>
                                        </div>
                                        <div className="bottomsection" style={{ padding: '10px' }}>
                                            <h5>{dueOverDueData?.dueAmount || 0}</h5>
                                        </div>
                                    </div>
                                    <div className="Datacard">
                                        <div className="Topsection">
                                            <h4>Total Amount OverDue</h4>
                                        </div>
                                        <div className="bottomsection" style={{ padding: '10px' }}>
                                            <h5>{dueOverDueData?.overdueAmount || 0}</h5>
                                        </div>
                                    </div>
                                    <div className="Datacard" style={{ height: '165px' }}>
                                        <div className="Topsection">
                                            <h4>Overdue Applications</h4>
                                        </div>
                                        <div className="bottomsection" style={{ padding: '10px' }}>
                                            <h5 style={{ marginTop: '40px' }}><b className='fs-inc'>{dueOverDueData?.overdueCounts || 0}</b> out of <b className='fs-inc'>{dueOverDueData?.totalLoanCounts || 0}</b></h5>
                                            {/* <h5 style={{ marginBottom: '20px' }}><b className='fs-inc'>{dueOverDueData?.overdueCounts || 0}</b> out of <b className='fs-inc'>{dueOverDueData?.totalLoanCounts || 0}</b></h5> */}
                                            {/* <h5 className='fs-inc'><b>457.24K</b></h5> */}
                                        </div>
                                    </div>
                                    {/* <div className="Datacard" style={{ height: '147px' }}>
                                        <div className="Topsection">
                                            <h4>Creditor Days vs Target</h4>
                                        </div>
                                        <div className="bottomsection" style={{ padding: '10px' }}>
                                            <h5 className='fs-inc'><b>34</b></h5>
                                            <h5>Goal 36-3 (+ 4.85%)</h5>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="col-md-6 col-xs-12 newPadding">
                                    
                                    <div className="Datacard">
                                        <div className="Topsection">
                                            <h4>Loan vs OverDue Amount</h4>
                                        </div>
                                        <div className="bottomsection" style={{ padding: '10px' }}>
                                            <div className="charts" style={{ height: '316px' }}>
                                                <ResponsiveContainer width="100%" height="100%">
                                                    <BarChart
                                                        width={500}
                                                        height={300}
                                                        data={BankBalanceamountDue}
                                                        barSize={60}
                                                        margin={{
                                                            top: 20,
                                                            right: 30,
                                                            left: 40,
                                                            bottom: 5,
                                                        }}
                                                    >
                                                        <CartesianGrid strokeDasharray="3 3" />
                                                        <XAxis dataKey="name" />
                                                        <YAxis />
                                                        <Tooltip />
                                                        <Bar dataKey="OverDueAmount" stackId="a" fill="#EE7F41" radius={[0,0,5,5]} />
                                                        <Bar dataKey="TotalLoanAmount" stackId="a" fill="#4481d5" radius={[5,5,0,0]} />
                                                    </BarChart>
                                                </ResponsiveContainer>
                                            </div>
                                            <div className="customLegend">
                                                <div className='legendbox'>
                                                    <div className="square" style={{ backgroundColor: '#EE7F41' }}></div>
                                                    <h6>OverDue</h6>
                                                </div>
                                                <div className='legendbox'>
                                                    <div className="square" style={{ backgroundColor: '#4481d5' }}></div>
                                                    <h6>Loan</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 newPadding">
                            <div className="Datacard">
                                <div className="Topsection">
                                    <h4>Invoiced vs Received</h4>
                                </div>
                                <div className="bottomsection" style={{ padding: '10px' }}>
                                    <div className="charts" style={{ height: '316px' }}>
                                        <ResponsiveContainer width="100%" height="100%">
                                            <ComposedChart
                                                width={500}
                                                height={300}
                                                data={InvoicedPaidData}
                                                barSize={20}
                                                margin={{
                                                    top: 20,
                                                    right: 0,
                                                    left: 40,
                                                    bottom: 5,
                                                }}
                                            >
                                                <CartesianGrid vertical={false} />
                                                <XAxis dataKey="name" />
                                                <YAxis yAxisId="left" orientation="left" />
                                                {/* <YAxis yAxisId="right" orientation="right" /> */}
                                                <Tooltip />
                                                <Bar yAxisId="left" dataKey="expected" fill="#EE7F41" />
                                                <Bar yAxisId="left" dataKey="received" fill="#4481d5" />
                                                {/* <Scatter yAxisId="left" dataKey="creditorDays" fill="#223d64" strokeWidth={2} line shape="dot" /> */}
                                            </ComposedChart>
                                        </ResponsiveContainer>
                                    </div>
                                    <div className="customLegend" style={{ flexWrap: 'wrap' }}>
                                        <div className='legendbox'>
                                            <div className="square newone" style={{ backgroundColor: '#EE7F41' }}></div>
                                            <h6>Invoiced</h6>
                                        </div>
                                        <div className='legendbox'>
                                            <div className="square newone" style={{ backgroundColor: '#4481d5' }}></div>
                                            <h6>Received</h6>
                                        </div>
                                        {/* <div className='legendbox'>
                                            <div className="square newone" style={{ backgroundColor: '#223d64' }}></div>
                                            <h6>Average Creditor Days</h6>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {/* <div className="col-lg-4 col-md-6 col-xs-12 newPadding">
                            <div className="Datacard">
                                <div className="Topsection">
                                    <h4>Creditors As Per Product</h4>
                                </div>
                                <div className="bottomsection" style={{ padding: '10px' }}>
                                    <div className="charts" style={{ height: '351px' }}>
                                        <ResponsiveContainer width="100%" height="100%">
                                            <BarChart
                                                width={560}
                                                height={300}
                                                data={creditorsData}
                                                barSize={15}
                                                layout="vertical"
                                                margin={{
                                                    top: 20, right: 10, left: 10, bottom: 10,
                                                }}
                                            >
                                                <CartesianGrid vertical={false} horizontal={false} />
                                                <XAxis type="number" />
                                                <YAxis type="category" dataKey="name" />
                                                <Tooltip />
                                                <Bar type="monotone" dataKey="due" fill="#EE7F41" />
                                                <Bar type="monotone" dataKey="overdue" fill="#4481d5" />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>
                                    <div className="customLegend">
                                        <div className='legendbox'>
                                            <div className="square" style={{ backgroundColor: '#EE7F41' }}></div>
                                            <h6>Due</h6>
                                        </div>
                                        <div className='legendbox'>
                                            <div className="square" style={{ backgroundColor: '#4481d5' }}></div>
                                            <h6>Overdue</h6>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-md-6 col-xs-12 newPadding">
                            <div className="Datacard">
                                <div className="Topsection">
                                    <h4>Overdue & Due Creditors</h4>
                                </div>
                                <div className="bottomsection" style={{ padding: '10px' }}>
                                    <div className="charts" style={{ height: '389px' }}>
                                        <ResponsiveContainer width="100%" height="100%">
                                            <PieChart width={400} height={400}>
                                                <Pie
                                                    dataKey="value"
                                                    startAngle={180}
                                                    endAngle={0}
                                                    cy="60%"
                                                    cornerRadius={3}
                                                    data={OverDueDuePieData}
                                                    width={560}
                                                    height={300}
                                                    innerRadius={100}
                                                    outerRadius={150}
                                                    fill="#223d64"
                                                    stroke="none"

                                                >
                                                    {OverDueDuePieData.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={entry.color} />
                                                    ))}
                                                </Pie>
                                                <text
                                                    x="50%"
                                                    y='40%'
                                                    textAnchor="middle"
                                                    dominantBaseline="middle"
                                                    fill="#000"
                                                    style={{ fontSize: '18px', fontWeight: 'bold' }}
                                                >
                                                    {OverDueDuePieData[0]?.value || 0}
                                                </text>
                                                <text
                                                    x="50%"
                                                    y='46%'
                                                    textAnchor="middle"
                                                    dominantBaseline="middle"
                                                    fill="#000"
                                                    style={{ fontSize: '16px' }}
                                                >
                                                    Due
                                                </text>
                                                <text
                                                    x="50%"
                                                    y='54%'
                                                    textAnchor="middle"
                                                    dominantBaseline="middle"
                                                    fill="#000"
                                                    style={{ fontSize: '18px', fontWeight: 'bold' }}
                                                >
                                                     {OverDueDuePieData[1]?.value || 0}
                                                </text>
                                                <text
                                                    x="50%"
                                                    y='60%'
                                                    textAnchor="middle"
                                                    dominantBaseline="middle"
                                                    fill="#000"
                                                    style={{ fontSize: '16px' }}
                                                >
                                                    Overdue
                                                </text>

                                            </PieChart>
                                        </ResponsiveContainer>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xs-12 newPadding">
                            <div className="Datacard">
                                <div className="Topsection">
                                    <h4>Age Summary</h4>
                                </div>
                                <div className="bottomsection" style={{ padding: '10px' }}>
                                    <div className="charts" style={{ height: '351px' }}>
                                        <ResponsiveContainer width="100%" height="100%">
                                            <BarChart
                                                width={560}
                                                height={300}
                                                data={OverDueAgeSummaryData}
                                                barSize={20}
                                                layout="vertical"
                                                margin={{
                                                    top: 20, right: 10, left: 5, bottom: 10,
                                                }}
                                            >
                                                <CartesianGrid vertical={false} horizontal={false} />
                                                <XAxis type="number" />
                                                <YAxis type="category" dataKey="interval" />
                                                <Tooltip />
                                                <Bar type="monotone" dataKey="emiCount" fill="#EE7F41" />
                                                <Bar type="monotone" dataKey="amount" fill="#4481d5" />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>
                                    <div className="customLegend">
                                        <div className='legendbox'>
                                            <div className="square" style={{ backgroundColor: '#EE7F41' }}></div>
                                            <h6>EMI Count</h6>
                                        </div>
                                        <div className='legendbox'>
                                            <div className="square" style={{ backgroundColor: '#4481d5' }}></div>
                                            <h6>Amount</h6>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const BarchartCustomLabel = (props) => {
    const { x, y, width, height, value } = props;
    return (
        <text x={x + width / 2} y={y + height / 2} fill="white" textAnchor="middle" dominantBaseline="central">
            {value}
        </text>
    );
};
