// console.log(process.env)
// const BASE_URL = 'http://192.168.1.17:8070/';
const BASE_URL = 'https://testbackend.phocket.co.in/';

// const BASE_URL = process.env.REACT_APP_URL

// const BASE_URL = 'https://adminsupport.phocket.co.in/';

//  const BASE_URL = 'http://2fab5b20a8b4.ngrok.io/'

// const BASE_URL = 'https://adminbackend.phocket.co.in/';

// const BASE_URL = 'https://phocket.in/';
// const BASE_URL = window.location.href.split('phocket.in/')[0] + 'phocket.in/';
// const BASE_URL = 'http://localhost:8070/';
// const BASE_URL = 'http://192.168.1.17:8070/';
// const BASE_URL = 'http://172.20.10.3:8070/';
// const BASE_URL = 'hngngrokrokttp://192.168.0.94:8070/';05-11-2022	
// const BASE_URL ='http://192.168.1.120:8070/'
// const BASE_URL ='https://d9e9-223-190-95-75.in.ngrok.io/'
// const BASE_URL ='http://192.168.1.5:8070/'; 
// const BASE_URL='https://33a2-223-190-95-102.in.ngngrokrok.io/';
// const BASE_URL = 'https://testcardbackend.phocket.in'

const BASE_URL_ANALYSIS = BASE_URL;
const BASE_URL_MARKETING = BASE_URL;
const BI_URL = 'http://3.110.107.174:8002/'
const ANALYSIS_URL = 'http://35.239.92.88:8141/api/'
const STATEMENT_URL = 'https://analytics.phocket.co.in/'
// const STATEMENT_URL = 'http://35.239.92.88:8800/'
const PSYCHOMETRIC_URL = 'https://psychometric.phocket.in/api/'
// const BI_GRAPH_URL = 'http://35.239.233.129:5004/api/';
const BI_GRAPH_URL = 'https://testanalytics.phocket.co.in/api/'
const CARD_URL = 'https://testcardbackend.phocket.in'
// const FINANCE_BACKEND = 'http://192.168.1.14:8080/'
const FINANCE_BACKEND = 'https://financebackend.phocket.co.in/'

// const CARD_URL='https://33a2-223-190-95-102.in.ngrok.io'
let ESIGN_KEY = ''
// const CARD_URL ='http://192.168.1.4:8070'
if (BASE_URL === 'https://test.phocket.in/' || BASE_URL === 'https://testcardbackend.phocket.in' || BASE_URL.includes('192.168')) {

    ESIGN_KEY = 'phocket-infotech-private-limited_esign_uat'
} else {
    ESIGN_KEY = 'phocket-infotech-private-limited_esign_production'
}


// 

const APIS = {
    BASE_URL: BASE_URL,
    CARD_URL: CARD_URL,

    GET_ADMIN_USER: BASE_URL + 'getAdminUser',

    GET_CAPTCHA: BASE_URL + 'generateRandonCaptcha',

    NBFC_LIST: BASE_URL + 'getListOfNbfc',

    GET_INCOME_COLLECTION_API: BASE_URL + 'getIncomeCollectedAnalytics?startDate=',

    GET_ASSET_QUALITY: BASE_URL + 'getAssetQuality',
    // admin and user
    PAN_VERIFY_CUSTOMER: BASE_URL + 'userDetails/panVerify?userId=',
    // get userDetail by userId
    USER_DETAIL_BY_ID: BASE_URL + 'userDetails/userDetailById?userId=',
    // upload image and pdf
    UPLOAD_FILE: BASE_URL + 'multipleuploadpdf',
    // edit basic detail
    USER_BASIC_DETAIL: BASE_URL + 'userDetails/basicDetailAdmin',
    // edit address detail
    USER_ADDRESS_DETAIL: BASE_URL + 'userDetails/addressDetail',
    // edit employment detail
    USER_EMPLOYMENT_DETAIL: BASE_URL + 'userDetails/employmentDetail',
    // edit bank detail
    FORM_BANK_DETAIL: BASE_URL + 'userDetails/addAccountDetails',
    // fetch all pincode and bak branch
    ZIP_DETAIL: BASE_URL + 'userDetails/codeDetail?code=',
    // edit loan details
    LOAN_UPDATE: BASE_URL + 'userDetails/updateloanDetail',
    // follow up status change
    SAVE_FOLLOW_UP_STATUS: BASE_URL + 'loan/applicationStatus?loanId=',
    // password check in doc upload
    DOC_PASSWORD_PROTECT: BASE_URL + 'docPasswordProtect',
    //check loan eligibility
    CHECK_LOAN_ELIGIBILITY: BASE_URL + 'userDetails/getPersonalAdvanceFlag?userId=',
    // transaction save
    SAVE_TRANSACTION: BASE_URL + 'saveTransaction',
    // get transaction by userId
    GET_TRANSACTIONS: BASE_URL + 'getTransaction?userId=',
    // check pan number in form
    CHECK_PEN_NUMBER: BASE_URL + 'userDetails/newPanNumberExist?panNumber=',

    EMI_CALCULATOR: BASE_URL + 'getmonthemiamount/',

    BULLET_TO_EMI_CONVERSION: BASE_URL + 'getbulletemitopayuser',

    SAVE_CONVERTED_EMI: BASE_URL + 'savebulletemitopayuser',

    CHECK_REPEAT_USER: BASE_URL + 'repeatUser?userId=',

    //user

    // login mobile number save
    SEND_MOBILE_NUMBER: BASE_URL + 'otpMessage/mobile?mobile=',
    // for OTP check
    SEND_OTP: BASE_URL + 'otpMessage/otp?otp=',
    // edit loan detail
    USER_LOAN_DETAIL: BASE_URL + 'userDetails/loanDetail',
    // save feedback data
    USER_FEEDBACK: BASE_URL + 'userDetails/saveFeedbackDetail',
    // form document page next button
    FORM_DOCUMENT_NEXT: BASE_URL + '/userDetails/thanks?userId=',
    // form document page next button
    FORM_DOCUMENT_PAGE_NEXT: BASE_URL + 'userDetails/updateDocumentFormStatus?userId=',
    // login with google
    GOOGLE_LOGIN: BASE_URL + 'authorize?userId=',
    // login with facebook
    FACEBOOK_LOGIN: BASE_URL + 'authorize/facebook?userId=',
    // get notification by userId
    GET_NOTIFICATION: BASE_URL + 'getNotificationByUserId?userId=',
    // notification count 0
    READ_NOTIFICATION: BASE_URL + 'readNotification?userId=',
    // delete notification by notification id
    DELETE_NOTIFICATION_BY_ID: BASE_URL + 'deleteNotificationById?id=',
    // payment page get amount
    GET_PAID_AMOUNT: BASE_URL + 'getPaidAmount?loanId=',
    // get app from website
    GET_APP: BASE_URL + 'getApp?mobileNumber=',
    // offer by user id
    OFFER_BY_USERID: BASE_URL + 'offerByUserId?userId=',
    // check coupon by user id and coupon code
    CHECK_COUPON_CODE: BASE_URL + 'checkCoupanByUserIdCoupanCode?userId=',
    //get company list by company name
    GET_COMPANY_NAME: BASE_URL + 'userDetails/getCompanyList?companyName=',
    // get state and city by pincode
    GET_STATE_CITY: BASE_URL + 'userDetails/getAreaPincode?pinCode=',
    // merge profile with new user id
    // MERGE_PROFILE: BASE_URL + 'mergeProfile?newUserId=',

    // verify official mail id
    VERIFY_OFFICIAL_MAIL: BASE_URL + 'verifyofficialmail/',
    // save location from website
    SAVE_LOCATION_FROM_WEB: BASE_URL_ANALYSIS + 'userDetails/saveLocationFromWeb',

    //admin
    GET_COMMENT_FOR_FAILURE: BASE_URL + 'enashAndEsign/updatePaymentIdForNach',
    // promotional data
    GET_PROMOTIONAL_DATA: BASE_URL + '/getpromotionaldata?pageNumber=',
    // promotional data filtration
    GET_FILTER_DATA: BASE_URL + '/getfilteroptions',
    // send mail to promotional data
    SEND_PROMOTIONAL: BASE_URL + '/sendEmail',
    // all detail in applicaion status page
    GET_ALL_DETAIL: BASE_URL + 'userDetails/alluserDetail?pageNumber=',
    // get user detail by email id
    GET_ALL_DETAIL_BY_EMAIL: BASE_URL + 'userDetails/allDetailByEmail?emailId=',
    // get user detail by mobile number
    GET_ALL_DETAIL_BY_MOBILE: BASE_URL + 'userDetails/allDetailByNumber?mobileNumber=',
    // // filter by application number
    // LOAN_FILTER_BY_APPLICATION: BASE_URL + 'loan/listByApplication?applicationId=',
    // // filter by emailId
    // LOAN_FILTER_BY_EMAILID: BASE_URL + 'loan/listByEmail?emailId=',
    // // filter by mobile number
    // LOAN_FILTER_BY_NUMBER: BASE_URL + 'loan/listByNumber?mobileNumber=',
    // get admin notification
    GET_CRM_NOTIFICATION: BASE_URL + 'getNotificationDetails',
    // notification count 0
    GET_READ_NOTIFICATION: BASE_URL + 'getAdminNotificationRead?type=',
    // delete notification one by one    
    DELETE_CRM_NOTIFICATION_ONE: BASE_URL + 'getNotificationDelete?id=',
    // delete all notifications
    DELETE_CRM_NOTIFICATION: BASE_URL + 'deleteCrmNotifications',
    // get all loans
    GET_LOAN_APPROVAL_LEAD_TESTING: BASE_URL + 'userDetails/getAllUserLoanApprovalLead?status=',
    // get all loans
    // GET_LOAN_APPROVAL_LEAD: BASE_URL + 'loanLeadTesting?status=',
    // get loanAdd leads
    GET_LOAN_ADDA_LEAD: BASE_URL + 'getLoanApprovalLeadByThirdParty?status=',
    // loan status change by action page
    LOAN_SANCTION: BASE_URL + 'loan/loanSanction',
    // role assign
    ROLE_ASSIGN: BASE_URL + 'roleAssign',

    GET_LEAD_BY_MOBILE_NUMBER: BASE_URL + 'getNewLeadsByMobileNumbers',
    // show all employees role
    ROLE_ASSIGN_VIEW: BASE_URL + 'roleAssignView?emailId=',
    // admin login
    ADMIN_LOGIN: BASE_URL + 'adminrole',
    // status change in communication page
    PUSH_NOTIFICATION: BASE_URL + 'pushNotification',
    // view all messages
    VIEW_USER_MSG: BASE_URL + 'getsms?userId=',
    // view all locations
    VIEW_USER_LOCATION: BASE_URL + 'getlocation?userId=',
    // logs view in admin
    GET_COMMENT_ADMIN: BASE_URL + 'getComment?userId=',
    // action page loan disbursed
    // DISBERSE_API: BASE_URL + 'loan/disberse?loanId=',

    DISBERSE_API: BASE_URL + 'disbursedStatus?loanId=',
    // save button in document link send via mail
    DOC_MAIL_SAVE: BASE_URL + 'loan/uploadDocumentData?userId=',
    // action page loan paid
    // PAID_STATUS: BASE_URL + 'loan/paidStatus',

    PAID_STATUS: BASE_URL + 'paidStatuss',

    // update transaction
    UPDATE_TRANSACTION: BASE_URL + 'updateTransaction?transactionId=',
    // push notification in admin
    REGISTER_PUSH_NOTIFICATION: BASE_URL + 'register/push?token=',

    REGISTER_TO_GROUP_NOTIFICATION: BASE_URL + '/register/push/group?token=',
    //get loan agreement in admin
    // GET_LOAN_AGREEMENT: BASE_URL + 'enashAndEsign/getloanUrl?loanId=',
    GET_LOAN_AGREEMENT: BASE_URL + 'enashAndEsign/getDocxLoanAgreement?loanId=',
    // all loan status
    GET_ALL_STATUS: BASE_URL + 'getAllStatus',
    // export excel in admin
    EXPORT_EXCEL: BASE_URL + 'getDataByStatus?status=',
    // view all role list
    GET_ALL_USER_ROLE: BASE_URL + 'getAllRole',
    // send name and url for bank statement
    // SEND_BANK_DATA: BASE_URL + 'getStatementAnalysis',
    SEND_BANK_DATA: STATEMENT_URL + 'getDetails',

    USER_MSG_ANALYSIS: STATEMENT_URL + 'getmsg?userid=',
    //get bank data
    GET_BANK_DATA: BASE_URL + 'finBoxGetUserStatement?userId=',
    //get contact data
    GET_ACCOUNT_FINBOX: BASE_URL + 'finBoxGetContacts?userId=',
    // get all bank list
    GET_BANK_LIST: BASE_URL + 'getAllBank',
    // save promotional data
    SAVE_PROMOTATIONAL_OFFER_DATA: BASE_URL + 'savePromotational',
    // get all saved promotional data
    GET_ALL_PROMOTATION_OFFER: BASE_URL + 'getPromotational',
    // get status wise loan count by date
    FORM_STATUS_COUNT_BY_GIVEN_DATE: BASE_URL + 'formStatusCountByGivenDate?date=',
    // get status wise loan count by month
    FORM_STATUS_COUNT_BY_GIVEN_MONTH: BASE_URL + 'formStatusCountByGivenMonth?year=',
    // get status wise loan count between two days
    FORM_STATUS_COUNT_BETWEEN_TWO_DATES: BASE_URL + 'formStatusCountBetweenTwoDates?startdate=',

    GET_ALL_STATUS_BULLET_EMI: BASE_URL + 'getAllStatusCount?startdate=',
    // bulk status change
    BULK_STATUS_CHANGE: BASE_URL + 'bulkStatus',
    // incomplete application count
    GET_INCOMPLETE_APP_COUNT: BASE_URL + 'getIncompleteApplicationBetweenDates?startdate=',

    GET_INCOMPLETE_COUNT_BY_FILTER: BASE_URL + 'getIncompleteCountByFilter?startDate=',
    // send mail to incomplete applicaion
    SEND_MAIL_FOR_INCOMPLETE: BASE_URL + 'sendingMailforIncompleteForms',
    // show feedback by userId
    GET_FEEDBACK_BY_USERID: BASE_URL + 'userDetails/getAllFeedbackDetails?userId=',
    // bulk communication by status
    COMMUNICATE_BY_STATUS: BASE_URL + 'sendingMailAccordingToStatus',
    // bulk communication by status LOGS
    COMMUNICATE_BY_STATUS_LOGS: BASE_URL + 'getBulkLog',
    // incoming loan payment next week
    INCOMING_PAYMENT_BY_WEEK: BASE_URL + 'getIncomingPaymentDetailsbyWeek?count=',
    // last 5 days document upload
    LATEST_DOCUMENT_UPLOAD: BASE_URL + 'getLastFiveDaysDoc?pageNumber=',
    // Emi incoming loan payment next week
    EMI_INCOMING_PAYMENT_BY_WEEK: BASE_URL + 'getEMIIncomingPaymentDetailsbyWeek?count=',
    // incoming loan payment by selected date
    INCOMING_PAYMENT_BY_DATE: BASE_URL + 'getIncomingPaymentDetailsbyGivenDate?date=',
    // Emi incoming loan payment by selected date
    EMI_INCOMING_PAYMENT_BY_DATE: BASE_URL + 'getEMIIncomingPaymentDetailsbyGivenDate?date=',
    // all due loan payment
    DUE_PAYMENT_BY_WEEK: BASE_URL + 'getDuePaymentDetailsbyWeek?count=',
    // all emi due loan payment
    EMI_DUE_PAYMENT_BY_WEEK: BASE_URL + 'getEMIDuePaymentDetailsbyWeek?count=',
    // due loan payment by selected date
    DUE_PAYMENT_BY_DATE: BASE_URL + 'getDuePaymentDetailsbyGivenDate?date=',
    // emi due loan payment by selected date
    EMI_DUE_PAYMENT_BY_DATE: BASE_URL + 'getEMIDuePaymentDetailsbyGivenDate?date=',
    // save application number and cheque number for field boys
    SAVE_APPNO_CHECK: BASE_URL + 'applicationToCheckSave',
    // todays loan count
    TODAYS_LOAN_COUNT: BASE_URL + 'todaysLoanCount?date=',
    // assign loan task for members
    ASSIGN_LOAN: BASE_URL + 'assignLoan?loanId=',
    ///load cache data
    LOAD_CACHE: BASE_URL + 'phocketReloadCacheData',
    // loan process tracking
    STATUS_ACTIVITY_DATA: BASE_URL + 'loan/getLoanTrack?loanId=',
    // get all admin roles
    GET_ALL_ADMIN_ROLE: BASE_URL + 'allUserRole?emailId=',
    // save score by company name and userId
    SAVE_SCORE_BY_COMPANY_USERID: BASE_URL + 'creditScorebyUserId?userId=',
    // FILTER_EXPORT_EXCEL: BASE_URL + 'sheetDownload?status=',
    FILTER_EXPORT_EXCEL: BASE_URL + 'sheetDownloadbyColumn',
    //Get Credit Rating
    GET_RATING_FOR_CUSTOMER_INFO: BASE_URL + 'creditScorebyUserId?userId=',
    // Update credit rating
    UPDATE_CREDIT_SCORE: BASE_URL + 'getcreditScoreList',
    // column list for export to excel
    LIST_FOR_EXCEL_REPORT: BASE_URL + 'sheetColumnList',
    // excel sheet by choice
    SHEET_BY_CHOICE: BASE_URL + 'sheetDownload',
    // enash verification
    LOAN_VARIFICATION: BASE_URL + 'enashAndEsign/verifyRegistrationEnash?loanId=',
    // schedule enash
    LOAN_TRANSACTION_SCHEDULE: BASE_URL + 'enashAndEsign/scheduleTransactionEnash?loanId=',
    // calculate payble amount
    CALC_UTILITY: BASE_URL + 'getPayableAmount',
    // All credit scores
    ALL_CREDIT_SCORE: BASE_URL + 'getAllCustomerCreditScore',
    // save user action logs
    SAVE_USER_ACTION_LOGS: BASE_URL + 'userActionLog',
    // change admin password
    // CHANGE_ADMIN_PASSWORD: BASE_URL + 'changePassword?emailId=',
    CHANGE_ADMIN_PASSWORD: BASE_URL + 'changePassword',
    // save important loan
    SAVE_IMPORTANT_LOANS: BASE_URL + 'saveValuableCustomersData',
    // delete employee from admin
    DELETE_EMPLOYEE: BASE_URL + 'deleteUser?emailId=',
    // delete transaction
    DELETE_TRANSACTION: BASE_URL + 'deleteTransaction?transactionId=',
    // get mail activity
    GET_ACTIVITY: BASE_URL + 'getActivity',
    //mail activity login
    ACTIVITY_LOGIN: BASE_URL + 'fetchMail',
    // status count by week
    STATUS_COUNT_BY_WEEK: BASE_URL + 'statusCountByWeek',
    // status count by month
    STATUS_COUNT_BY_MONTH: BASE_URL + 'statusCountByMonth?year=',
    // status count between days 
    STATUS_COUNT_BETWEEN_DATES: BASE_URL + 'statusCountBetweenDay?startdate=',
    // send mail for approval
    APPROVE_VERIFICATION: BASE_URL + 'enashAndEsign/sendMailForApproval?loanId=',
    // stamp duty upload
    UPLOAD_STAMP_DUTY: BASE_URL + 'uploadStampDuty',
    // display coupon stats
    COUPON_STATS: BASE_URL + 'couponStat',
    // merchant login
    MERCHANT_LOGIN: BASE_URL + 'loginMerchant',
    // get merchat detail
    MERCHANT_ALL_DATA_API: BASE_URL + 'getMerchantDetails',

    MERCHANT_REDUMPTION_DETAIL: BASE_URL + 'getPendingCoupons',

    REDEEM_COUPON: BASE_URL + 'redeemcoupon',

    MERCHANT_DOCUMENT_UPLOAD: BASE_URL + 'saveMerchantLogo',

    COUPON_DOCUMENT_UPLOAD: BASE_URL + 'saveCouponImage',

    MERCHANT_AGREEMENT_UPLOAD: BASE_URL + 'saveAgreement',

    GET_MERCHANT_IMAGES: BASE_URL + 'getMerchantLogo',

    GET_COUPON_IMAGE: BASE_URL + 'getOfferImage',

    GET_COUNT_BY_STATES: BASE_URL + 'getLoanCountByStates?date=',

    GET_COUNT_BY_AGENT: BASE_URL + 'getApplicationCountByAgent?date=',

    GET_APPLICATION_STATUS_COUNT: BASE_URL + 'getApplicationStatusByCount?startDate=',

    GET_LOAN_SCORE: BASE_URL + 'getNewScore?loanId=',

    MAP_COUPON_LOANSTATUS: BASE_URL + 'saveOffersForLoanStatus',

    // SAVE_CERTIFICATE_NUMBER: BASE_URL + 'saveCertificateNumber?loanId=',

    GET_COUPON_ON_OUTLET: BASE_URL + 'getCouponOnOutlet',

    AUTOMATION_MAIL_LOGS: BASE_URL + 'getAutomationMailLog',

    GET_USERS_USED_COUPONS: BASE_URL + 'getAvailCouponUserByCoupon',

    SAVE_WEBSITE_DATA: BASE_URL + 'saveWebsiteData',

    GET_WEBSITE_DATA: BASE_URL + 'getWebsiteData',

    TOTAL_AMBASSADOR_COUNT: BASE_URL + 'ambassadorCount',

    SAVE_NACH: BASE_URL + 'loan/saveNachData',

    SAVE_EXTENSION_TIME: BASE_URL + 'loan/saveExtensionTime?loanId=',
    // GET_FROUD_DETAIL: BASE_URL + 'getFraudMatchDetails',
    GET_FROUD_DETAIL: BASE_URL + 'getFraudMatchDetail?userId=',


    GET_FROUD_DETAIL_NEW: BASE_URL + 'getFraudMatchDetailNew?userId=',

    GENERATE_DISBURSE_REPORT: BASE_URL + 'getDisbursedSheet',

    GENERATE_CIBIL_REPORT: BASE_URL + 'cibilData?startDate=',

    SAVE_FIELDBOY_DETAILS: BASE_URL + 'saveEmployeeDetails',

    GET_FIELDBOY_DETAILS: BASE_URL + 'getEmployeeDetails',

    DELETE_FIELD_BOY: BASE_URL + 'deleteFieldBoyRegistration?empId=',

    UPDATE_ASSIGN_FIELD_BOY: BASE_URL + 'updateFieldAssign',

    SAVE_LOAN_ID: BASE_URL + 'loan/saveApplicationToLoan',

    LOAN_LIST_BY_LOAN: BASE_URL + 'loan/listByloan?loanId=',

    GET_EMAIL_DEVICE: BASE_URL + 'getEmailAndDevice?page=',

    SEND_RECOVERY_COMMUNICATION: BASE_URL + 'saveRecoveryMail',

    GET_RECOVERY_LOGS: BASE_URL + 'getRecoveryMail',

    SAVE_CHEQUE_NOTICE: BASE_URL + 'saveChequeNotice',

    GET_CHEQUE_DATA: BASE_URL + 'getChequeData?count=',

    GET_NOTICE_DATA: BASE_URL + 'getNoticeData?count=',

    GET_USER_DEVICE_DATA: BASE_URL_ANALYSIS + 'getUserDeviceData?userId=',

    GET_NACH_DATA: BASE_URL + 'getNachBounceData?count=',

    SAVE_CHEQUE_LIMIT: BASE_URL + 'saveChequeLimit?userId=',

    UPDATE_USING_CSV: BASE_URL + 'uploadsheetcsv',

    UPDATE_DB_DATA: BASE_URL + 'updatecsv',

    UPDATE_MOBILE_AND_EMAIL: BASE_URL + 'updateMobileAndEmail?userId=',

    DELETE_COMPLETE_USER: BASE_URL + 'removeUser?userId=',

    DELETE_COMPLETE_AMBASSADOR: BASE_URL + 'deleteAmbassador?ambassadorId=',

    DOC_UPLOAD_SAVE_FROM_MAIL: BASE_URL + 'docUploadSave',

    SEND_WHATSAPP_MSG: BASE_URL + 'sendWhatsAppMsg',

    GET_WHATSAPP_MSG: BASE_URL + 'getWhatsAppMsg?mobileNo=',

    SEND_WHATSAPP_FILE: BASE_URL + 'sendWhatsAppFile',

    GENERATE_REPORT_LOGS: BASE_URL + 'downloadReportByEmploy',

    GET_FIELDBOY_DETAIL: BASE_URL + 'getEmployeeDetails',

    ASSIGN_APPLICATION: BASE_URL + 'assignApplication',

    ASSIGNED_APPLICATIONS: BASE_URL + 'getAssignedApplicationByLoanId?loanId=',

    REFERRAL_COMPANY: BASE_URL + 'saveMerchantReferral',

    DAILY_DISTANCE_COVERED: BASE_URL + 'getDistanceCoveredDateWise?empId=',

    SAVE_AMBASSADOR_COMMENTS: BASE_URL + 'saveAmbassadorAdminLog',

    GET_AMBASSADOR_COMMENTS: BASE_URL + 'getAmbassadorAdminLog?ambassadorId=',

    START_FIELD_BOY_TIMING: BASE_URL + 'saveEndTimeFieldBoy?time=',

    SAVE_TENTATIVE_DATA: BASE_URL + 'loan/tentativeApproveAmount',

    NOTIFICATION_IMAGE_UPLOAD: BASE_URL + 'uploadAnypdf',

    GET_IBD_DETAILS: BASE_URL + 'getibddetails/',

    GET_OBD_DETAILS: BASE_URL + 'getobddetails',

    SAVE_COUPON_CODE: BASE_URL + 'saveCouponCodes',

    GET_CALLBACK_STATUS_CHANGE: BASE_URL + 'ivr/changeCallBackStatus?mobileNumber=',

    GET_IVR_FILTER_API: BASE_URL + 'ivr/getibddetails?date=',

    GET_IVR_RESPONSE: BASE_URL + 'getIVRResponse',

    SAVE_LOAN_INSTALLMENT: BASE_URL + 'loan/saveLoanEmi',

    GET_LOAN_INSTALLMENT: BASE_URL + 'loan/getLoanEmiDetailByLoanId?loanId=',

    DELETE_LOAN_INSTALLMENT: BASE_URL + 'loan/deleteLoanEmi?id=',

    SEND_MAIL_VERIFICATION_REQUEST: BASE_URL + 'sendverificationRequest',

    EMI_DETAIS_SAVE: BASE_URL + 'saveemiforuser',

    EMI_DETAIS_GET: BASE_URL + 'getemitopayuser/',

    // EMI_PAID_SAVED: BASE_URL + 'updateemiforuser',

    EMI_PAID_SAVED: BASE_URL + 'updateemiforuserNew',


    FIELD_BOY_DOCUMENTS: BASE_URL + 'updateUserDocs',

    GET_SCHEDULED_LOAN_DATA: BASE_URL + 'getScheduledLoanDetails/',

    COMMUNICATION_COUNT_BY_FILTER: BASE_URL + 'getCommunicationCountByFilter?state=',

    BULK_COMMUNICATION: BASE_URL + 'sendingMailAccToFilter',

    UPDATE_CONVERT_EMI_BTN: BASE_URL + 'updateloanstatusbyadmin/',

    GET_LOAN_PAGE_MSG: BASE_URL + 'getValidationMsg',

    GET_CALCULATIO_FOR_ADMIN: BASE_URL + 'getemiamountbyadmin/',

    GET_EMI_HISTORY: BASE_URL + 'getallemiforadminbyuser/',

    // USER_DETAIL_BY_LOANID: BASE_URL + 'userDetails/userDetailByLoanId?loanId=',

    USER_DETAIL_BY_LOANID: BASE_URL + 'getLoanDetailByLoanIdNew?loanId=',
    USER_DETAIL_BY_LOANID_OPTIMISED: BASE_URL + 'getLoanDetailByLoanIdNewOptimised?loanId=',

    ADD_AMBASSADOR_ID_IN_APPLICATION: BASE_URL + 'adminAmbassasorApplication?ambassadorId=',

    CHANAGE_EMI_STATUS: BASE_URL + 'changeEMIStatus?userId=',

    GET_ALL_STATE: BASE_URL + 'getAllState',

    BULK_APPLICATION_ASSIGN: BASE_URL + 'bulkApplicationAssign',

    GET_USER_ASSIGN_STATE: BASE_URL + 'getAssignByState',

    GET_COUNT_BY_STATUS: BASE_URL + 'getCountToEachStatus?date=',

    UPDATE_ASSIGN_STATUS: BASE_URL + 'updateAssignStatus?emailId=',

    MANUAL_ASSIGN_BY_LOAN_ID: BASE_URL + 'bulkApplicationAssignByLoanId',

    SAVE_ASSIGN_BY_STATUS: BASE_URL + 'saveAssignByStatusUpdated',

    GET_ASSIGN_BY_STATUS: BASE_URL + 'getAssignByStatusUpdated',

    MARK_APPLICATION_COMPLETE: BASE_URL + 'markApplicationComplete?userId=',

    PINCODE_UPLOAD: BASE_URL + 'pincodeUpload',

    APPROVE_T0_DISBURSE: BASE_URL + 'getDisbursedDetailByCsv',

    CONVERT_TO_PAID_BULK: BASE_URL + 'getPaidDetailByCsv',

    GET_PINCODE_DATA: BASE_URL + 'getAllPincode',

    NACH_UPLOAD_BULK: BASE_URL + 'loan/updateBulkNachStatus',

    GET_PRIORITY_COMPANY: BASE_URL + 'getPriorityCompany',

    ADD_PRIORITY_COMPANY: BASE_URL + 'addPriorityCompany',

    GET_LOAN_BREAK_BY_LOANID: BASE_URL + 'loan/getLoanBreakAmountByLoanId?loanId=',

    GET_PAYMENT_TRANSACTION: BASE_URL + 'loan/getLoanBreakDetailOfSinlgeEmi?loanId=',

    GET_LIST_OF_NBFC: BASE_URL + 'getListOfNbfc?nbfcName=',

    SOFT_APPROVAL_TO_NBFC: BASE_URL + 'softApprovalToNbfc?loanId=',

    SAVE_NBFC: BASE_URL + 'saveNbfc',

    VERIFY_MENDATE_SAVE_TOKEN: BASE_URL + 'enashAndEsign/verifyMandateAndSaveToken?userId=',

    BANK_DETAIL_ANALYSIS: STATEMENT_URL + 'api?id=',

    APP_ANALYSIS: STATEMENT_URL + 'app_api?userid=',

    UPDATE_ACC_INFO_CSV: BASE_URL + 'updateAccInfo',

    NACH_REPORT_DATA: BASE_URL + 'enashAndEsign/getEnachDataLead?pageNumber=',

    SEND_MAIL_TO_NACH_FAIL: BASE_URL + 'sendEnachRepeatMail?loanId=',

    BULK_COM_BY_CSV: BASE_URL + 'bulkCommunicationByCSV',

    UPLOAD_CSV_FILE: BASE_URL + 'uploadCsvFile',

    UPLOAD_CIBIL_FILE: BASE_URL + 'uploadCibilScoreData',

    DEDUCT_PAYMENT: BASE_URL + 'enashAndEsign/deductUserPayment?loanId=',

    DEDUCT_PAYMENT_BY_TECH_PROCESS: BASE_URL + 'enashAndEsign/scheduleTransactionEnash?loanId=',

    GET_SOURCE_OF_ENACH_BY_USERID: BASE_URL + 'getSourceOfEnachByUserId?userId=',

    GET_DATA_FOR_BULK_UPDATE: BASE_URL + 'loan/getDataForBulkUpdate?applicationId=',

    // GET_CIBIL_SCORE_BY_USERID: BASE_URL + 'loan/getCibilScoreDataByUserId?userId=',
    GET_CIBIL_SCORE_BY_USERID: BASE_URL + 'loan/getCibilScoreDataByUserIdLoanId?userId=',

    GET_CRIF_DATA: BASE_URL + 'getCrifData?userId=',
    // GET_CRIF_DATA: BASE_URL + 'test',

    GET_CRIF_ANALYSIS_DATA: BASE_URL + 'getCrifDataInJson?userId=',

    EDIT_CIBIL_SCORE_BY_USERID: BASE_URL + 'loan/updateCibilScoreData',

    REMOVE_APPLICATION_FROM_NBFC: BASE_URL + 'assignNbfcWithoutStatus?loanId=',

    GET_PSYCHOMETRY_DATA: PSYCHOMETRIC_URL + 'ques',

    GET_APPLICATION_ANALYSIS_DATA: BI_GRAPH_URL + 'Application_Analysis',

    GET_FORM_STAGE_COUNT: BI_GRAPH_URL + 'Form_Stage_Count',

    GET_PROFILE_ANALYSIS_DATA: BI_GRAPH_URL + 'Profile_Analysis',

    GET_LOAN_ANALYSIS_DATA: BI_GRAPH_URL + 'Loan_Analysis',

    GET_OVERDUE_ANALYSIS_DATA: BI_GRAPH_URL + 'Overdue_Analysis',

    GET_LOGIN_DEVICE_DATA: BI_GRAPH_URL + 'eda/login_device_status',

    GET_EVENT_DATA: BI_GRAPH_URL + 'eda/sourceofinfo_plateform_events',

    GET_LOAN_DATA: BI_GRAPH_URL + 'eda_loan_amount_range',

    GET_DETAILS_DATA: BI_GRAPH_URL + 'eda_user_login2 ',

    GET_LOGIN_DATA: BI_GRAPH_URL + 'eda_user_login',

    GET_EDA_USER_LOAN: BI_GRAPH_URL + 'eda_user_to_loan',

    PUSH_LEAD_TO_RETRA: BASE_URL + 'retraPushData?loanId=',

    BULK_PUSH_RETRA: BASE_URL + 'retraSendDataByLoanId',

    CREDIT_VIDYA_LEADS: BASE_URL + 'getCreditVidyaLeads?pageNumber=',

    CREDIT_VIDYA_LEADS_BY_FILTER: BASE_URL + 'getCreditVidyaLeadsByFilter?pageNumber=',

    UNSUBSCRIBE_MAIL: BASE_URL + 'unSubscribeMailByUserId?userId=',

    AUTH_TOKEN: BASE_URL + 'oauth/token',

    // ambassador

    //google login
    AMB_GOOGLE_LOGIN: BASE_URL + 'ambassador/authorize?ambassadorId=',
    //facebook login
    AMB_FACEBOOK_LOGIN: BASE_URL + 'ambassador/authorize/facebook?userId=',
    // Ambasador Details
    ALL_AMBASADOR_DETAILS: BASE_URL + 'allAmbasadorDetail?ambassadorId=',
    // All Ambasador Data
    ALL_AMBASSADORS: BASE_URL + 'allAmbasador?count=',
    // Update ambassador status
    UPDATE_AMBASSADOR_STATUS: BASE_URL + 'updateAmbassadorStatus?ambassadorId=',
    // ambassador basic detail save
    AMBASSADOR_BASIC_DETAIL: BASE_URL + 'ambasadorDetailSave',

    AMBASSADOR_ADDRESS_DETAIL: BASE_URL + 'ambasadorAddressSave',

    AMBASSADOR_BANK_DETAIL: BASE_URL + 'ambasadorBankDetailSave',

    AMBASSADOR_ALL_DATA: BASE_URL + 'ambasadorAllDetail?ambassadorId=',

    UPLOAD_AMBASSADOR_DOCUMENT: BASE_URL + 'ambassadordocument',

    DELETE_CATEGORY: BASE_URL + 'deleteCategory',

    DELETE_SUBCATEGORY: BASE_URL + 'deleteSubCategory',

    DELETE_COUPON: BASE_URL + 'deleteCoupon',

    DELETE_MERCHANT: BASE_URL + 'deleteMerchant',

    DELETE_MERCHANT_IMAGE: BASE_URL + 'deleteMerchantImage',

    DELETE_MERCHANT_OUTLET: BASE_URL + 'deleteMerchantOutlet',

    DELETE_CITY: BASE_URL + 'deleteCity',

    DELETE_COUPON_ON_OUTLET: BASE_URL + 'deleteCouponOnOutlet',

    SEND_AGGREMENT: BASE_URL + 'enashAndEsign/sendMailloanUrl?loanId=',

    SAVE_EXISTING_USER: BASE_URL + 'saveAndCheckForExistingUser',

    USER_TO_AMBASSADOR: BASE_URL + 'moveUserToAmbassador',

    GET_AMBASSADOR_ID: BASE_URL + 'getAmbassadorId?emailId=',
    // document next btn
    AMB_DOC_NEXT: BASE_URL + 'completeDocument?ambassadorId=',

    SHARE_AMB_ID_WITH_MAIL: BASE_URL + 'shareMail?emailId=',

    SHARE_AMB_ID_WITH_MSG: BASE_URL + 'shareMessage?number=',

    GET_ENACH_LOAN_DETAIL: BASE_URL + 'enashAndEsign/getloanDetail?loanId=',



    GET_RAJORPAY_ORDER_ID: BASE_URL + 'enashAndEsign/getRajorPayOrderIdCustomerId?loanId=',



    GET_TECHPROCESS_HASH_VALUE: BASE_URL + 'enashAndEsign/getHashValue?loanId=',

    GET_PAYNIMO_ORDER_ID: BASE_URL + 'enashAndEsign/getHashValue?loanId=',

    RAJORPAY_CALLBACK: BASE_URL + 'enashAndEsign/razorPayCallBack?type=',

    // GET_ESIGN_PDF_URL: BASE_URL + 'enashAndEsign/getloanUrl?loanId=',

    GET_ESIGN_PDF_URL: BASE_URL + 'enashAndEsign/getloanUrlUpdated?loanId=',

    ESIGN_AND_ENACH_INITIATE: BASE_URL + 'enashAndEsign/initiateEsign?loanId=',

    EMI_CALC_WITH_NBFC: BASE_URL + 'getmonthemiamountForNbfc/',

    // Loyality

    // save loyality offer
    LOYALITY_OFFER: BASE_URL + 'loyalityOffer',
    // Get all loyality offers
    GET_ALL_LOYALITY_OFFER: BASE_URL + 'getloyalityOffer',
    // get loyality list by userId
    GET_LOYALITY_LIST_BY_USERID: BASE_URL + 'getloyalityListByUserId?userId=',
    // Get all loyality detail
    GET_ALL_LOYALITY_DETAIL: BASE_URL + 'getAllloyalityList?count=',
    //marchant offer
    MARCHANT_OFFER_SAVE: BASE_URL + 'saveOffer',

    GET_MARCHANT_OFFER_LISTING: BASE_URL + 'getAllCoupons',

    GET_MARCHANT_OUT_LET: BASE_URL + 'getMerchantsOutlet',

    SAVE_MARCHANT_OUT_LET: BASE_URL + 'saveOffersonOutlet',

    GET_MARCHANT_OFFER: BASE_URL + 'getAdminDiscountDetails',

    GET_MERCHENT_DETSILS: BASE_URL + 'getmerchnatsdetails',

    SAVE_OFFR_LOCATION: BASE_URL + 'saveLocation',

    SAVE_OFFER_MERCHANT: BASE_URL + 'saveMerchant',

    SAVE_OFFER_CATEGORY: BASE_URL + 'saveCategory',

    SAVE_OFFER_SUBCATEGORY: BASE_URL + 'saveSubCategory',

    GET_COUPON_LIST_FOR_MERCHANTS: BASE_URL + 'getOffersForMerchants',

    SAVE_MARCHANT_OUT_LET_ADDRESS: BASE_URL + 'saveOutlet',

    GET_OUTLET_LOCATIONS: BASE_URL + 'getOutlets',

    APPROVE_REJECT_REASON: BASE_URL + 'approveRejectReason?status=',

    DOWNLOAD_REPORT_LOG: BASE_URL + 'downloadReportByEmploy',

    AMBASSADOR_LOGIN: BASE_URL + 'loginPhocketAmbassdor',

    THIRD_PARTY_LOGIN: BASE_URL + 'logincampaignusers ',

    THIRD_PARTY_REGISTRATION: BASE_URL + 'saveeventcampaigndata',

    GET_CAMPAIGN_TRACK: BASE_URL + 'getUsersByCampaign/',

    GET_REGISTER_DATA: BASE_URL + 'getRegisteredUsers',

    SAVE_CAMPAIN_DATA: BASE_URL + 'saveEventCampaign',

    GET_ALL_CAMPAIN_NAME: BASE_URL + 'getAllCampaign',

    GET_USERS_DETAILS: BASE_URL + 'getRegisteredUsersDetails/',

    GET_ALL_CAMPAIGN: BASE_URL + 'getAllCampaign',

    GET_CAMPAIGN_BY_MERCHANT: BASE_URL + 'getCampaignByMerchant/',

    DELETE_CAMPAIGN_URL: BASE_URL + 'deleteMerchantByAdmin',

    GET_EXCEL_DATA_URL: BASE_URL + 'downloadThirdPartySheet?url=',

    DEACTIVATE_NACH: BASE_URL + 'enashAndEsign/deactivateEnach?userId=',

    SEND_MSG_BY_MOBILE_CSV: BASE_URL + 'sendMsgFromCsvByMobileNumber',

    AADHAR_EXIST_CHECK: BASE_URL + 'userDetails/aadharExist?userId=',

    INITIATE_BULK_PAYMENT_RAZORPAY: BASE_URL + 'enashAndEsign/initiateRazorPayPaymentBulk',


    /////Api After Gift Card///////////
    GET_LOAN_APPROVAL_LEAD: BASE_URL + 'loanLeadTestingNew?status=',

    GET_LOAN_DETAIL_BY_USERID: BASE_URL + 'getLoanDetailByUserId?userId=',

    GET_USER_DOCUMENTS_BY_USER_ID: BASE_URL + 'getUserDocumentDetailByUserId?userId=',

    GET_GIFTCARD_EMI: BASE_URL + 'getEmiToPayGiftCard/',

    GET_EMI_HISTORY_GIFTCARD: BASE_URL + 'getAllLoanEmiForUserGiftCard/',

    GIFT_CARD_APPROVE_API: BASE_URL + 'approvedGiftCard/',

    DISBURSE_GIFT_CARD_API: BASE_URL + 'disbursedGiftCard?giftCardId=',

    CHANGE_FOLLOW_UP_GIFT_CARD: BASE_URL + 'applicationStatuss?giftCardId=',

    CHANGE_EMI_STATUS_GIFT_CARD: BASE_URL + 'changeEMIStatuss?userId=',

    APPROVE_VERIFICATION_GIFTCARD: BASE_URL + 'enashAndEsign/sendMailForApprovalGiftCard?giftCardId=',

    SAVE_TENTATIVE_DATA_GIFT_CARD: BASE_URL + 'tentativeApproveAmountGiftCard',

    GET_ENACH_LOAN_DETAIL_GIFT_CARD: BASE_URL + 'enashAndEsign/getLoanDetailById?id=',

    GET_ACCOUNT_DETAILS_USER_ID: BASE_URL + 'getAccountDetailByUserId?userId=',


    LOAN_FILTER_BY_APPLICATION: BASE_URL + 'listByApplication?applicationId=',

    LOAN_FILTER_BY_EMAILID: BASE_URL + 'listByEmail?emailId=',

    LOAN_FILTER_BY_NUMBER: BASE_URL + 'listByNumber?mobileNumber=',

    SAVE_CERTIFICATE_NUMBER: BASE_URL + 'saveCertificateNumberGiftCard?loanId=',

    APPLY_GIFT_CARD_API: BASE_URL + 'applyForGiftCard',

    GET_GIFT_CARD_LEADS: BASE_URL + 'loanLeadTestingNewGiftCard?status=',

    GIFTCARD_SANCTION: BASE_URL + 'loanSanction',

    REPEAT_USER_CHECK: BASE_URL + 'repeatUser?userId=',

    SHEET_BY_CHOICE_NEW: BASE_URL + 'sheetDownloadUpdated',

    GIFTCARD_DETAIL_BY_USER_ID: BASE_URL + 'getGiftCardDetailByUserId?userId=',

    GET_MONTHLY_EMI: BASE_URL + 'getMonthlyEmi',

    GET_GIFTCARD_EMI_BREAK_UP: BASE_URL + 'loan/getLoanBreakAmountByGiftCardId?giftCardId=',

    GET_SINGLE_EMI_BREAK_UP: BASE_URL + 'loan/getLoanBreakDetailOfSinlgeEmiByEmiAndGiftCardId?giftCardId=',

    GIFTCARD_PAID: BASE_URL + 'paidGiftCard',

    CHANGE_EMI_STATUS_GIFT_CARD_PAID: BASE_URL + 'changeEMIStatuss?giftCardId=',

    EMI_PAID_SAVED_GIFT_CARD: BASE_URL + 'updateEmiToPaidForGiftCard',

    GET_GIFT_VOUCHER_DETAILS: BASE_URL + 'getVoucherDetailByGiftCardId?giftCardId=',

    GET_CRIF_ANALYSIS_DATA_NEW: BASE_URL + 'getCrifDataInJsonUpdated?userId=',

    GET_FINBIT_DATA: BASE_URL + 'getFinBitData?userId=',

    GENERATE_COLLECTION_REPORT: BASE_URL + 'generateCollectionReport?startDate=',

    GET_FINBIT_DATA_DETAILS: BASE_URL + 'getAccountUUID?userId=',

    GET_ENACH_DEACTIVATION_BY_USERID: BASE_URL + 'enashAndEsign/getENachDeactivationbyUserId?userId=',

    GET_ENACH_DEACTIVATION_BY_LOANID: BASE_URL + 'enashAndEsign/getENachDeactivationbyLoanId?loanId=',

    ENACH_DEACTIVATION_BY_USERID_AND_LOANID: BASE_URL + 'enashAndEsign/saveENachDeactivation',

    GET_ASSIGN_FOR_COLLECTION: BASE_URL + 'getAssignForCollection',

    SAVE_FOR_COLLECTION: BASE_URL + 'saveAssignForCollection',

    BULK_NACH_BOUNCE_BY_CSV: BASE_URL + 'updateNachBounceOfCsv',

    BULK_PAYMENT_LEADS: BASE_URL + 'getLeadsForBulkPayment?type=',

    DOWNLOAD_INCOMING_PAYMENT_REPORT: BASE_URL + 'getIncomingPaymentReport?dateFrom=',

    NEXOPAY_USER_REGISTRATION: BASE_URL + 'directUserRegistration',

    GET_NEXOPAY_DATA: BASE_URL + 'getNexoPayData?uniqueId=',

    GET_DASHBOARD_COUNT_FOR_STATUS: BASE_URL + 'getDashBoardCountForStatus?empId=',

    GET_VREEDHI_DATA: BASE_URL + 'getBussinessDetailByUserId?userId=',

    SAVE_OR_UPDATE_BUISNESS_DETAIL: BASE_URL + 'saveOrUpdateBussinessDetail',

    ESIGN_AND_ENACH_INITIATE_NEW: BASE_URL + 'eSignRequest?loan_id=',

    WIDGET_API: BASE_URL + 'widgetToken?loan_id=',

    RFD_API: BASE_URL + 'loan/changeLoanSubStatus?loanId=',

    GET_DOCKET_INFO: BASE_URL + 'getDocketInfo?loan_id=',

    GET_SIGNATURE_STATUS: BASE_URL + 'getSignatureStatus?document_id=',

    BULK_ICICI_PAYMENT: BASE_URL + 'bulkIciciTransaction',

    BULK_VERIFY_TRANSACTION: BASE_URL + 'bulkVerifyTransaction',

    BUDDY_LOAN_COMPLETE_DATA: BASE_URL + 'getBuddyLoanCompleteData?dateFrom=',

    BUDDY_LOAN_APPLIED_DATA: BASE_URL + 'getBuddyLoanAppliedData?dateFrom=',

    APPLICATION_STATUS_DETAIL_USER: BASE_URL + 'userDetails/alluserDetailByUserIdInComplete?userId=',

    UPDATED_APPLICATION_STATUS: BASE_URL + 'userDetails/alluserDetailUpdated?pageNumber=',

    AUTOMATION_MAIL_LOGS_UPDATED: BASE_URL + 'getAutomationMailLogUpdated?type=',

    AUTOMATION_TYPES: BASE_URL + 'getAutomationMailLogTypes',

    MOBILE_VERIFIY_API: BASE_URL + 'makeMobileVerified?userId=',

    GET_COMPANY_PERFORMANCE_COUNT: BASE_URL + 'getCompletePerformanceCount?startDate=',

    DELETE_FACEBOOK_USER_DATA: BASE_URL + 'deleteFaceBookAccountDetail?userId=',

    GET_RAZORPAYDATA: BASE_URL + 'getRazorPayReportData?dateFrom=',

    GET_FLIPKART_BALANCE: BASE_URL + 'getFlipkartBalance',

    USER_REFERENCE_SAVE_API: BASE_URL + 'userDetails/saveUserReferences?userId=',

    GET_USER_REFERENCE: BASE_URL + 'userDetails/getUserReferences?userId=',

    MARK_APPLICATION_INCOMPLETE: BASE_URL + 'markApplicationInComplete?userId=',

    GET_COMPANY_PERFORMANCE_COUNT_DEPARTMENT: BASE_URL + 'getCompletePerformanceCountByDepartment?startDate=',

    DEPARTMENTWISE_LOAN_COUNT: BASE_URL + 'getDepartmentWiseLoanCount?date=',

    BADA_BUISNESS_LEAD_CREATE_API: BASE_URL + 'saveBazarBussinessLead',

    DOWNLOAD_DEPARTMENTWISE_DATA: BASE_URL + 'downloadCompletePerformanceCountByDepartment?startDate=',

    BADA_BUISNESS_TRIGGER_API: BASE_URL + 'badaBussinessTrigger?applicationId=',

    BADA_BUISNESS_TRANSACTION: BASE_URL + 'getBadaBusinessTransactionDetails?loanId=',

    GET_PAN_PROFILE_DETAILS: BASE_URL + 'karza/api/getPanProfileDetails?user_id=',

    GET_PAN_PROFILE_POST_CALL: BASE_URL + 'karza/api/panStatusCheck?user_id=',

    GET_AADHAR_VERIFICATION_DETAILS: BASE_URL + 'karza/api/getAadhaarVerificationDetails?user_id=',

    GET_AADHAR_XML_DETAILS: BASE_URL + 'karza/api/getAadhaarXmlDetailByUserId?user_id=',

    GET_AADHAR_XML_DATA: BASE_URL + 'karza/api/getAadhaarXmlDataByUserId?user_id=',

    POST_CALL_DIGILOCKER: BASE_URL + 'karza/api/downloadXmlAadhaarDigiLocker?user_id=',

    POST_CALL_AADHAR_VERFICATION: BASE_URL + 'karza/api/aadhaarNumberVerification?user_id=',

    GET_MANUAL_ASSIGNED_LEADS: BASE_URL + 'loanLeadTestingManualAssignment?status=',

    //card utility api//

    CARD_UTILITY: CARD_URL + '/cardUtility',

    GET_ALL_CARD_USERS: CARD_URL + '/getAllCardLeads?pageNumber=',

    GET_CARD_USER_DETAIL_BY_USER_ID: CARD_URL + '/getUserDetails?userId=',

    GET_TRANSACTION_DETAIL_BY_USER_ID: CARD_URL + '/getTransactionDetailsByUserId?userId=',

    GET_BANK_DETAIL_BY_USER_ID: CARD_URL + '/getBankDetails?userId=',

    GET_ADDRESS_DETAIL_BY_USER_ID: CARD_URL + '/getAddressDetails?userId=',

    GET_CARD_LIMIT_BY_USER_ID: CARD_URL + '/getCardLimitHistory?userId=',

    GET_CARD_DETAILS_BY_USER_ID: CARD_URL + '/getCardDetailsByUserId?userId=',

    EDIT_CARD_BANK_DETAILS: CARD_URL + '/addBankDetail',

    EDIT_CARD_ADDRESS_DETAILS: CARD_URL + '/addAddress',

    EDIT_CARD_BASIC_DETAILS: CARD_URL + '/addPhocketUser',

    ADD_FALCON_VIRTUAL_CARD: CARD_URL + '/addFalconVirtualCard?userId=',

    ADD_CARD_FUNDS: CARD_URL + '/addFunds',

    ADD_FALCON_USER: CARD_URL + '/addFalconUser?userId=',

    GET_ALL_CARD_STATUS: CARD_URL + '/getAllStatus?type=',

    GET_CARD_FUNDS_DETAIL: CARD_URL + '/getFunds?cardId=',

    UPLOAD_CARD_CSV: CARD_URL + '/uploadCardUserCreationCsvFile',

    COMPLETE_ZERO_TOUCH_KYC: CARD_URL + '/completeZeroTouchKYC?userId=',

    SAVE_FOLLOW_UP_STATUS_CARD: CARD_URL + '/saveCardApplicationStatus?cardId=',

    GET_CARD_TRANSACTION_DETAILS: CARD_URL + '/getTransactionDetailByUserId?userId=',

    PAN_AUTHENTICATION_API: BASE_URL + 'karza/api/panAuthenticationCheck?user_id=',

    GET_CARD_ACTIVITY_API: CARD_URL + '/getUserActivityById?userId=',

    ENABLE_OR_DISABLE_CARD: CARD_URL + '/enableDisableCard?userId=',

    BLOCK_CARD: CARD_URL + '/blockCard?userId=',

    GET_TESTING_CARD_LEADS: CARD_URL + '/getCardLoanLeads',

    CARD_STATUS_DATA: CARD_URL + '/getCardStatusDetail?cardId=',

    GET_CVV: CARD_URL + '/getCardCVV?cardId=',

    CARD_POLICY_DETAILS: CARD_URL + '/getCardPolicy?cardId=',

    ENABLE_DISABLE_CAHNNEL: CARD_URL + '/enableDisableChannel?cardId=',

    GET_RAJORPAY_ORDER_ID_CARD: CARD_URL + '/enachAndEsign/getRazorPayOrderIdCustomerId?userId=',

    GET_UPDATE_LOAN_AGREEMENT_URL: CARD_URL + '/enachAndEsign/getLoanUrlUpdated?userId=',

    GET_CARD_LOAN_LEADS: CARD_URL + '/getCardLoanLeads?status=',

    TODAYS_LOAN_COUNT_CARD: CARD_URL + '/todaysLoanCount?date=',

    USER_DETAIL_BY_ID_CARD: CARD_URL + '/userDetails/userDetailById?userId=',

    USER_DETAIL_BY_LOANID_CARD: CARD_URL + '/getLoanDetailByLoanIdNew?userId=',

    GET_PAN_PROFILE_DETAILS_CARDS: CARD_URL + '/karza/api/getPanProfileDetails?user_id=',

    GET_CARD_LOAN_DETAIL_BY_USER_ID: CARD_URL + '/getCardLoanDetailByUserId?userId=',

    EMI_DETAIS_GET_CARDS: CARD_URL + '/getemitopayuser/',

    GET_EMI_HISTORY_CARDS: CARD_URL + '/getAllLoanEmiForUserGiftCard/',

    GET_EMIS_HISTORY_CARDS: CARD_URL + 'getallemiforadminbyuser/',

    GET_TRANSACTIONS_CARDS: CARD_URL + '/getTransaction?userId=',

    GET_LOAN_INSTALLMENT_CARDS: CARD_URL + '/loan/getLoanEmiDetailByLoanId?loanId=',

    STATUS_ACTIVITY_DATA_CARDS: CARD_URL + '/loan/getLoanTrack?loanId=',

    GET_ENACH_LOAN_DETAIL_CARD: CARD_URL + '/enachAndEsign/getUserDetailsByUserId?userId=',

    APPROVE_REJECT_REASON_CARD: CARD_URL + '/approveRejectReason?status=',

    RAJORPAY_CALLBACK_CARD: CARD_URL + '/enachAndEsign/razorPayCallBack?type=',

    GET_EMI_HISTORY_CARDS: CARD_URL + '/getallemiforadminbyuser/',

    BANK_VERIFICATION_API: BASE_URL + 'karza/api/bankAccountCheck?ifsc=',

    ESIGN_AND_ENACH_INITIATE_NEW_CARDS: CARD_URL + '/eSignRequest?userId=',

    WIDGET_API_CARDS: CARD_URL + '/widgetToken?userId=',

    GET_DOCKET_INFO_CARDS: CARD_URL + '/getDocketInfo?userId=',

    GET_SIGNATURE_STATUS_CARDS: CARD_URL + '/getSignatureStatus?document_id=',

    ADD_PHYSICAL_CARD_API: CARD_URL + '/addFalconPhysicalCard?userId=',


    //TeaGarden
    GET_USER_DETAILS_BY_EMPID: BASE_URL + 'getUserDetailByEmpId?empId=',

    UPLOAD_TEA_GARDEN_USERS: BASE_URL + 'uploadCSV',

    SAVE_OR_UPDATE_BASIC_DETAILS_TEA_FLOW: BASE_URL + 'addOrUpdateUserDetailTeaGarden',

    APPLY_TEA_GARDEN_LOAN: BASE_URL + 'saveLoanDataTeaGarden',

    ADD_AMBASSADOR_TEA_GARDEN: BASE_URL + 'addAmbassador',

    GET_ALL_TEA_GARDEN_LIST: BASE_URL + 'getAllGardenDetail?pageNumber=',

    GET_ALL_TEA_GARDEN_AMBASADOR_LIST: BASE_URL + 'getAllAmbassador?pageNumber=',

    GET_USERS_DETAILS_BY_USER_ID: BASE_URL + 'getUserDetailByUserId?userId=',

    TEA_GARDEN_AMBASADOR_LOGIN: BASE_URL + 'processOtp?mobileNumber=',

    VERIFY_AMBASADOR_OTP: BASE_URL + 'verifyOtp?mobileNumber=',

    GET_AMBASSADOR_DETAILS_BY_USER_ID: BASE_URL + 'getAmbassadorDetailByUserId?userId=',

    GET_AMBASSADOR_LEADS: BASE_URL + 'loanLeadAmbassador?status=',

    PAN_VERIFICATION_KARZA: BASE_URL + 'karza/api/panAuthenticationCheck?user_id=',

    APPLY_GIFT_CARD_API_TEA_GARDEN: BASE_URL + 'applyForTeaGiftCard',

    TEA_GARDEN_GIFT_CARD_LEADS: BASE_URL + 'loanLeadTestingNewGiftCard?status=',

    GIFTCARD_AMBASSADOR_LEADS: BASE_URL + 'phocketGiftCardLoanAmbassador?status=',

    UPLOAD_TEA_GARDEN_ATTENDANCE: BASE_URL + 'uploadAttendance',

    UPLOAD_TEA_GARDEN_WAGES: BASE_URL + 'getWagesJson',

    THIRD_USER_ACIVE_INACTIVE: BASE_URL + 'changeEventUserActiveInActiveStatus?status=',

    GET_DBR_CALCULATION_DETAILS_BY_USER_ID: BASE_URL + 'loan/getDbrScores?userId=',

    SAVE_DBR_SCORES: BASE_URL + 'loan/DBRCalculation',

    GET_DBR_ALL_DATA: BASE_URL + 'loan/getDbrDetails?userId=',

    GET_REPEAT_AUTOMATION_USER_ID: BASE_URL + 'loan/getRepeatAutomation?userId=',

    GET_TEA_USER_BY_PAN: BASE_URL + 'getUserDetailByPan?pan=',

    UPLOAD_STAMP_DUTY_ALL: BASE_URL + 'splitAndUploadStampDutyPdf',

    GET_STAMP_DUTY: BASE_URL + 'getStampDuty?usedStatus=',

    GET_STAMP_DUTY_COUNT: BASE_URL + 'getStampDutyCount?usedStatus=',

    KYC_FORM_APP: BASE_URL + 'loan/changeKycStatus?loanId=',

    BULK_ASSIGN_VIA_CSV: BASE_URL + 'bulkAssignmentLeads',

    CHANGE_EMPID_TEA_GARDEN: BASE_URL + 'changeEmpIdTeaGarden?teaGardenId=',

    GET_BRANCH_DATA: BASE_URL + 'getBranchData?sDate=',

    RECEIPT_DOWNLOAD_TEA: BASE_URL + 'generateTeaGardenReciept?loanId=',

    SAVE_BANK_DETAIL: BASE_URL + 'userDetails/addAccountDetailsBnplReownDevice',

    GET_APPROVAL_ESIGN_ENACH_BY_USERID: BASE_URL + 'getApprovalEsignEnachStatus?userId=',

    EMI_CALCULATOR_CARD: BASE_URL + 'getMonthlyEmi/',

    PAYMENT_TRANSFER_API_RAZORPAY: BASE_URL + 'enashAndEsign/transferRazorpay?amount=',

    GET_RAZORPAY_TRANSACTION: BASE_URL + 'enashAndEsign/getTransferData?noOfEntry=',

    UPLOAD_NBFC_LOGO: BASE_URL + 'uploadNbfcLogo?nbfcName=',

    NBFC_PRODUCT_CONFIG_SAVE: BASE_URL + 'saveOrUpdateLoanUtility',

    GET_PRODUCT_BY_NBFC_ID: BASE_URL + 'getLoanUtility?nbfcId=',

    GET_PRODUCT_NBFC_COUNT: BASE_URL + 'getLoanUtilityCount?nbfcId=',

    SAVE_PROCESSING_CHARGES: BASE_URL + 'saveOrUpdateProcessingFee',

    GET_PROCESSING_FEE_COUNT: BASE_URL + 'getProcessingFeeCount?nbfcId=',

    GET_NBFC_CHARGES: BASE_URL + 'getProcessingFee?nbfcId=',

    SAVE_ESIGN_CONFIG: BASE_URL + 'saveOrUpdateEsignConfig',

    SAVE_ENACH_CONFIG: BASE_URL + 'saveOrUpdateEnachConfig',

    GET_ESIGN_CONFIG: BASE_URL + 'getEsignConfig?nbfcId=',

    GET_ESIGN_CONFIG_COUNT: BASE_URL + 'getEsignConfigCount?nbfcId=',

    GET_ENACH_CONFIG: BASE_URL + 'getEnachConfig?nbfcId=',

    GET_ENACH_CONFIG_COUNT: BASE_URL + 'getEnachConfigCount?nbfcId=',

    CREATE_BILL_DESK_ORDER: BASE_URL + 'createMandateOrder?loanId=',

    SAVE_MANDATE_DETAILS_BILL_DESK: BASE_URL + 'saveMandateDetails',

    CREATE_PAYMENT_LINK: BASE_URL + 'createPaymentLink?loanId=',

    CREATE_PAYMENT_BY_LINK_ID: BASE_URL + 'createPaymentOrderByLinkId?linkId=',

    SAVE_PAYMENT_DETAILS_BILL_DESK: BASE_URL + 'saveTransactionDetails',

    GET_PAYMENT_DETAILS_BY_LINKID: BASE_URL + 'getTransactionDetailByLinkId?linkId=',

    GET_API_COSTING_DATA: BASE_URL + 'getApiCostingData',

    GET_THIRD_PARTY_HIT_DATA: BASE_URL + 'getThirdPartyHitsByDate?startDate=',

    GET_FINBIT_HIT_STATS: BASE_URL + 'getFinBitHitStats?startDate=',

    UPDATE_COSTING: BASE_URL + 'updateApiCostingData',

    GET_BILL_DESK_TXN_BY_LOAN_ID: BASE_URL + 'getBillDeskTransactionByLoanId?loanId=',

    SAVE_BUISNESS_DATA_MANUAL: BASE_URL + 'saveManualBusinessReport',

    GET_BUISNESS_DATA_MANUAL: BASE_URL + 'getManualBusinessReport',

    GET_BUISNESS_REPORT_LIST: BASE_URL + 'getManualBusinessReportList?pageNumber=',

    GET_PERSONAL_ANALYSIS_REPORT: BASE_URL + 'karza/api/getPersonlAnalysis?userId=',

    GET_BILLDESK_NACH_REPORT: BASE_URL + 'getEnachDataLeadBillDesk?pageNumber=',

    // GET_BILLDESK_NACH_REPORT: 'https://backend.phocket.in/getEnachDataLeadBillDesk?pageNumber='
    GET_ANALYSIS_DASHBOARD_MATCH_DATA: BASE_URL + 'getAgregatedResult?userId=',

    GET_CONCLUDED_DATA: BASE_URL + 'getConcludedStats?userId=',

    GET_SMS_ANALYSIS_STATUS: BASE_URL + 'getSmsAnalysisStatus?userId=',

    GET_CRIF_ANALYSIS_STATUS: BASE_URL + 'getCrifAnalysis?userId=',

    GET_FINBIT_ANALYSIS_DATA: BASE_URL + 'getFinBitAnalysis?userId=',

    GET_SIMILAR_ANALYSIS_DATA: BASE_URL + 'getSimilarProfileAnalytics?loanId=',

    GET_BRANCH_DATA_NEW: BASE_URL + 'getBranchStats?startDate=',

    DOWNLOAD_BRANCH_REPORT: BASE_URL + 'downloadBranchReport?dateFrom=',

    GET_FIDY_PAY_TRANSACTION_DETAILS: BASE_URL + 'enashAndEsign/getFidyPayTransactionId?loanId=',

    GET_EVENTS_LIST: BASE_URL + 'getEventList',

    SAVE_EMAIL_VERIFICATION_API: BASE_URL + 'saveEmailVerificationGenerateReport',

    CODE_VRIFY_MAIL: BASE_URL + 'mainEmailVerificationGenerateReport/emailVerfication/',

    VERIFY_REFRENCE_DETAILS: BASE_URL + 'userDetails/updateReferenceVerifiedStatus?userId=',

    SAVE_AGREEGATOR_DATA: BASE_URL + 'saveeventcampaigndata',

    SAVE_CREDIT_ASSESMENT_NOTES: BASE_URL + 'saveNotesForCreditAssessment',

    GET_CREDIT_ASSESSMENT_COMMENT: BASE_URL + 'getNotesForCreditAssessment?loanId=',

    GENERIC_FILE_UPLOAD_AND_GET_URL: BASE_URL + 'uploadpdfGeneric',

    GET_MARKETING_LIST: BASE_URL + 'getEventDataByPageNo?pageNumber=',

    GET_NBFC_BANK_CODE_BY_BANK_NAME: BASE_URL + 'getNbfcBankCodeByBankName?bankName=',

    GET_AGREEGATOR_LIST: BASE_URL_MARKETING + 'getAggregatorName',

    SAVE_BUDGET_PLAN: BASE_URL_MARKETING + 'saveOrUpdateMarketingMonthlyPlanning',

    SIGN_DESK_MANDATE: BASE_URL + 'createMandate?loanId=',

    GET_REASONS_API: BASE_URL + 'getDataAnalysisStatusByLoanId?loanId=',

    GET_CREDIT_LEADS_API: BASE_URL + 'getDataAnalysisStatusByPageNo?pageNo=',

    GET_EVENT_DATA_BY_PAGE: BASE_URL_MARKETING + "getEventDataByPageNo?pageNumber=",

    // push leads
    GET_IN_COMPLETE_STATS_BY_AGGREGATOR: BASE_URL_MARKETING + "getInCompleteStatsByAggregator?aggregatorName=",
    GET_IN_COMPLETE_STATS_BY_AGGREGATOR_LEADS: BASE_URL_MARKETING + "getInCompleteStatsByAggregatorLeads?aggregatorName=",
    GET_IN_COMPLETE_STATS_BY_BRANCH: BASE_URL_MARKETING + "getInCompleteStatsByBranchData?partnerName=",
    GET_IN_COMPLETE_STATS_BY_BRANCH_LEADS: BASE_URL_MARKETING + "getInCompleteStatsByBranchDataLeads?partnerName=",

    // applied Leads

    GET_APPLIED_STATS_BY_AGGREGATOR_SUBMITTED: BASE_URL_MARKETING + "getAppliedCountStatsForAggregatorSubmited?from=&to=&aggregatorName=n",
    GET_APPLIED_STATS_BY_BRANCH: BASE_URL_MARKETING + "getAppliedCountStatsBranchData?from=",

    // configuration
    GET_AGGREGATOR_NAME: BASE_URL_MARKETING + "getAggregatorName",



    GET_EVENT_DATA_BY_PARTNER_ID: BASE_URL_MARKETING + "getEventDataByPartnerId?partnerId=",
    GET_AGGREGATOR_PAYOUT: BASE_URL_MARKETING + "getAggregatorPayoutsByAggregatorId?aggregatorId=",

    // push leads
    GET_IN_COMPLETE_STATS_BY_PARTNER_ID: BASE_URL_MARKETING + "getInCompleteStatsByPartnerId?partnerId=",
    GET_IN_COMPLETE_DATA_BY_PARTNER_ID: BASE_URL_MARKETING + "getInCompleteDataByPartnerId?partnerId=",
    BULK_COMMUNICATION_BY_FORM_STATUS: BASE_URL_MARKETING + "bulkCommunicationByFormStatusPartnerId",

    // lead breakdown

    GET_BRANCH_STATS_BY_PARTNERID: BASE_URL_MARKETING + "getBranchStatsByPartnerId?partnerId=",
    GET_BRANCH_COMMUNICATE_BY_EVENT_NAME: BASE_URL_MARKETING + "getBranchCommunicateByEventName",

    // applied Leads

    GET_APPLIED_COUNT_STATS_BY_PARTNER_ID: BASE_URL_MARKETING + "getAppliedCountStatsByPartnerId?partnerId=",
    GET_APPLIED_DATA_BY_PARTNER_ID: BASE_URL_MARKETING + "getAppliedDataByPartnerId?partnerId=",

    // disbursed leads

    GET_APPLIED_DATA_BY_PARTNER_ID_LOAN_STATUS: BASE_URL_MARKETING + "getAppliedDataByPartnerId?partnerId=",
    GET_DISBURSED_COUNT_STATS_BY_PARTNER_ID: BASE_URL_MARKETING + "getDisbursedCountStatsByPartnerId?partnerId=",
    // configuration

    SAVE_OR_UPDATE_AGGREGATOR_PAYOUTS: BASE_URL_MARKETING + "saveOrUpdateAggregatorPayouts",
    GET_ANALYSIS_COUNT_API: BASE_URL_MARKETING + 'getDataAnalysisStats?sDate=',
    GET_COUNT_BY_STATUS_BY_PARTNER_ID: BASE_URL_MARKETING + "getCountByStatusByPartnerId?partnerId=",
    GET_DISBURSMENT_STATS: BASE_URL_MARKETING + "getDisbursmentStats?partnerId=",
    GET_REJECT_REASON_COUNT: BASE_URL_MARKETING + "getRejectReasonCount?partnerId=",
    GET_TOTAL_LEADS_RECIEVED: BASE_URL_MARKETING + "getTotalLeadsRecieved?partnerId=",
    GET_LEAD_PARTICULAR: BASE_URL_MARKETING + "getLeadParticular?partnerId=",
    GET_CONVERSTION_DETAILS: BASE_URL_MARKETING + "getConverstionDetails?partnerId=",
    GET_DEVIATION_REPORT: BASE_URL_MARKETING + 'deviationReport?startDate=',
    BULK_COMMUNICATION_BY_LOAN_STATUS: BASE_URL_MARKETING + 'bulkCommunicationByLoanStatusPartnerId',
    COMMUNICATE_VIA_FILTER: BASE_URL_MARKETING + 'communicateByFilter?startDate=',
    BUISNESS_FROM_AGREEGATORS: BASE_URL_MARKETING + 'businessFromAggregators?startDate=',
    GET_AUTOMATION_LOGS_NEW: BASE_URL_MARKETING + 'getAutomationMailLogUpdatedNew?startDate=',
    GET_AUTOMATION_LOGS_COUNT: BASE_URL_MARKETING + 'getAutomationMailLogCount?startDate=',
    GET_EVENTS_COUNTS: BASE_URL_MARKETING + 'getEventListCount?pageNumber=',
    GET_LEAD_PARTICULAR_OVERALL: BASE_URL_MARKETING + 'getMarketingLeadParticular?startDate=',
    GET_DASHOBARD_COUNTS_MARKETING: BASE_URL_MARKETING + 'getAggregatorDashboard?startDate=',
    GET_OVERALL_DASHBOARD_COUNTS: BASE_URL_MARKETING + 'getMarketingCounts?startDate=',
    GET_LEADS_COUNT_DASHBOARD_DATA: BASE_URL_MARKETING + 'getMarketingLeadCountDetails?startDate=',
    GET_DEVIATION_COUNT: BASE_URL_MARKETING + 'getMarketingDeviationReport?startDate=',
    GET_REJECT_REASON_COUNT_OVERALL: BASE_URL_MARKETING + 'getMarketingRejectReasonCount?startDate=',
    GET_DISBURSE_OVERALL_COUNT: BASE_URL_MARKETING + 'getMarketingDisbursmentStats?startDate=',
    GET_CREDIT_ANALYSIS_DATA: BASE_URL + 'getPolicyDetailsByLoanIdList?userId=',
    GET_ALL_POLICY_LIST: BASE_URL + 'getCreditPolicyByPageNo?pageNo=1&count=100',
    SAVE_OR_UPDATE_POLICY_DETAILS: BASE_URL + 'saveOrUpdateCreditPolicy',
    SAVE_OR_UPDATE_CREDIT_POLICY_LIST: BASE_URL + 'savePolicyDetailsList',
    GET_AGREEGATOR_CONFIG_BY_AGREEGATOR_ID: BASE_URL_MARKETING + 'getAggregatorPayoutsListByAggregatorId?aggregatorId=',
    GET_AGREEGATOR_MONTHLY_PLANING_BY_ID: BASE_URL_MARKETING + 'getMarketingMonthlyPlanningListByAggregatorId?aggregatorId=',
    GET_LOS_APPLICATION: BI_URL + 'api/loan_origination_system/application',
    GET_LOS_APPLICATION_OPPORTUNITY: BI_URL + 'api/loan_origination_system/Opportunity',
    GET_GENDER: BI_URL + 'api/loan_origination_system/gender',
    GET_DEVICE: BI_URL + 'api/loan_origination_system/device',
    GET_AGE_ANALYSIS: BI_URL + 'api/loan_origination_system/Age',
    GET_GROUP_SALARY: BI_URL + 'api/loan_origination_system/Groupped_salary',
    GET_OCCUPATION_ANALYSIS: BI_URL + 'api/loan_origination_system/Occupation',
    GET_AGE_COUNT: BI_URL + 'api/loan_origination_system/Age',
    GET_STATE_COUNT: BI_URL + 'api/loan_origination_system/State',
    GET_AVERAGE_LOAN_DETAILS: BI_URL + 'api/loan_origination_system/Average_loan_details',
    GET_APPLY_VS_APPROVE: BI_URL + 'api/loan_origination_system/ApplyVSapprove_system',
    GET_APPLY_VS_REJECT: BI_URL + 'api/loan_origination_system/rejectVSapprove_system1',
    GET_MANDATE_DATA_BY_REF_ID: BASE_URL + 'getMandateStatusByRefId?refId=',
    AADHAAR_CONSENT_API: BASE_URL + "karza/api/consentAadhaarNumberVerification?user_id=",

    INITIATE_AADHAAR_CONSENT: BASE_URL + "karza/api/initiateAadhaarConsent?user_id=",
    GET_AADHAAR_OTP: BASE_URL + 'karza/api/getAadhaarOtp?user_id=',
    SHARE_CODE_API: BASE_URL + 'karza/api/downloadAadhaarXml?user_id=',
    GET_USER_DATA: BASE_URL + 'userDetails/getUserDetailsByUserId?userId=',
    UPDATE_KYC_DETAILS: BASE_URL + 'userDetailsUpdated/updateKycDetails?userId=',
    GET_PAN_NOTIFY: BASE_URL + 'userDetailsUpdated/getKycNotify?userId=',
    GET_EMI_REDUCING_USER: BASE_URL + 'getemitopayReducinguser/',
    GET_CRIF_ACCESS_CHECK: BASE_URL + 'getCreditReportAccessByUserId?userId=',
    SAVE_CREDIT_CONFIG: BASE_URL + 'saveOrUpdateCreditAttributePt',
    MAP_CREDIT_POLICY: BASE_URL + 'saveOrUpdateCreditToAttr',
    GET_CONFIGURATION: BASE_URL + 'getCreditAttributePtByPageNo?pageNumber=',
    SAVE_MAPPING: BASE_URL + 'saveOrUpdateCreditToAttr',
    GET_MAPPED_DATA: BASE_URL + 'getCreditToAttrByPageNoPolicyId?pageNumber=',
    DELETE_MAPPING_API: BASE_URL + 'deleteCreditToAttrByCreditAttId?creditAttId=',
    DETELE_POLICY_API: BASE_URL + 'deleteCreditPolicyByPolicyId?policyId=',
    DETELE_ATTRIBUTE_API: BASE_URL + 'deleteCreditAttributePtByCreditAttId?creditAttId=',
    SEND_NOC_API: BASE_URL + 'loan/sendNocToUser?loanId=',
    FINBIT_ANALYSIS_API: BASE_URL + 'getFinBitAnalysis?userId=',
    DOWNLOAD_API_FILE: BASE_URL + 'downloadTrackerReport',
    GET_DEVIATION_VALUES: BASE_URL + 'getDeviationPts',
    SAVE_DEVIATION_POINT: BASE_URL + 'saveDeviationPts',
    UPLOAD_STATEMENT_TO_FINBIT: BASE_URL + 'getFinBitDataOnUpload?userId=',
    SHOW_DISABLE_ESIGN: BASE_URL + 'userDetailsUpdated/getEsignRepeatFlag?userId=',
    GET_LOAN_DETAIL_BY_USERID_OPTIMISED: BASE_URL + 'getLoanDetailByUserIdOptimised?userId=',
    GET_LOAN_LEADS_NEW_API: BASE_URL + 'loanLeadTestingNewNew?status=',
    // GET_COMMENTS_API: BASE_URL + 'getCommentForLeads?status=',

    GET_LEAD_PARTICULAR_LEADS_DATA_DATE_WISE: BASE_URL + 'getMarketingLeadParticularDateWise?startDate=',
    NEW_ACTIVITY_LOGS_API: BASE_URL + 'loan/getLoanTrackUpdated?loanId=',
    GET_OPERATION_CHECK_DATA: BASE_URL + 'getOperationCheckInfo?userId=',
    GET_OPERATION_FLOW: BASE_URL + 'getOperationFlowStatus?userId=',
    QUALITY_CHECK_API: BASE_URL + 'updateQualityCheck?loanId=',
    NEW_ACTIVITY_LOGS_API_SHORT: BASE_URL + 'loan/getLoanTrackLimit?loanId=',
    LEAD_MANAGEMENT_COUNT: BASE_URL + 'getLeadManagmentCounts?empId=',
    LEAD_MANAGEMENT_LEADS_API: BASE_URL + 'loanLeadTestingNewBucket?status=',
    GET_DASHBOARD_GRAPH_LM: BASE_URL + 'getOverAllDashboardCount?startDate=',
    GET_EMAIL_ID_VIA_DEPARTMENT: BASE_URL + 'getEmpIdByDepartmentList',
    DELETE_ASSIGNED_ENTRY: BASE_URL + 'deleteAssignApplicationById?id=',
    GET_SALARY_WISE_COUNT_DASHBOARD_DATA: BASE_URL_MARKETING + 'salaryRangeWiseCount?startDate=',
    GET_AGE_WISE_COUNT_DASHBOARD_DATA: BASE_URL_MARKETING + 'ageRangeWiseCount?startDate=',
    GET_EMI_WISE_COUNT_DASHBOARD_DATA: BASE_URL_MARKETING + 'emiWiseCount?startDate=',
    GET_GENDER_WISE_COUNT_DASHBOARD_DATA: BASE_URL_MARKETING + 'genderWiseCount?startDate=',
    GET_LOAN_STATUS_WISE_COUNT_DASHBOARD_DATA: BASE_URL_MARKETING + 'loanStatusWiseCount?startDate=',
    GET_REJECT_REASON_WISE_COUNT_DASHBOARD_DATA: BASE_URL_MARKETING + 'rejectReasonWiseCount?startDate=',
    GET_STATE_WISE_COUNT_DASHBOARD_DATA: BASE_URL_MARKETING + 'stateWiseCount?startDate=',
    SAVE_UNDERWRITTER_API: BASE_URL + 'saveUnderWriterDecision',
    GET_NOTES_API_NEW: BASE_URL + 'getNotesListForCreditAssessment?loanId=',
    GET_ANALYSIS_API_NEW: BASE_URL + 'karza/api/getKycAnalsis?userId=',
    CREDIT_ANALYSIS_NEW_API: BASE_URL + 'getAnalysisFinBitSmsSalaryAnalysis?userId=',
    GET_COMMENTS_API: BASE_URL + 'getCommentForLeadsBucket?status=',
    SAVE_WORKING_HISTRORY_API: BASE_URL + 'saveCreditWorkingHistory',
    GET_WORKING_HISTORY_DATA: BASE_URL + 'getWorkingHistoryByUserId?userId=',
    GET_ORG_DETAILS: BASE_URL + 'getOrganizationAnalysis?userId=',
    REPEAT_USER_CHECK_UPDATED: BASE_URL + 'repeatUserUpdated?userId=',
    GET_ALL_FORM_STATS: BASE_URL + 'getInCompleteStats?startDate=',
    DOWNLOAD_REPEAT_DATA: BASE_URL + 'downloadRepeatData?empId=',
    CHECK_MOBILE_EXIST: BASE_URL + 'checkMobileExists?mobileNo=',
    GET_SCORE_CATEGORY_LIST: BASE_URL + 'getScoreCategoryList',
    SAVE_SCORE_CATEGORY_LIST: BASE_URL + 'saveOrUpdateCreditScoreCategory',
    GET_POINTERS_CATEGORY_LIST: BASE_URL + 'getCreditScoreConfigList',
    GET_SAVED_CATEGORY_BY_USERID: BASE_URL + 'getUserCreditScoreByUserId?userId=',
    PROJECTION_DATA_MONTHS: BASE_URL + 'projection?noOfMonths=',
    GET_AUTOMATED_SCORING: BASE_URL + 'getAutomatedScoringByUserId?userId=',
    SAVE_POINTERS_MAP_API: BASE_URL + 'saveOrUpdateCreditScoreConfig',
    SAVE_SCORE_OF_AUTOMATED: BASE_URL + 'getDbrCalculationNewScoring',
    FACE_MATCH_API: BASE_URL + 'karza/api/getFaceSimilarityResult?userId=',
    GET_FACE_DATA: BASE_URL + 'karza/api/getFaceSimilarityDetailsByUserId?userId=',
    READ_LOAN_ID_CSV: BASE_URL + 'readCSVOfUpdateLoanId',
    SAVE_LOAN_ID_CSV: BASE_URL + 'updateLoanIdByCSV',
    PROJECTION_DAY_WISE: BASE_URL + 'projectionPerDay?noOfMonths=',
    DELETE_RAZORPAY_TOKEN: BASE_URL + 'enashAndEsign/deleteTokenByUserIdList',
    // DATA_FOR_RECONCILAIATION: BASE_URL + 'getPaymentsDataForReconciliation?startDate=',
    UPLOAD_RAZORPAY_REPORT: BASE_URL + 'getRazorpayReportCsv',
    GET_COUNT_RECONCILAIATION: BASE_URL + 'getSelectedReconciliationCount',
    GET_DATA_BY_TYPE: BASE_URL + 'getSelectedReconciliationDataByType',
    GET_OPERATIONS_EMPLOYEE: BASE_URL + 'operations/getTeamMembers?page=',
    GET_PERFORMANCE_DATA_BY_EMAIL: BASE_URL + 'operations/report?startDate=',
    GET_THIRD_PARTY_HIT_COUNT_BY_APPLICATIONID: BASE_URL + 'getThirdPartyHitCountsByApplicationId?applicationId=',
    GET_VERIFIED_BANK_DETAILS_API: BASE_URL + 'karza/api/getBankAccVerificationByUserId?user_id=',
    GET_OPETRATION_PERFORMANCE_API: BASE_URL + 'operations/empPerformanceAnalysis?startDate=',
    GET_SOFTAPPROVAL_API: BASE_URL + 'operations/empCommentStats?startDate=',
    DOWNLOAD_KARZA_REPORT_BY_TYPE: BASE_URL + 'downloadKarzaReportByType',
    GET_LOAN_COUNT_BY_DATE: BASE_URL + 'getLoanCountByDate',
    KYC_START_STOP_API: BASE_URL + 'updateRepeatKyc?flag=',
    GET_KYC_FLAGS: BASE_URL + 'getKycFlags',
    GET_SMALL_DASHBOARD_OPERATION_API: BASE_URL + 'operations/getPerformanceStats?startDate=',
    OPERTAIONS_TABULAR_DATA_API: BASE_URL + 'operations/detailProcessReport?startDate=',
    DOWNLOAD_OPERATION_REPORT_API: BASE_URL + 'operations/detailProcessReportDownload?startDate=',
    GET_PENDING_KYC_LEADS: BASE_URL + 'karza/api/getPendingKycList?startDate=',
    UPLOAD_STATEMENT_FOR_PARSING_DIGITAP: BASE_URL + 'uploadStatementForParsing?userId=',
    GET_DIGITAB_BASIC_DATA: BASE_URL + 'getDigitapBasicData?userId=',
    GET_FRAUD_FILTER_LIST: BASE_URL + 'getFraudFilterList?userId=',
    GET_DIGITAP_DATA_BY_TYPE: BASE_URL + 'getDigitapDataByType?userId=',
    GET_BANK_STATEMENT_PARSE_SOURCE: BASE_URL + 'getstatementparsesource?userId=',
    GET_CUSTOMER_SERVICE_EMPLOYEE: BASE_URL + 'businessDevelopment/getTeamMembers?page=',
    GET_PERFORMANCE_DATA_BY_EMAIL_CUSTOMER_SERVICE: BASE_URL + 'businessDevelopment/report?startDate=',
    GET_CUSTOMER_SERVICE_PERFORMANCE_API: BASE_URL + 'businessDevelopment/empPerformanceAnalysis?startDate=',
    GET_SOFTAPPROVAL_API_CUSTOMER_SERVICE: BASE_URL + 'businessDevelopment/empCommentStats?startDate=',
    GET_SMALL_DASHBOARD_OPERATION_API_CUSTOMER_SERVICE: BASE_URL + 'businessDevelopment/getPerformanceStats?startDate=',
    OPERTAIONS_TABULAR_DATA_API_CUSTOMER_SERVICE: BASE_URL + 'businessDevelopment/detailProcessReport?startDate=',
    DOWNLOAD_OPERATION_REPORT_API_CUSTOMER_SERVICE: BASE_URL + 'businessDevelopment/detailProcessReportDownload?startDate=',
    GET_REPEAT_FRESH_EMPLOYEE: BASE_URL + 'freshCredit/getTeamMembers?page=',
    GET_PERFORMANCE_DATA_BY_EMAIL_REPEAT_FRESH: BASE_URL + 'freshCredit/report?startDate=',
    GET_REPEAT_FRESH_PERFORMANCE_API: BASE_URL + 'freshCredit/empPerformanceAnalysis?startDate=',
    GET_SOFTAPPROVAL_API_REPEAT_FRESH: BASE_URL + 'freshCredit/empCommentStats?startDate=',
    GET_SMALL_DASHBOARD_OPERATION_API_REPEAT_FRESH: BASE_URL + 'freshCredit/getPerformanceStats?startDate=',
    OPERTAIONS_TABULAR_DATA_API_REPEAT_FRESH: BASE_URL + 'freshCredit/detailProcessReport?startDate=',
    DOWNLOAD_OPERATION_REPORT_API_REPEAT_FRESH: BASE_URL + 'freshCredit/detailProcessReportDownload?startDate=',
    GET_REPEAT_EMPLOYEE: BASE_URL + 'repeatCredit/getTeamMembers?page=',
    GET_PERFORMANCE_DATA_BY_EMAIL_REPEAT: BASE_URL + 'repeatCredit/report?startDate=',
    GET_REPEAT_PERFORMANCE_API: BASE_URL + 'repeatCredit/empPerformanceAnalysis?startDate=',
    GET_SOFTAPPROVAL_API_REPEAT: BASE_URL + 'repeatCredit/empCommentStats?startDate=',
    GET_SMALL_DASHBOARD_OPERATION_API_REPEAT: BASE_URL + 'repeatCredit/getPerformanceStats?startDate=',
    OPERTAIONS_TABULAR_DATA_API_REPEAT: BASE_URL + 'repeatCredit/detailProcessReport?startDate=',
    DOWNLOAD_OPERATION_REPORT_API_REPEAT: BASE_URL + 'repeatCredit/detailProcessReportDownload?startDate=',
    DOWNLOAD_KYC_PENDING_DATA: BASE_URL + 'karza/api/downLoadPendingKycList?startDate=',
    ENACH_DATA_LEADS: BASE_URL + 'enashAndEsign/getEnachDataLeadNew?pageNumber=',
    GET_AML_DATA_BY_USERID: BASE_URL + 'getAMLDataByUserId?userId=',
    DOWNLOAD_DISBURSE_REPORT: BASE_URL + 'getDisbursedReportData?sDate=',
    SAVE_KYC_UNDERWRITTER: BASE_URL + 'karza/api/saveKycAnalysisOfUserApplication?userId=',
    GET_KYC_BOX_DATA: BASE_URL + 'karza/api/getKycAnalysisOfUserApplication?userId=',
    DOWNLOAD_INCOME_BREAKUP_REPORT: BASE_URL + 'getIncomeBreakdownData?sDate=',
    DOWNLOAD_EMI_MODIFY_REPORT: BASE_URL + 'getEMIModifyReportData?sDate=',
    DOWNLOAD_EMI_COLLECTION_REPORT: BASE_URL + 'getEmiCollectionReportData?sDate=',
    DOWNLOAD_EMI_DAILY_HIT_REPORT: BASE_URL + 'getEmiDailyHitReportData?sDate=',
    GET_INTERNAL_DEDUPE_DATA: BASE_URL + 'loan/getInternalDedupeAnalysisOfUserApplication?userId=',
    SAVE_DEDUPEDATA: BASE_URL + 'loan/saveInternalDedupeAnalysisOfUserApplication?userId=',
    GET_ORGANISATION_ANALYSIS_DATA: BASE_URL + 'getUserLoanOrganisationAnalysis?userId=',
    GET_OUTSTANDING_DUE_REPORT: BASE_URL + 'getOutstandingReportData?disbursedDate=',
    UPDATE_NAME_AS_ON_PAN: BASE_URL + 'updatePanNameInUserInformation?userId=',
    GET_REJECTION_REPORT: BASE_URL + 'getRejectionReportData?sDate=',
    UPDATE_BANK_STATEMENT_STATUS: BASE_URL + 'updateBankStatementStatus?loanId=',
    UPDATE_REAPEAT_CUSTOMER: BASE_URL + 'updateNewUserStatus?loanId=',
    GET_DAILY_EMIT_HIT_REPORT: BASE_URL + 'getDailyEmiReportData?sDate=',
    GET_DISBURSE_CREDIT_DATA: BASE_URL + 'getDisbursedReportCreditData?sDate=',
    GET_DATA_ANALYSIS_STATUS_BY_USERID: BASE_URL + 'getDataAnalaysisStatusByUserId?userId=',
    GET_APPLICATION_ID_LIST_REGISTERED_WITH_DRP: BASE_URL + 'getLastUserEmiWithDRPMandate?loanStatus=',
    BULK_DEACTIVATE_DRP_NACH: BASE_URL + 'enashAndEsign/deactivateEnachList',
    UPLOAD_OVERDUE_SMS_CSV: BASE_URL + 'getCitraBulkCommunicationOverdueCsv',
    SEND_MSG_BULK: BASE_URL + 'sendCitraOverdueSMS',
    GET_RECEIVED_EMI_AMOUNT_REPORT: BASE_URL + 'emiAmountReceivedReport?sDate=',

    GENERATE_PAYMENT_LINK: BASE_URL + 'payments/razorpay/createPaymentLink',
    GET_GENERATED_PAYMENT_LINK_HISTORY: BASE_URL + 'payments/razorpay/getAllPaymentLinkByLoanId?loanId=',
    DELETE_GENERATED_PAYMENT_LINK: BASE_URL + 'payments/razorpay/deactivatePaymentLink?loanId=',
    GET_ACCOUNTING_DISBURSE_DATA: BASE_URL + 'getDailyAccountingDisbursementReport?sDate=',
    GET_TRACKWIZZ_RESPONSE: BASE_URL + 'getTrackWizzResponseByUserId?userId=',
    GET_TXN_BY_LOANID: BASE_URL + 'getTransactionByLoanId?loanId=',
    GET_BILL_DESK_LEADS: BASE_URL + 'getUnpaidEmiWithBillDeskMandate',
    CREATE_INVOICE_BILLDESK: BASE_URL + 'createInvoice?loanId=',
    CHECK_USER_DOC: BASE_URL + 'userDocumentsExists?userId=',
    STAMP_DUTY_UPLOAD_HISTORY: BASE_URL + 'getStampDutyUploadedHistory?pageNo=',
    STAMP_USED_HISTORY: BASE_URL + 'getStampDutyUsageHistory?sDate=',
    GET_CALULATED_AMOUNT: BASE_URL + 'getEMIBreakUpAfterWaiver',
    GET_EMI_BREAKUP_FOR_ONE_EMI: BASE_URL + 'getbulletemitopayuser/',
    GET_ALL_PAYMENTS_API: BASE_URL + 'payments/getAllPayments?startDate=',
    SET_ORGANIZATION_API: BASE_URL + 'orgEmailVerifiedViaByUserId?userId=',
    // GET_DETAILS_OF_TXN: BASE_URL + 'payments/getBreakdownDetailsByEmiId?emiId='
    GET_DETAILS_OF_TXN: BASE_URL + 'payments/getBreakdownDetailsByPaymentId?paymentId=',
    ADD_OR_UPDATE_EMAIL_TEMPLATE: BASE_URL + 'saveOrUpdateEmailTemplate',
    GET_EMAIL_TEMPLATES: BASE_URL + 'getAllEmailTemplates',
    DELETE_TEMPLATE_EMAIL: BASE_URL + 'deleteEmailTemplateByName?templateName=',
    GET_SMS_TEMPLATES: BASE_URL + 'getAllSmsTemplates',
    ADD_OR_UPDATE_SMS_TEMPLATE: BASE_URL + 'saveOrUpdateSmsTemplate',
    DELETE_TEMPLATE_SMS: BASE_URL + 'deleteSmsTemplateByName?templateName=',
    GET_COMPLETE_APPLICATION_STATUS: BASE_URL + 'getStatusCountAnalysisByDateAndDateType?sDate=',
    GET_BANK_STATEMENT_STATUS: BASE_URL + 'getBankStatementsStatus?userId=',
    GET_BANK_NAME_LIST: BASE_URL + 'getInstitutionList',

    // Executor 
    GET_ALL_AUTOMATED_PROCESS: BASE_URL + 'getLatestAutomatedExecutorData',
    SAVE_UPDATE_AUTOMATED_PROCESS: BASE_URL + 'saveOrUpdateAutomatedProcess',
    UPDATE_AUTOMATED_PROCESS_STATUS: BASE_URL + 'updateAutomatedProcessActiveStatus?processName=',
    DELETE_PROCESS_BY_PROCESS_NAME: BASE_URL + 'deleteProcessByName?processName=',
    GET_AUTOMATED_PROCESS_BY_FILTER: BASE_URL + 'getAutomatedProcessByFilter?',
    GET_ALL_PROCESS_NAMES: BASE_URL + 'getAllProcessNames',
    // loan utility
    GET_ALL_LOAN_UTILITY: BASE_URL + 'getAllLoanUtilityByPagination?pageNo=',
    SAVE_UPDATE_LOAN_UTILITY: BASE_URL + 'saveOrUpdateLoanUtility',
    DELETE_LOAN_UTILITY: BASE_URL + 'deleteLoanUtility?id=',
    GET_LOAN_UTILITY_BY_PRODUCTID: BASE_URL + 'getLoanUtilityByProductName?productName=',
    //  Processing fee 
    GET_ALL_PROCESSING_FEE: BASE_URL + 'getAllProcessingFeeByPagination?pageNo=',
    DELETE_PROCESSING_FEE: BASE_URL + 'deleteProcessingFee?id=',
    GET_PROCESSING_FEE_BY_PRODUCTID: BASE_URL + 'getProcessingFeeByProductId?productId=',
    SAVE_UPDATE_PROCESSING_FEE: BASE_URL + 'saveOrUpdateProcessingFee',

    SAVE_UPDATE_AUTOMATED_PROCESS: BASE_URL + 'saveOrUpdateAutomatedProcess',
    UPDATE_AUTOMATED_PROCESS_STATUS: BASE_URL + 'updateAutomatedProcessActiveStatus?processName=',
    ///productApi////
    SAVE_DOC_PRODUCTS: BASE_URL + 'saveOrUpdateProductDetails',
    GET_DOC_PRODUCTS: BASE_URL + 'getProductDetails?pageNo=',
    GET_PRODUCTS_COUNT: BASE_URL + 'getProductDetailsCount',
    DELETE_PRODUCTS: BASE_URL + 'deleteProductDetails?productId=',
    GET_PRODUCT_BY_NBFC_ID_NEW: BASE_URL + 'getAllProducts?nbfcId=',
    GET_CALCULATION: BASE_URL + 'getCalculations?productId=',
    EDIT_CALCULATION: BASE_URL + 'editCalculation',
    GET_PRODUCT_BY_DOCTORID: BASE_URL + 'getAllProductDetail?doctorId=',
    ASSIGN_PRODUCT_TO_DOCTOR: BASE_URL + 'assignProductToDoctor?doctorId=',
    CHANGE_PRODUCT_STATUS: BASE_URL + 'changeProductActivationStatus?status=',
    UN_ASSIGN_PRODUCT_TO_DOCTOR: BASE_URL + 'deleteDoctorProduct?doctorId=',
    UPLOAD_DOCTOR_RATING_FILE: BASE_URL + 'uploadDoctorDetails',
    SAVE_PROCESSING_FEE_TYPE: BASE_URL + 'updateProcessingFeeTypeUsingProductId?productId=',
    RESTART_PROCESS: BASE_URL + 'automation/restartAutomationProcessByProcessName?processName=',
    GET_LOGS_BY_EXECUTOR_NAME: BASE_URL + 'getExecutorLogsByFilter?executorName=',
    GET_ALL_ORGANISATION: BASE_URL + 'getAllOrganisation',
    ////
    SAVE_EMPLOYEE_DATA_FOR_TARGET: FINANCE_BACKEND + 'employee/saveAndUpdateEmployeeDetail',
    GET_ALL_EMPLOYEES: FINANCE_BACKEND + 'employee/getAllEmployeeDetailsList',
    DELETE_EMPLOYEE_BY_ID: FINANCE_BACKEND + 'employee/deleteEmployeeDetailsById?id=',
    SAVE_EXPENSE_DATA: FINANCE_BACKEND + 'expense/saveAndUpdateExpense',
    GET_ALL_EXPENSE: FINANCE_BACKEND + 'expense/getAllExpenseList?sDate=',
    DELETE_EXPENSE_BY_ID: FINANCE_BACKEND + 'expense/deleteExpenseById?id=',
    SAVE_EXPENSE_CATEGORY: FINANCE_BACKEND + 'expense/saveAndUpdateExpenseCategory',
    GET_ALL_EXPENSE_CATEGORY: FINANCE_BACKEND + 'expense/getAllExpenseCategoryList',
    DELETE_EXPENSE_CATEGORY_BY_ID: FINANCE_BACKEND + 'expense/deleteExpenseCategoryById?id=',
    SAVE_EXPENSE_SUB_CATEGORY: FINANCE_BACKEND + 'expense/saveAndUpdateExpenseSubCategory',
    GET_ALL_EXPENSE_SUB_CATEGORY: FINANCE_BACKEND + 'expense/getAllExpenseSubCategoryList',
    DELETE_EXPENSE_SUBCATEGORY_BY_ID: FINANCE_BACKEND + 'expense/deleteExpenseSubCategoryById?id=',
    SAVE_EXPENSE_DESCRIPTION: FINANCE_BACKEND + 'expense/saveAndUpdateExpenseDescription',
    GET_ALL_DESCRIPTION: FINANCE_BACKEND + 'expense/getAllExpenseDescription',
    DELETE_EXPENSE_DESCRIPTION_BY_ID: FINANCE_BACKEND + 'expense/deleteExpenseDescription?id=',
    SAVE_EXPENSE_TARGET: FINANCE_BACKEND + 'expense/saveAndUpdateExpenseTarget',
    GET_EXPENSE_TARGET: FINANCE_BACKEND + 'expense/getAllExpenseTarget?sDate=',
    DELETE_EXPENSE_TARGET_BY_ID: FINANCE_BACKEND + 'expense/deleteExpenseTarget?id=',
    // business
    GET_ALL_BUSINESS: FINANCE_BACKEND + 'businessPlanning/getAllBusinessPlanning',
    SAVE_BUSINESS_DATA: FINANCE_BACKEND + 'businessPlanning/saveBusinessPlanning',
    UPDATE_BUSINESS_DATA: FINANCE_BACKEND + 'businessPlanning/updateBusinessPlanning',

    GET_ALL_BUSINESS_RETURN: FINANCE_BACKEND + 'businessPlanning/getAllBusinessReturn',
    SAVE_BUSINESS_RETURN_DATA: FINANCE_BACKEND + 'businessPlanning/saveBusinessReturn',
    UPDATE_BUSINESS_RETURN_DATA: FINANCE_BACKEND + 'businessPlanning/updateBusinessReturn',

    GET_ALL_BUSINESS_TARGET_CATEGORY: FINANCE_BACKEND + 'businessPlanning/getAllBusinessTargetCategory',
    SAVE_BUSINESS_TARGET_CATEGORY_DATA: FINANCE_BACKEND + 'businessPlanning/saveBusinessTargetCategory',
    UPDATE_BUSINESS_TARGET_CATEGORY_DATA: FINANCE_BACKEND + 'businessPlanning/updateBusinessTargetCategory',

    GET_ALL_BUSINESS_TARGET_SUBCATEGORY: FINANCE_BACKEND + 'businessPlanning/getAllBusinessTargetSubCategory',
    SAVE_BUSINESS_TARGET_SUBCATEGORY_DATA: FINANCE_BACKEND + 'businessPlanning/saveBusinessTargetSubCategory',
    UPDATE_BUSINESS_TARGET_SUBCATEGORY_DATA: FINANCE_BACKEND + 'businessPlanning/updateBusinessTargetSubCategory',

    GET_ALL_BUSINESS_TARGET: FINANCE_BACKEND + 'businessPlanning/getAllBusinessTarget',
    SAVE_BUSINESS_TARGET_DATA: FINANCE_BACKEND + 'businessPlanning/saveBusinessTarget',
    UPDATE_BUSINESS_TARGET_DATA: FINANCE_BACKEND + 'businessPlanning/updateBusinessTarget',
 
    GET_ALL_DEPARTEMENT_LIST: FINANCE_BACKEND + 'getDepartmentList',
    SAVE_DEPARTMENT_DATA: FINANCE_BACKEND + 'saveDepartment',
    UPDATE_DEPARTMENT_DATA: FINANCE_BACKEND + 'updateDepartment',
    DELETE_DEPARTMENT_DATA: FINANCE_BACKEND + 'deleteDepartmentById?id=',
    ////
    REGENERATE_CRIF_CHECK: BASE_URL + 'isCrifGenerated?userId=',
    NBFC_DATA_NEW: BASE_URL + 'getLoanUtilityByNbfcSeparation',
    SAVE_EMAIL_KEY: BASE_URL + 'saveEmailDirectory?key=',
    GET_EMAIL_KEYS: BASE_URL + 'getAllEmailDirectory',
    UPDATE_EMAIL_KEY: BASE_URL + 'updateEmailDirectory?key=',
    DELETE_EMAIL_KEY: BASE_URL + 'deleteEmailDirectoryByKey?key=',
    APPLICATION_STATUS_COUNT: BASE_URL + 'getApplicationStatsByDate?startDate=',
    INCOMING_PAYMENT_COUNT: BASE_URL + 'getIncomingPaymentStatsByDateAndType?eDate=',
    EMI_OVERDUE_LIST:BASE_URL+'payments/getAllOverdueEMIsList?startDate=',

    // NPA Dashboard 
    GET_MONTH_WISE_OVERDUE_STATS :FINANCE_BACKEND + 'getMonthWiseOverdueStats?sDate=',
    GET_MONTH_WISE_OVERDUE_STATS_DETAILED :FINANCE_BACKEND + 'getMonthlyOverdueStatsDetailed?sDate=',

    GET_DUE_OVERDUE_COUNTS :FINANCE_BACKEND + 'getDueVsOverdueCounts?sDate=',
    GET_INVOICED_PAID_AMOUNT_MONTHLY :FINANCE_BACKEND + 'getInvoicedVsPaidAmountMonthly?sDate=',
    GET_OVERDUE_AGE_SUMMARY :FINANCE_BACKEND + 'getOverdueAgeSummary?sDate=',



























};

const WEB_URL = 'https://phocket.in/'

export {
    APIS,
    BASE_URL,
    WEB_URL,
    ESIGN_KEY,
    CARD_URL
};