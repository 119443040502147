import React from "react";
import { aadharVerificationDetails, aadharXmlData, aadharXmlDetails, checkUserDoc, getOperationChcekDataByUserId, getOperationFlowByUserId, panProfileDetails, postCallAdharVerification, postCallPanDetails, qualityCheckApi, rfdApi, triggerKycApi, veriFyReferenceDetails } from "../AdminActionCreator.component";
import LogoComplete from '../../../../images/rewampimages/progress.png'
import BlueCircle from '../../../../images/rewampimages/progressblue.png'
import IdentityLogo from '../../../../images/rewampimages/ID Card.png'
import GreenTick from '../../../../images/rewampimages/greentick.png'
import BankLogo from '../../../../images/rewampimages/bank.png'
import CommTab from "./CommunicationNew/mainTabsCommunicate";
import UnverifiedLogo from '../../../../images/rewampimages/unverifiedCross.png'
import PopUp from "../../../presentationals/Popup/Popup.component";
import { ROLE } from "../../../../utils/Constant";
import EditLogo from '../../../../images/rewampimages/edit.png'
import ReferenceEdit from "./UserEdit/reference.conatiner";
import KarzaPOPUPUI from '../../../presentationals/Popup/KarzaPopup.component'
import AAdharPopup from '../../../presentationals/Popup/KarzaAddharPopup.component'


class Operations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activityData: '',
            flowData: '',
            viewTab: 'softApproval',
            operationsData: '',
            loaderState: false,
            popupStatus: '',
            popupState: false,
            markButton: false,
            referencePopup: false,
            verificationType: '',
            aadharVerificationData: '',
            typeOfData: '',
            paninfo: false,
            aadharInfo: false,
            karzaAdharData: '',
            karzaData: '',
            addharPopup: false,
            panPopup: false,
            showButton: false,
            errorMessage:''
        }
    }
    getData() {
        const { allDetail } = this.props
        getOperationChcekDataByUserId(allDetail.userId, callBack => {
            this.setState({ operationsData: callBack })
        })
    }
    karzaPopup(e, type) {
        const { allDetail, userDetail } = this.props;

        if (type === 'pan') {

            if (this.state.karzaData.namePanAuthentication === null) {
                if (allDetail.applicationStatus !== 'Rejected') {
                    this.setState({ loaderState: true })
                    postCallPanDetails(allDetail.userId, callBack => {
                        this.setState({ karzaData: callBack, paninfo: true, loaderState: false }, () => this.getpanProfileDetailsData(allDetail.userId))
                    })
                }
            }
            if (this.state.karzaData.status === undefined || this.state.karzaData.status === '' || this.state.karzaData.status === null && allDetail.applicationStatus !== 'Rejected') {
                this.setState({ loaderState: true })
                postCallPanDetails(allDetail.userId, callBack => {
                    if (callBack !== null && callBack !== '' && callBack !== undefined && callBack.result !== '' && callBack.result !== null && callBack.result !== undefined) {
                        this.setState({ karzaData: callBack.result, paninfo: true, loaderState: false, panPopup: true }, () => this.getpanProfileDetailsData(allDetail.userId))
                    } else {
                        this.setState({ loaderState: false, popupStatus: 'Data Not Available', popupState: true }, () => this.removePopup())
                    }

                })
            } else {
                this.setState({ panPopup: true, paninfo: true })

            }
        }
        if (type === 'aadhar') {

            aadharVerificationDetails(allDetail.userId, callBack => {
                if (callBack.gender === null) {
                    postCallAdharVerification(allDetail.userId, allDetail.aadharNumber, callBack => {
                        aadharVerificationDetails(allDetail.userId, callBack => {
                            this.setState({ aadharVerificationData: callBack, addharPopup: true })
                        })
                    })
                } else {
                    this.setState({ aadharVerificationData: callBack, addharPopup: true })

                }
            })
            if (this.state.typeOfData === 'aadhaar') {
                this.setState({ verificationType: 'XML' })

                this.setState({ loaderState: true })

                aadharXmlDetails(allDetail.userId, callBack => {
                    if (callBack) {
                        this.setState({ karzaAdharData: callBack, loaderState: false, addharPopup: true })
                    }
                })
            }
            if (this.state.typeOfData === 'digiLocker') {
                this.setState({ verificationType: 'Digilocker' })
            }

        }
    }

    downloadFile(filename, content) {


        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;base64,' + content);
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);

    }

    getpanProfileDetailsData(userId) {
        panProfileDetails(userId, callBack => {
            if (callBack !== null && callBack !== '' && callBack !== undefined) {
                this.setState({ karzaData: callBack })
            }
        })
    }
    componentDidMount() {
        this.getData()
        this.getFlowData()
        this.getkyc()
        this.checkdoc()
    }
    checkdoc() {
        const { allDetail } = this.props

        checkUserDoc(allDetail.userId, callBack => {
            if (callBack === 'success') {
                this.setState({errorMessage:''})
                this.setState({ showButton: true })
            } else {
                this.setState({ showButton: false })

            }
            if (callBack === 'failure') {
                this.setState({ errorMessage: 'Contact Tech Admin' })

            }
            if (callBack === 'At least 1 document is required') {
                this.setState({ errorMessage: 'Atleast 1 doc required, please upload it' })
            }
        })
    }
    getkyc() {
        const { allDetail } = this.props

        panProfileDetails(allDetail.userId, callBack => {
            if (callBack !== null && callBack !== '' && callBack !== undefined) {

                this.setState({ karzaData: callBack, paninfo: true })
            } else {

            }
        })

        aadharXmlData(allDetail.userId, callBack => {
            if (callBack !== '' && callBack !== null) {
                // aadharXmlDetails(allDetail.userId, callBack => {
                this.setState({ typeOfData: callBack.type, aadharDataFromKarza: callBack })

                // })
                if (callBack.status === true) {
                    this.setState({ aadharInfo: true })
                }


            }
        })
    }
    getFlowData() {
        const { allDetail } = this.props

        getOperationFlowByUserId(allDetail.userId, allDetail.loanId, callBack => {
            console.log(callBack)
            this.setState({ flowData: callBack })
        })
    }
    triggerKycButton() {
        const { admin, userDetail } = this.props
        this.setState({ loaderState: true })
        triggerKycApi(userDetail, callBack => {
            // console.log(callBack)
            if (callBack === 'success') {
                this.setState({ loaderState: false, popupState: true, popupStatus: 'successfully done!' }, () => this.removePopup())
            } else {
                this.setState({ loaderState: false, popupState: true, popupStatus: 'please try again later!' }, () => this.removePopup())

            }

        })
    }
    qualityCheck() {
        const { admin, userDetail } = this.props
        this.setState({ loaderState: true })

        qualityCheckApi(userDetail, !this.state.flowData.qualityCheckDone, admin.emailId, callBack => {
            if (callBack === 'success') {
                this.setState({ loaderState: false, popupState: true, popupStatus: 'successfully done!' }, () => this.removePopup())
                this.getData()
                this.getFlowData()
                this.getkyc()
            } else {
                this.setState({ loaderState: false, popupState: true, popupStatus: 'please try again later!' }, () => this.removePopup())

            }
        })
    }
    removePopup() {
        setTimeout(() => {
            this.setState({ popupState: false })
        }, 5000);
    }
    markButtonPopup() {
        this.setState({ markButton: false })
    }
    RfdFunction(data) {
        const { admin, userDetail } = this.props
        rfdApi(userDetail.loanId, data, admin.emailId, userDetail.userId, userDetail.giftCardId, callBack => {
            console.log(callBack)
            if (callBack === 'success') {
                this.setState({
                    popupState: true,
                    popupStatus: 'saved successfully'
                })
                this.removePopup()
            } else {
                this.setState({
                    popupState: true,
                    popupStatus: 'Please Try Again later!'
                })
                this.removePopup()
            }
        })
    }
    closePopup() {
        this.setState({ popupState: false, addharPopup: false, panPopup: false })
    }
    changeVeriFyStatus(data, i) {
        let typeToSend = i === 0 ? 'one' : i === 1 ? 'two' : i === 2 ? 'three' : ''
        const { admin, allDetail, userDetail } = this.props
        if (typeToSend !== '') {
            this.setState({ loaderState: true })
            veriFyReferenceDetails(allDetail.userId, true, data.number, typeToSend, admin.emailId, allDetail.loanId, callBack => {
                this.setState({ loaderState: false }, () =>
                    this.getFlowData()
                )
            })
        }
    }
    getuserReference() {
        const { allDetail, userDetail } = this.props;

        this.setState({ userReference: this.state.flowData.userReferenceList })

    }
    editHandler() {
        this.setState({ referencePopup: false })
    }
    render() {
        const { allDetail, admin, userDetail, userDetails } = this.props
        return (
            <>
                {this.state.popupState ?
                    <PopUp popupStatus={this.state.popupStatus}
                        closePopup={this.closePopup.bind(this)}
                    />
                    : ""}
                {this.state.referencePopup ?
                    <ReferenceEdit getuserReference={this.getuserReference.bind(this)} admin={admin} userdata={allDetail} editHandler={this.editHandler.bind(this)} />
                    : ""}
                {this.state.panPopup ?
                    <KarzaPOPUPUI userDetail={allDetail} karzaData={this.state.karzaData} closePopup={this.closePopup.bind(this)} />
                    : ""}
                {this.state.addharPopup ?
                    <AAdharPopup verificationType={this.state.verificationType} karzaData={this.state.karzaAdharData} closePopup={this.closePopup.bind(this)}
                        downloadFile={this.downloadFile.bind(this)} aadharVerificationData={this.state.aadharVerificationData} />
                    : ""}
                <div className="activity-logs container-fluid animated fadeIn " style={{ marginTop: '140px' }} onClick={this.state.markButton ? () => this.markButtonPopup() : ""}>
                    <div className='row'>
                        {/* <div className='col-sm-1 col-xs-12'>
                        </div> */}
                        <div className='col-sm-4 col-xs-12'>
                            <div className="row">
                                <div className="col-sm-2">
                                    <div className='text-center'>
                                        <img src={LogoComplete} width={'30px'} />
                                    </div>
                                    <div className='stepperlineinActive-ops-active'></div>

                                </div>
                                <div className="col-sm-8 col-xs-12" onClick={() => this.setState({ viewTab: 'softApproval' })} style={{ cursor: 'pointer' }}>
                                    <div className={this.state.viewTab === 'softApproval' ? 'active-ops-active' : 'active-ops'}>
                                        <div><b>Assigned to Operations</b></div>
                                        <div style={{ fontSize: '12px' }}>{allDetail.applicationId} assigned to {this.state.flowData !== '' && this.state.flowData.assignTo !== undefined ? this.state.flowData.assignTo : ""}</div>
                                        <div style={{ fontSize: '12px', color: '#00C851' }}>Soft Approval Done</div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-2">
                                    <div className='text-center'>
                                        {
                                            this.state.flowData !== '' && this.state.flowData.qualityCheckDone !== undefined && this.state.flowData.qualityCheckDone ?
                                                <img src={LogoComplete} width={'30px'} /> :
                                                <img src={BlueCircle} width={'30px'} />}
                                    </div>
                                    {
                                        this.state.flowData !== '' && this.state.flowData.qualityCheckDone !== undefined && this.state.flowData.qualityCheckDone ?
                                            <div className='stepperlineinActive-ops-active'></div>
                                            : <div className='stepperlineinActive-ops'></div>}


                                </div>
                                <div className="col-sm-8 col-xs-12" onClick={this.state.flowData !== '' ? this.state.flowData.qualityCheckDone ? () => this.setState({ viewTab: 'quality' }) : "" : ""} style={{ cursor: 'pointer' }}>
                                    <div className={this.state.viewTab === 'quality' ? 'active-ops-active' : 'active-ops'}>
                                        <div><b>Quality Checked</b></div>
                                        <div style={{ fontSize: '12px' }}>{allDetail.applicationId} ready for e-Sign , e-NACH , & e-Mandate Process</div>
                                        <div style={{ fontSize: '12px', color: this.state.flowData !== '' && this.state.flowData.qualityCheckDone ? '#00C851' : 'rgba(0, 0, 0, 0.25)' }}>e-Sign , e-NACH , & Reference Link</div>


                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-2">
                                    <div className='text-center'>
                                        {
                                            this.state.flowData !== '' && this.state.flowData.enachStatus !== undefined && this.state.flowData.enachStatus && this.state.flowData.esignStatus ?
                                                <img src={LogoComplete} width={'30px'} /> :
                                                <img src={BlueCircle} width={'30px'} />}
                                    </div>
                                    {
                                        this.state.flowData !== '' && this.state.flowData.enachStatus !== undefined && this.state.flowData.enachStatus && this.state.flowData.esignStatus ?
                                            <div className='stepperlineinActive-ops-active'></div>
                                            : <div className='stepperlineinActive-ops'></div>}


                                </div>
                                <div className="col-sm-8 col-xs-12" onClick={this.state.flowData !== '' && this.state.flowData.qualityCheckDone ? () => this.setState({ viewTab: 'enach' }) : ""} style={{ cursor: 'pointer' }}>
                                    <div className={this.state.viewTab === 'enach' ? 'active-ops-active' : 'active-ops'}>
                                        <div><b>e-Sign / e-NACH Done</b></div>
                                        <div style={{ fontSize: '12px', color: this.state.flowData !== '' && this.state.flowData.enachStatus && this.state.flowData.esignStatus ? '#00C851' : 'rgba(0, 0, 0, 0.25)' }}> Move to Reference Verification</div>

                                        <br />

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-2">
                                    <div className='text-center'>
                                        {
                                            this.state.flowData !== '' && this.state.flowData.rfdExpiredStatus !== undefined && this.state.flowData.rfdExpiredStatus ?
                                                <img src={LogoComplete} width={'30px'} /> :
                                                <img src={BlueCircle} width={'30px'} />}
                                    </div>
                                    {this.state.flowData !== '' && this.state.flowData.rfdExpiredStatus !== undefined && this.state.flowData.rfdExpiredStatus ?
                                        <div className='stepperlineinActive-ops-active'></div>
                                        : <div className='stepperlineinActive-ops'></div>}

                                </div>
                                <div className="col-sm-7 col-xs-12">
                                    <div className={'active-ops'}>

                                        <div><b>RFD / Expire</b></div>
                                        <div style={{ fontSize: '12px', color: this.state.flowData !== '' && this.state.flowData.rfdExpiredStatus ? '#00C851' : 'rgba(0, 0, 0, 0.25)' }}>Ready For Disbursement</div>


                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-2">
                                    <div className='text-center'>
                                        {
                                            this.state.flowData !== '' && this.state.flowData.disburseOrAboveStatusFlag !== undefined && this.state.flowData.disburseOrAboveStatusFlag ?
                                                <img src={LogoComplete} width={'30px'} /> :
                                                <img src={BlueCircle} width={'30px'} />}
                                    </div>

                                </div>
                                <div className="col-sm-8 col-xs-12">
                                    <div className={'active-ops'}>

                                        <div><b>Disbursed</b></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-8 col-xs-12 details-new'>
                            <div className="details-body" style={{ border: 'none', boxShadow: '0px 2px 6px 4px rgba(50, 71, 92, 0.02), 0px 2px 9px 0px rgba(50, 71, 92, 0.04), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)' }}>
                                <div className="details-head text-right">
                                    <button className="btn btn-primary" style={{ marginBottom: '0',color:'#fff' }} onClick={() => this.setState({ markButton: true })}>Mark Operation Status</button>
                                </div>
                                {this.state.markButton ?
                                    <div className="small-popup-rfd">
                                        <h6>Mark Operation Status</h6>
                                        {admin.rolelist.indexOf(ROLE.Mark_RFD) >= 0 ?
                                            <button className="btn -btn-default" onClick={() => this.RfdFunction('202')} style={{ background: '#DBE9FF' }}>Mark Rfd</button>
                                            : ""}
                                        <br />
                                        {admin.rolelist.indexOf(ROLE.Mark_PFD) >= 0 ?
                                            <button className="btn -btn-default" onClick={() => this.RfdFunction('201')} style={{ background: '#DBE9FF', marginTop: '10px' }}>Mark Pfd</button>
                                            : ""}
                                    </div>
                                    : ""}
                                {this.state.viewTab === 'softApproval' ?
                                    <div style={{ padding: '15px', height: '600px', overflow: 'auto' }}>
                                        <div className="row">
                                            <div className="col-sm-8 col-xs-8">
                                                <div className="col-sm-1 col-xs-1">
                                                    {this.state.flowData !== '' && this.state.flowData.qualityCheckDone ?
                                                        <i className="fa fa-check-circle-o" style={{ fontSize: '22px', marginTop: '5px' }}></i>
                                                        :
                                                        <i className="fa fa-times-circle-o" style={{ fontSize: '22px', marginTop: '5px' }}></i>}
                                                </div>
                                                <div className="col-sm-7 col-xs-7" style={{ marginTop: '5px', fontSize: '16px' }}>Quality Check Report</div>


                                            </div>
                                            <div className="col-sm-4 col-xs-2 text-right">
                                                {this.state.showButton ?
                                                    <button onClick={() => this.qualityCheck()} className="btn btn-default" style={{ background: '#fff', color: '#267DFF', borderRadius: '8px', border: '1px solid #267DFF' }}><b>Quality Check Done</b></button>
                                                    : ""}
                                                    {this.state.errorMessage!==''?<p className="text-end" style={{color:'red'}}>{this.state.errorMessage}</p>:""}
                                            </div>
                                        </div>
                                        <div className="operation-inner-box">
                                            <div className="operation-inner-box-head">
                                                <div className="row">
                                                    <div className="col-sm-8 col-xs-8">
                                                        <img src={IdentityLogo} width={'27px'} />&nbsp;&nbsp;<b>PAN Card</b>
                                                    </div>
                                                    <div className="col-sm-4 col-xs-4 text-right" style={{ color: '#267DFF' }}>
                                                        <span style={{ cursor: 'pointer' }} onClick={(e) => this.karzaPopup(e, 'pan')}>  <i className="fa fa-eye"></i> &nbsp;View</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <table className="opertion-table">
                                                <tr>
                                                    <td style={{ borderTop: 'none', borderLeft: 'none' }}>KARZA Verified</td>
                                                    <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none' }}><img src={this.state.operationsData !== '' && this.state.operationsData.panMatched ? GreenTick : UnverifiedLogo} width='27px' /></td>
                                                </tr>
                                            </table>
                                            <div className="operation-inner-box-head" style={{ borderRadius: 0 }}>
                                                <div className="row">
                                                    <div className="col-sm-8 col-xs-8">
                                                        <img src={IdentityLogo} width={'27px'} />&nbsp;&nbsp;<b>Aadhaar Card</b>
                                                    </div>
                                                    <div className="col-sm-4 col-xs-4 text-right" style={{ color: '#267DFF' }} >
                                                        <span style={{ cursor: 'pointer' }} onClick={(e) => this.karzaPopup(e, 'aadhar')}><i className="fa fa-eye"></i> &nbsp;View</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <table className="opertion-table" >
                                                <tr>
                                                    <td style={{ borderTop: 'none', borderLeft: 'none' }}>KARZA Automatic / Manual</td>
                                                    <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none' }}>{this.state.operationsData !== '' && this.state.operationsData.karzaAutoManual ? this.state.operationsData.karzaAutoManual : ''}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ borderTop: 'none', borderLeft: 'none' }}>KARZA Verified</td>
                                                    <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none' }}><img src={this.state.operationsData !== '' && this.state.operationsData.aadhaarMatch ? GreenTick : UnverifiedLogo} width='27px' /></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ borderTop: 'none', borderLeft: 'none' }}>Name Match</td>
                                                    <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', color: this.state.operationsData !== '' && this.state.operationsData.aadhaarPercentMatch && this.state.operationsData.aadhaarPercentMatch >= 60 ? '#4CC78C' : '#FF5C5D' }}>{this.state.operationsData !== '' && this.state.operationsData.aadhaarPercentMatch ? this.state.operationsData.aadhaarPercentMatch : '0'}% Match</td>
                                                </tr>
                                            </table>
                                            <div className="operation-inner-box-head" style={{ borderRadius: 0 }}>
                                                <img src={BankLogo} width={'27px'} />&nbsp;&nbsp;<b>Account Information</b>
                                            </div>
                                            <table className="opertion-table" >
                                                <tr>
                                                    <td style={{ borderTop: 'none', borderLeft: 'none' }}>Account Number</td>
                                                    <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none' }}><img src={this.state.operationsData !== '' && this.state.operationsData.accountNoMatch ? GreenTick : UnverifiedLogo} width='27px' /></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ borderTop: 'none', borderLeft: 'none' }}>IFSC Code</td>
                                                    <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none' }}><img src={this.state.operationsData !== '' && this.state.operationsData.ifscCodeMatch ? GreenTick : UnverifiedLogo} width='27px' /></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ borderTop: 'none', borderLeft: 'none' }}>Name Match</td>
                                                    <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', color: this.state.operationsData !== '' && this.state.operationsData.namePercentageMatch && this.state.operationsData.namePercentageMatch >= 60 ? '#4CC78C' : '#FF5C5D' }}>{this.state.operationsData !== '' && this.state.operationsData.namePercentageMatch ? this.state.operationsData.namePercentageMatch : '0'}% Match</td>
                                                </tr>
                                            </table>

                                        </div>
                                    </div>
                                    : ""}
                                {this.state.viewTab === 'quality' ?
                                    <div className="ops-com-box" style={{ padding: '15px', height: '600px', overflow: 'auto' }}>
                                        <div style={{ padding: '15px' }}>
                                            <div className="row">
                                                <div className="col-sm-8 col-xs-8">
                                                    <div className="col-sm-1 col-xs-1">
                                                        {this.state.flowData !== '' && this.state.flowData.qualityCheckDone ?
                                                            <i className="fa fa-check-circle-o" style={{ fontSize: '22px', marginTop: '5px' }}></i>
                                                            :
                                                            <i className="fa fa-times-circle-o" style={{ fontSize: '22px', marginTop: '5px' }}></i>}
                                                    </div>
                                                    <div className="col-sm-7 col-xs-7" style={{ marginTop: '5px', fontSize: '16px' }}>Quality Checked</div>


                                                </div>
                                                <div className="col-sm-4 col-xs-2 text-right">
                                                    {/* {userDetail.applicationStatus === 'Approved' ? */}
                                                    <button onClick={() => this.triggerKycButton()} className="btn btn-default" style={{ background: '#fff', color: '#267DFF', borderRadius: '12px', border: '1px solid #267DFF' }}><b>Notify for e-Sign/e-NACH</b></button>
                                                    {/* : ""} */}
                                                </div>
                                            </div>
                                        </div>
                                        <CommTab admin={admin} userDetail={userDetail} allDetail={allDetail} />

                                    </div>
                                    : ""}
                                {this.state.viewTab === 'enach' ?
                                    <div style={{ padding: '15px', height: '600px', overflow: 'auto' }}>
                                        <div className="text-right">
                                            {admin != null && admin.emailId !== 'SuperUser' && admin.rolelist.indexOf(ROLE.EDIT_REFERENCE) >= 0 ?
                                                <button onClick={() => this.setState({ referencePopup: true })} className="btn btn-default" style={{ background: '#fff', color: '#267DFF', borderRadius: '8px', border: '1px solid #267DFF', marginBottom: '10px' }}><b>Edit Reference Details</b></button>
                                                : admin != null && admin.emailId === 'SuperUser' ?
                                                    <button onClick={() => this.setState({ referencePopup: true })} className="btn btn-default" style={{ background: '#fff', color: '#267DFF', borderRadius: '8px', border: '1px solid #267DFF', marginBottom: '10px' }}><b>Edit Reference Details</b></button>
                                                    : ""}
                                        </div>
                                        <div className="phocket-table-new">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Name </th>
                                                        <th>Mobile Number </th>
                                                        <th style={{ textAlign: 'center' }}>Verification </th>
                                                        <th>Relation </th>
                                                        {/* {admin != null && admin.emailId !== 'SuperUser' && admin.rolelist.indexOf(ROLE.EDIT_REFERENCE) >= 0 ?

                                                            <th><img src={EditLogo} width={'27px'} /> </th>
                                                            : admin != null && admin.emailId === 'SuperUser' ?

                                                                <th><img src={EditLogo} width={'27px'} /> </th> 
                                                                : ""} */}


                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {this.state.flowData !== '' && this.state.flowData.userReferenceList !== null ? this.state.flowData.userReferenceList.map((data, i) => {
                                                        return (
                                                            <tr>
                                                                <td style={{ paddingLeft: '21px' }}>{data.name}</td>
                                                                <td style={{ paddingLeft: '21px' }}>{data.number}</td>
                                                                <td>
                                                                    {admin.rolelist.indexOf(ROLE.Mark_RFD) >= 0 ?
                                                                        data.verified ?
                                                                            <div style={{ background: '#D7FBEA', color: '#00C851', padding: '10px', textAlign: 'center', borderRadius: '6px' }}>Verified</div>
                                                                            : <div onClick={() => this.changeVeriFyStatus(data, i)} style={{ background: '#FEECEB', color: '#FF4444', padding: '10px', textAlign: 'center', borderRadius: '6px', cursor: 'pointer' }}>Not Verified</div>
                                                                        : ""}

                                                                </td>
                                                                {/* <td >{data.verified ? <div style={{ background: '#D7FBEA', color: '#00C851', padding: '10px', textAlign: 'center', borderRadius: '6px' }}>Verified</div> : <div style={{ background: '#FEECEB', color: '#FF4444', padding: '10px', textAlign: 'center', borderRadius: '6px' }}>Not Verified</div>}</td> */}
                                                                <td style={{ paddingLeft: '21px' }}>{data.relationShip}</td>
                                                                {/* {admin != null && admin.emailId !== 'SuperUser' && admin.rolelist.indexOf(ROLE.EDIT_REFERENCE) >= 0 ?

                                                                    <td><img src={EditLogo} onClick={() => this.setState({ referencePopup: true })} width={'27px'} style={{ marginLeft: '10px' }} /></td>
                                                                    : admin != null && admin.emailId === 'SuperUser' ?
                                                                        <td><img src={EditLogo} onClick={() => this.setState({ referencePopup: true })} width={'27px'} style={{ marginLeft: '10px' }} /></td>
                                                                        : ""} */}
                                                            </tr>
                                                        )
                                                    }) : 'No Data'}


                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    : ""}

                            </div>

                        </div>
                        {/* <div className='col-sm-1 col-xs-12'>
                        </div> */}

                    </div>
                </div >
            </>

        )
    }
}
export default Operations