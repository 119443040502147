import { APIS } from "../../../../utils/api-factory";
import axios from "axios";

export const getMonthWiseOverdueStatsAPI = (sDate,eDate,callBack,error) =>{
    axios.get(APIS.GET_MONTH_WISE_OVERDUE_STATS + sDate + "&eDate=" + eDate )
    .then(res => {
        callBack(res.data);
    })
    .catch(e => {
        console.log(e);
        callBack(e.response?.data);
    });
}

export const getMonthWiseOverdueStatsDetailedAPI = (sDate,eDate,month,callBack,error) =>{
    axios.get(APIS.GET_MONTH_WISE_OVERDUE_STATS_DETAILED + sDate + "&eDate=" + eDate + "&monthName=" +month )
    .then(res => {
        callBack(res.data);
    })
    .catch(e => {
        console.log(e);
        callBack(e.response?.data);
    });
}

export const getDueOverDueCountsAPI = (sDate,eDate,nbfcId,productId,callBack,error) =>{
    axios.get(APIS.GET_DUE_OVERDUE_COUNTS + sDate + "&eDate=" + eDate +"&nbfcId=" + nbfcId + "&productId=" + productId)
    .then(res => {
        callBack(res.data);
    })
    .catch(e => {
        console.log(e);
        error(e.response?.data);
    });
}

export const getInvoicedPaidAmountMonthlyAPI = (sDate,eDate,nbfcId,productId,callBack,error) =>{
    axios.get(APIS.GET_INVOICED_PAID_AMOUNT_MONTHLY + sDate + "&eDate=" + eDate +"&nbfcId=" + nbfcId + "&productId=" + productId)
    .then(res => {
        callBack(res.data);
    })
    .catch(e => {
        console.log(e);
        callBack(e.response?.data);
    });
}

export const getOverDueAgeSummaryAPI = (sDate,eDate,nbfcId,productId,callBack,error) =>{
    axios.get(APIS.GET_OVERDUE_AGE_SUMMARY + sDate + "&eDate=" + eDate +"&nbfcId=" + nbfcId + "&productId=" + productId)
    .then(res => {
        callBack(res.data);
    })
    .catch(e => {
        console.log(e);
        error(e.response?.data);
    });
}