import { APIS } from '../../../utils/api-factory'
import LocalStrorageUtility from '../../../utils/localStorageUtil'
import moment from 'moment'
import { POST_HEADER } from '../../../utils/apiHeaders'
import { _formatDateInDash } from '../../../utils/validation'
export const getNbfcList = callBack => {
  fetch(APIS.NBFC_LIST)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}

export const aadharExistCheck = (userId, aadaharNumber, callBack) => {
  fetch(APIS.AADHAR_EXIST_CHECK + userId + '&aadharNumber=' + aadaharNumber)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}

export const onChangeSideNav = sideNavSelected => {
  return { type: 'CHANGE_SETTINGS_SIDENAV', sideNavSelected }
}

export const onContentChange = selectedNavIndex => {
  return { type: 'CHANGE_CONTENT_VIEW', selectedNavIndex }
}

export const resetSettingState = () => {
  return { type: 'RESET_SETTINGS' }
}

export const getUserDetailApiAdmin = (userId, callBack) => {
  fetch(APIS.USER_DETAIL_BY_ID + userId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}

export const getIvrFilter = (date, status, callBack) => {
  fetch(APIS.GET_IVR_FILTER_API + date + '&status=' + status)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}

export const getUserDetailByLoanId = (loanId, callBack) => {
  fetch(APIS.USER_DETAIL_BY_LOANID + loanId, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-api-key': 'AIzaSyB-9HOhts6vij-Lx0dNmB4KoJOfPSUN9oA'
    }
  })
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}
export const getLoanDetailByUserId = (userId, callBack) => {
  fetch(APIS.GET_LOAN_DETAIL_BY_USERID + userId + '&type=' + 'All')
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}

export const getAllDetail = (no, callBack) => {
  return dispatch => {
    fetch(APIS.GET_ALL_DETAIL + no)
      .then(res => res.json())
      .then(json => {
        if (json.responseCode == 0) {
          callBack('error')
        } else {
          callBack(json)
          dispatch({ type: 'GET_ALL_USER_DETAILS', allUserDetails: json })
        }
      })
  }
}

export const getAllDetailByMail = (email, no, callBack) => {
  return dispatch => {
    fetch(APIS.GET_ALL_DETAIL_BY_EMAIL + email + '&pageNumber=' + no)
      .then(res => res.json())
      .then(json => {
        if (json.responseCode == 0) {
        } else {
          callBack(json)
          dispatch({ type: 'GET_ALL_USER_DETAILS', allUserDetails: json })
        }
      })
  }
}

export const getAllDetailByMobile = (mobile, no, callBack) => {
  return dispatch => {
    fetch(APIS.GET_ALL_DETAIL_BY_MOBILE + mobile + '&pageNumber=' + no)
      .then(res => res.json())
      .then(json => {
        if (json.responseCode == 0) {
        } else {
          callBack(json)
          dispatch({ type: 'GET_ALL_USER_DETAILS', allUserDetails: json })
        }
      })
  }
}

export const getAllStatus = callBack => {
  return dispatch => {
    fetch(APIS.GET_ALL_STATUS)
      .then(res => res.json())
      .then(json => {
        if (json.responseCode == 0) {
        } else {
          callBack(json)
          dispatch({ type: 'GET_ALL_STATUS', status: json })
        }
      })
  }
}

export const getAllStatusByDate = date => {
  return dispatch => {
    fetch(APIS.FORM_STATUS_COUNT_BY_GIVEN_DATE + date)
      .then(res => res.json())
      .then(json => {
        if (json.responseCode == 0) {
        } else {
          dispatch({ type: 'GET_ALL_STATUS', status: json })
        }
      })
  }
}

export const getAllStatusByMonth = (year, month) => {
  return dispatch => {
    fetch(APIS.FORM_STATUS_COUNT_BY_GIVEN_MONTH + year + '&month=' + month)
      .then(res => res.json())
      .then(json => {
        if (json.responseCode == 0) {
        } else {
          dispatch({ type: 'GET_ALL_STATUS', status: json })
        }
      })
  }
}

export const getAllStatusByBetweenDate = (startDate, endDate, callBack) => {
  return dispatch => {
    fetch(
      APIS.FORM_STATUS_COUNT_BETWEEN_TWO_DATES +
      startDate +
      '&enddate=' +
      endDate
    )
      .then(res => res.json())
      .then(json => {
        if (json.responseCode == 0) {
        } else {
          callBack(json)
          dispatch({ type: 'GET_ALL_STATUS', status: json })
        }
      })
  }
}

export const getAllStatusBulletEmi = (startDate, endDate, callBack) => {
  fetch(APIS.GET_ALL_STATUS_BULLET_EMI + startDate + '&enddate=' + endDate)
    .then(res => res.json())
    .then(json => {
      if (json.responseCode == 0) {
      } else {
        callBack(json)
      }
    })
}

export const onChangeUserNav = userNavSelected => {
  return { type: 'CHANGE_USERNAV', userNavSelected }
}

export const onUserContentChange = selectedNavIndexUser => {
  return { type: 'CHANGE_CONTENT_VIEW_USER', selectedNavIndexUser }
}

export const resetUserState = () => {
  return { type: 'RESET_USERS' }
}

export const getAllNotification = callBack => {
  return dispatch => {
    fetch(APIS.GET_CRM_NOTIFICATION)
      .then(res => res.json())
      .then(json => {
        if (json.responseCode == 0) {
        } else {
          callBack(json)
          dispatch({ type: 'GET_CRM_NOTIFICATION', crmNotification: json })
        }
      })
  }
}

export const getLoanApprovalLead = (
  status,
  count,
  empId,
  sDate,
  nbfcName,
  eDate,
  tabType,
  loanFrom,
  subStatus,
  teaGardenId,
  teaGardenEmpId,
  dateType,
  callBack
) => {
  return dispatch => {

    fetch(
      APIS.GET_LOAN_APPROVAL_LEAD +
      status +
      '&pageNumber=' +
      count +
      '&empId=' +
      empId +
      '&date=' +
      sDate +
      '&nbfcName=' +
      nbfcName +
      '&eDate=' +
      eDate +
      '&tabType=' +
      tabType +
      '&loanFrom='
      + loanFrom +
      '&subStatus='
      + subStatus
      + '&teaGardenId='
      + teaGardenId
      + '&teaGardenEmpId='
      + teaGardenEmpId
      + '&dateType=' + dateType)
      .then(res => res.json())
      .then(json => {
        if (json.responseCode == 0) {
        } else {
          dispatch({ type: 'GET_LOAN_APPROVAL_LEAD', allPendingPayment: json })
          callBack(json)
        }
      })
  }
}
export const getgiftCardLead = (
  status,
  count,
  empId,
  sDate,
  nbfcName,
  eDate,
  tabType,
  callBack
) => {
  return dispatch => {

    fetch(
      APIS.GET_GIFT_CARD_LEADS +
      status +
      '&pageNumber=' +
      count +
      '&empId=' +
      empId +
      '&date=' +
      sDate +
      '&nbfcName=' +
      nbfcName +
      '&eDate=' +
      eDate +
      '&tabType=' +
      tabType
    )
      .then(res => res.json())
      .then(json => {
        if (json.responseCode == 0) {
        } else {
          dispatch({ type: 'GET_LOAN_APPROVAL_LEAD', allPendingPayment: json })
          dispatch({
            type: 'GET_LOAN_APPROVAL_LEAD_GIFT_CARD',
            allPendingPayment: json
          })
          callBack(json)
        }
      })
  }
}
export const getLoanApprovalLeadTesting = (
  status,
  count,
  empId,
  date,
  nbfcName,
  callBack
) => {
  return dispatch => {
    fetch(
      APIS.GET_LOAN_APPROVAL_LEAD_TESTING +
      status +
      '&pageNumber=' +
      count +
      '&empId=' +
      empId +
      '&date=' +
      date +
      '&nbfcName=' +
      nbfcName,
      {
        // headers: {
        //     'Accept': 'application/json',
        //     'Content-Type': 'application/json',
        //     'Authorization': 'bearer ' + LocalStrorageUtility.getFromLocalStorage('access_token')
        // }
      }
    )
      .then(res => res.json())
      .then(json => {
        if (json.responseCode == 0) {
        } else {
          dispatch({ type: 'GET_LOAN_APPROVAL_LEAD', allPendingPayment: json })
          callBack(json)
        }
      })
  }
}

export const getLoanApprovalLeadPriority = (
  status,
  count,
  empId,
  date,
  type,
  callBack
) => {
  return dispatch => {
    fetch(
      APIS.GET_LOAN_APPROVAL_LEAD +
      status +
      '&pageNumber=' +
      count +
      '&empId=' +
      empId +
      '&date=' +
      date +
      '&type=' +
      type +
      '&eDate=' +
      '&tabType=',
      {
        // headers: {
        //     'Accept': 'application/json',
        //     'Content-Type': 'application/json',
        //     'Authorization': 'bearer ' + LocalStrorageUtility.getFromLocalStorage('access_token')
        // }
      }
    )
      .then(res => res.json())
      .then(json => {
        if (json.responseCode == 0) {
        } else {
          dispatch({
            type: 'GET_LOAN_APPROVAL_LEAD_BY_PRIORITY',
            loanApprovalLeadByPriority: json
          })
          callBack(json)
        }
      })
  }
}

export const getDisburseLead = (status, count, empId, date, callBack) => {
  return dispatch => {
    fetch(
      APIS.GET_LOAN_APPROVAL_LEAD +
      status +
      '&pageNumber=' +
      count +
      '&empId=' +
      empId +
      '&date=' +
      date +
      '&eDate=' +
      '&tabType=',
      {
        // headers: {
        //     'Accept': 'application/json',
        //     'Content-Type': 'application/json',
        //     'Authorization': 'bearer ' + LocalStrorageUtility.getFromLocalStorage('access_token')
        // }
      }
    )
      .then(res => res.json())
      .then(json => {
        if (json.responseCode == 0) {
        } else {
          dispatch({ type: 'GET_DISBURSE_LEAD', disburseLeads: json })
          callBack(json)
        }
      })
  }
}

export const getImportantLead = (status, count, empId, date, callBack) => {

  return dispatch => {
    fetch(
      APIS.GET_LOAN_APPROVAL_LEAD +
      status +
      '&pageNumber=' +
      count +
      '&empId=' +
      empId +
      '&date=' +
      date +
      '&eDate=' +
      '&tabType=',
      {
        // headers: {
        //     'Accept': 'application/json',
        //     'Content-Type': 'application/json',
        //     'Authorization': 'bearer ' + LocalStrorageUtility.getFromLocalStorage('access_token')
        // }
      }
    )
      .then(res => res.json())
      .then(json => {
        if (json.responseCode == 0) {
        } else {
          dispatch({ type: 'GET_IMPORTANT_LEAD', importantLeads: json })
          callBack(json)
        }
      })
  }
}

export const getistByNumber = (status, count, leadType, admin, callBack) => {
  return dispatch => {
    fetch(
      APIS.LOAN_FILTER_BY_NUMBER +
      status +
      '&pageNumber=' +
      count +
      '&type=' +
      leadType +
      '&empId=' +
      admin.emailId
    )
      .then(res => res.json())
      .then(json => {
        if (json.responseCode == 0) {
        } else {
          dispatch({
            type: 'GET_LOAN_APPROVAL_LEAD_GIFT_CARD',
            allPendingPayment: json
          })
          dispatch({
            type: 'GET_ALL_INCOMING_PAYMENT',
            allIncomingPayment: json
          })
          dispatch({ type: 'GET_ALL_DUE_PAYMENT', allDuePayment: json })
          dispatch({ type: 'GET_ALL_DUE_PAYMENT_EMI', allDuePaymentEmi: json })
          dispatch({ type: 'GET_LOAN_APPROVAL_LEAD', allPendingPayment: json })
          dispatch({ type: 'GET_LOAN_APPROVAL_LEAD_OPTIMIZED', allPendingPayment: json })

          dispatch({ type: 'GET_LOAN_ADDAL_LEAD', loanAddaLeads: json })
          dispatch({
            type: 'GET_LOAN_APPROVAL_LEAD_BY_PRIORITY',
            loanApprovalLeadByPriority: json
          })
          dispatch({ type: 'GET_DISBURSE_LEAD', disburseLeads: json })
          dispatch({ type: 'GET_IMPORTANT_LEAD', importantLeads: json })
          dispatch({
            type: 'GET_ALL_LATEST_DOCUMENT_UPLOAD',
            latestDocUpload: json
          })
          dispatch({ type: 'GET_LEAD_MANAGEMENT', LeadManagementData: json })
          dispatch({ type: 'GET_LEAD_MANAGEMENT_OPTIMIZED', LeadManagementData: json })

          callBack(json)
        }
      })
  }
}

export const getlistByEmail = (status, count, leadType, admin, callBack) => {
  return dispatch => {
    fetch(
      APIS.LOAN_FILTER_BY_EMAILID +
      status +
      '&pageNumber=' +
      count +
      '&type=' +
      leadType +
      '&empId=' +
      admin.emailId
    )
      .then(res => res.json())
      .then(json => {
        if (json.responseCode == 0) {
        } else {
          dispatch({
            type: 'GET_LOAN_APPROVAL_LEAD_GIFT_CARD',
            allPendingPayment: json
          })
          dispatch({
            type: 'GET_ALL_INCOMING_PAYMENT',
            allIncomingPayment: json
          })
          dispatch({ type: 'GET_ALL_DUE_PAYMENT', allDuePayment: json })
          dispatch({ type: 'GET_ALL_DUE_PAYMENT_EMI', allDuePaymentEmi: json })
          dispatch({ type: 'GET_LOAN_APPROVAL_LEAD', allPendingPayment: json })
          dispatch({ type: 'GET_LOAN_APPROVAL_LEAD_OPTIMIZED', allPendingPayment: json })

          dispatch({ type: 'GET_LOAN_ADDAL_LEAD', loanAddaLeads: json })
          dispatch({
            type: 'GET_LOAN_APPROVAL_LEAD_BY_PRIORITY',
            loanApprovalLeadByPriority: json
          })
          dispatch({ type: 'GET_DISBURSE_LEAD', disburseLeads: json })
          dispatch({ type: 'GET_IMPORTANT_LEAD', importantLeads: json })
          dispatch({
            type: 'GET_ALL_LATEST_DOCUMENT_UPLOAD',
            latestDocUpload: json
          })
          dispatch({ type: 'GET_LEAD_MANAGEMENT', LeadManagementData: json })
          dispatch({ type: 'GET_LEAD_MANAGEMENT_OPTIMIZED', LeadManagementData: json })

          callBack(json)
        }
      })
  }
}

// export const getlistByApplication = (
//   status,
//   count,
//   leadType,
//   admin,

//   isSearchAllow,
//   callBack
// ) => (dispatch) => {
//   return new Promise(function(resolve,reject){
//     axios.get(
//       APIS.LOAN_FILTER_BY_APPLICATION +
//       status +
//       '&pageNumber=' +
//       count +
//       '&type=' +
//       leadType +
//       '&empId=' +
//       admin.emailId +
//       '&isSearchAllow=' + isSearchAllow
//     )
//       .then(res => {
//         console.log(res)
//         res.data.json()})
//       .then(json => {
//         console.log(json,'listbyapplicationid')
//         if (json.responseCode == 0) {
//           callBack(reject(null))
//         } else {
//           dispatch({
//             type: 'GET_ALL_INCOMING_PAYMENT',
//             allIncomingPayment: json
//           })
//           dispatch({
//             type: 'GET_LOAN_APPROVAL_LEAD_GIFT_CARD',
//             allPendingPayment: json
//           })
//           dispatch({ type: 'GET_ALL_DUE_PAYMENT', allDuePayment: json })
//           dispatch({ type: 'GET_ALL_DUE_PAYMENT_EMI', allDuePaymentEmi: json })
//           dispatch({ type: 'GET_LOAN_APPROVAL_LEAD', allPendingPayment: json })
//           dispatch({ type: 'GET_LOAN_ADDAL_LEAD', loanAddaLeads: json })
//           dispatch({
//             type: 'GET_LOAN_APPROVAL_LEAD_BY_PRIORITY',
//             loanApprovalLeadByPriority: json
//           })
//           dispatch({ type: 'GET_DISBURSE_LEAD', disburseLeads: json })
//           dispatch({ type: 'GET_IMPORTANT_LEAD', importantLeads: json })
//           dispatch({
//             type: 'GET_ALL_LATEST_DOCUMENT_UPLOAD',
//             latestDocUpload: json
//           })
//           dispatch({ type: 'GET_LEAD_MANAGEMENT', LeadManagementData: json })
//           callBack(resolve(json))
//         }
//       })
//   })
//   // return dispatch => {
//     // console.log(   APIS.LOAN_FILTER_BY_APPLICATION +
//     //   status +
//     //   '&pageNumber=' +
//     //   count +
//     //   '&type=' +
//     //   leadType +
//     //   '&empId=' +
//     //   admin.emailId)

//   // }
// }

// let apicall= 0

export const getlistByApplication = (
  status,
  count,
  leadType,
  admin,

  isSearchAllow,
  callBack
) => {
  return dispatch => {

    fetch(
      APIS.LOAN_FILTER_BY_APPLICATION +
      status +
      '&pageNumber=' +
      count +
      '&type=' +
      leadType +
      '&empId=' +
      admin.emailId +
      '&isSearchAllow=' + isSearchAllow
    )

      .then(res => res.json())
      .then(json => {
        if (json.responseCode == 0) {
        } else {
          // if(apicall>0){
          dispatch({
            type: 'GET_ALL_INCOMING_PAYMENT',
            allIncomingPayment: json
          })
          dispatch({
            type: 'GET_LOAN_APPROVAL_LEAD_GIFT_CARD',
            allPendingPayment: json
          })
          dispatch({ type: 'GET_ALL_DUE_PAYMENT', allDuePayment: json })
          dispatch({ type: 'GET_ALL_DUE_PAYMENT_EMI', allDuePaymentEmi: json })
          dispatch({ type: 'GET_LOAN_APPROVAL_LEAD', allPendingPayment: json })
          dispatch({ type: 'GET_LOAN_APPROVAL_LEAD_OPTIMIZED', allPendingPayment: json })

          dispatch({ type: 'GET_LOAN_ADDAL_LEAD', loanAddaLeads: json })
          dispatch({
            type: 'GET_LOAN_APPROVAL_LEAD_BY_PRIORITY',
            loanApprovalLeadByPriority: json
          })
          dispatch({ type: 'GET_DISBURSE_LEAD', disburseLeads: json })
          dispatch({ type: 'GET_IMPORTANT_LEAD', importantLeads: json })
          dispatch({
            type: 'GET_ALL_LATEST_DOCUMENT_UPLOAD',
            latestDocUpload: json
          })
          dispatch({ type: 'GET_LEAD_MANAGEMENT', LeadManagementData: json })
          dispatch({ type: 'GET_LEAD_MANAGEMENT_OPTIMIZED', LeadManagementData: json })


          callBack(json)
        }
      })

  }
}

export const addLoanApprovalLead = crmNotification => {
  return { type: 'ADD_LOAN_APPROVAL_LEAD', allPendingPayment: crmNotification }
}

export const saveOrUpdateBuisnessDetaiom = (
  vreedhiData,
  vreedhiDataState,
  admin,
  callBack
) => {
  var data = {
    empId: admin.emailId,
    name: vreedhiDataState.name,
    address: vreedhiDataState.address,
    category: vreedhiDataState.category,
    segment: vreedhiDataState.segment,
    monthlyTurnover: vreedhiDataState.monthlyTurnover,
    netBussinessIncome: vreedhiDataState.netBussinessIncome,
    amountRecomended: vreedhiData.amountRecomended,
    clMailId: vreedhiData.clMailId,
    clMobile: vreedhiData.clMobile,
    clName: vreedhiData.clName,
    clRemarks: vreedhiData.clRemarks,
    createdAt: vreedhiData.createdAt,
    dailyTurnover: vreedhiData.dailyTurnover,
    establishmentDate: vreedhiData.establishmentDate,
    familyDependents: vreedhiData.familyDependents,
    familyFinancialCondition: vreedhiData.familyFinancialCondition,
    id: vreedhiData.id,
    model: vreedhiData.model,
    neighbourFeedback: vreedhiData.neighbourFeedback,
    neighbourMobileNo: vreedhiData.neighbourMobileNo,
    neighbourName: vreedhiData.neighbourName,
    ownershipDetail: vreedhiData.ownershipDetail,
    premise: vreedhiData.premise,
    premisesType: vreedhiData.premisesType,
    rcmMailId: vreedhiData.rcmMailId,
    rcmMobile: vreedhiData.rcmMobile,
    rcmName: vreedhiData.rcmName,
    registrationDocument: vreedhiData.registrationDocument,
    remarksDiscussionWithNeighbour: vreedhiData.remarksDiscussionWithNeighbour,
    samePremisesYear: vreedhiData.samePremisesYear,
    stability: vreedhiData.stability,
    tenureRecomended: vreedhiData.tenureRecomended,
    userId: vreedhiData.userId,
    vintageOfBussiness: vreedhiData.vintageOfBussiness,
    voterId: vreedhiData.voterId,
    yearlyTurnOver: vreedhiData.yearlyTurnOver

  }

  fetch(APIS.SAVE_OR_UPDATE_BUISNESS_DETAIL, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}
export const fetchUserBasicDetail = (
  userDetail,
  allDetail,
  admin,
  callBack
) => {
  var data = {
    userId: allDetail.userId,
    loanId: allDetail.loanId,
    firstName: userDetail.firstNameState,
    middleName: userDetail.MiddleNameState,
    lastName: userDetail.lastNameState,
    dateOfBirth: userDetail.BasicDOB,
    emailId: userDetail.EmailIDState,
    gender: userDetail.GenderState,
    formStatus: userDetail.formStatus,
    alternativeNo: userDetail.alternateMobileState,
    empId: admin.emailId,
    mobileNumber: userDetail.MobileNumberState
  }
  fetch(APIS.USER_BASIC_DETAIL, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}

export const fetchCibilDetailnew = (scoreDetail, allDetail, remarksListdata, admin, callBack) => {
  var data = {
    userId: allDetail.userId,
    overDueAccount: scoreDetail.cibilOverdueAccount,
    cibilScore: scoreDetail.cibilScore,
    income: scoreDetail.cibilincome,
    obligations: scoreDetail.cibilObligations,
    surplus: scoreDetail.cibilSurplus,
    remarksList: remarksListdata,
    empId: admin.emailId,
    shortTermCreditCard: scoreDetail.shortTermCreditCard,
    loanId: allDetail.loanId
  }
  console.log(data)
  fetch(APIS.EDIT_CIBIL_SCORE_BY_USERID, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}
export const fetchCibilDetail = (scoreDetail, allDetail, admin, callBack) => {
  var data = {
    userId: allDetail.userId,
    overDueAccount: scoreDetail.cibilOverdueAccount,
    cibilScore: scoreDetail.cibilScore,
    income: scoreDetail.cibilincome,
    obligations: scoreDetail.cibilObligations,
    surplus: scoreDetail.cibilSurplus,
    remarks: scoreDetail.cibilRemarks,
    empId: admin.emailId,
    shortTermCreditCard: scoreDetail.shortTermCreditCard
  }
  console.log(data)
  fetch(APIS.EDIT_CIBIL_SCORE_BY_USERID, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}

export const fetchAddressDetail = (
  userAddressDetail,
  allDetail,
  admin,
  callBack
) => {
  var data = {
    userId: allDetail.userId,
    loanId: allDetail.loanId,
    currentAddress: userAddressDetail.CurrentAddressState,
    currentPincode: userAddressDetail.CurrentPinCodeState,
    currentState: userAddressDetail.CurrentStateState,
    currentCity: userAddressDetail.CurrentCityState,
    currentResidenceType: userAddressDetail.CurrentResidenceTypeValue,
    currentResidenceValue: userAddressDetail.CurrentDurationState,
    empId: admin.emailId,
    permanentAddress: userAddressDetail.PermanentAddressState,
    permanentPincode: userAddressDetail.PermanentPinCodeState,
    permanentState: userAddressDetail.PermanentStateState,
    permanentCity: userAddressDetail.PermanentCityState,
    permanentResidenceType: userAddressDetail.PermanentResidenceTypeValue,
    permanentResidenceValue: userAddressDetail.PermanentDurationState,
    formStatus: userAddressDetail.formStatus
  }
  fetch(APIS.USER_ADDRESS_DETAIL, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}

export const fetchOccupationAndEmployment = (
  employeeDetail,
  allDetail,
  admin,
  callBack
) => {
  var data = {
    userId: allDetail.userId,
    loanId: allDetail.loanId,
    empId: admin.emailId,
    highestQualification: employeeDetail.HighQualificationState,
    fullPartTime: employeeDetail.fullPartTimeState,
    employerType: employeeDetail.EmployeeTypeValue,
    organizationName: employeeDetail.OrganizationNameState,
    netTakeHomeSalary: employeeDetail.HomeSalaryState,
    workExperienceCurrentOrganization:
      employeeDetail.WorkExperienceCurrentState,
    workExperiencePreviousOrganization:
      employeeDetail.WorkExperiencePreviousState,
    isThisFirstJob: employeeDetail.IsFirstJobState,
    selfEmploymentType: employeeDetail.EmployeeTypeState,
    officeAddress: employeeDetail.officeAddressState,
    totalExperience: employeeDetail.WorkExperienceTotalState,
    selfEmployeeType: employeeDetail.EmployeeTypeState,
    monthlyIncome: employeeDetail.MonthlyIncomeState,
    businessStartDate: employeeDetail.businessStartDateState,
    businessIndustryType: employeeDetail.IndustrialTypeState,
    designation: employeeDetail.designationState,
    formStatus: employeeDetail.formStatus,
    optionalEmail: employeeDetail.officeMailState,
    noOfEmployees: employeeDetail.noOfEmployeeState
  }
  fetch(APIS.USER_EMPLOYMENT_DETAIL, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}

export const fetchBankDetail = (bankDetail, userId, adminId, callBack) => {
  var data = {
    userId: userId,
    bankName: bankDetail.BankNameState,
    nameAsBankAccount: bankDetail.BankUserNameState,
    accountNumber: bankDetail.AccountNoState,
    ifscCode: bankDetail.IFSCCodeState,
    bankBranch: bankDetail.BankBranchState,
    ifSalariedAccount: bankDetail.IfSalriedAccountState,
    panNo: bankDetail.PanNoState,
    aadhaarNo: bankDetail.aadharNoState,
    formStatus: bankDetail.formStatus,
    empId: adminId ? adminId : ''
  }

  fetch(APIS.FORM_BANK_DETAIL, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('success')
    })
}

export const fetchLoanDetail = (loanDetail, allDetail, admin, callBack) => {
  var data = {
    userId: allDetail.userId,
    loanId: allDetail.loanId,
    empId: admin.emailId,
    loanAmount: loanDetail.loanAmountState,
    paybackDuration: loanDetail.loanDaysState,
    loanReason: loanDetail.loanReasonState,
    formStatus: loanDetail.formStatus
  }
  fetch(APIS.LOAN_UPDATE, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}

export const uploadImage = (imageFile, type, fileName, userId, callBack) => {
  var self = this
  return new Promise((resolve, reject) => {
    var self = this
    let imageFormData = new FormData()
    for (let i = 0; i < imageFile.length; i++) {
      imageFormData.append('uploadfile', imageFile[i])
    }
    imageFormData.append('userId', userId)
    imageFormData.append('type', 'img')
    imageFormData.append('fileName', type)
    var xhr = new XMLHttpRequest()
    xhr.open('post', APIS.UPLOAD_FILE, true)
    xhr.onload = function () {
      if (this.status == 200) {
        resolve(this.response)
        callBack(this.response)
      } else {
        reject(this.statusText)
      }
    }
    xhr.send(imageFormData)
  })
}

export const uploadPdf = (
  pdfFile,
  type,
  fileName,
  password,
  userId,
  callBack
) => {
  var self = this
  return new Promise((resolve, reject) => {
    var self = this
    let imageFormData = new FormData()
    for (let i = 0; i < pdfFile.length; i++) {
      imageFormData.append('uploadfile', pdfFile[i])
    }
    imageFormData.append('userId', userId)
    imageFormData.append('type', 'pdf')
    imageFormData.append('fileName', type)
    imageFormData.append('password', password)
    var xhr = new XMLHttpRequest()
    xhr.open('post', APIS.UPLOAD_FILE, true)
    xhr.onload = function () {
      if (this.status == 200) {
        resolve(this.response)
        callBack(this.response)
      } else {
        reject(this.statusText)
        callBack(this.response)
      }
    }
    xhr.send(imageFormData)
  })
}

export const uploadAmbImage = (
  imageFile,
  type,
  fileName,
  ambData,
  callBack
) => {
  var self = this
  return new Promise((resolve, reject) => {
    var self = this
    let imageFormData = new FormData()
    for (let i = 0; i < imageFile.length; i++) {
      imageFormData.append('uploadfile', imageFile[i])
    }
    imageFormData.append('ambassadorId', ambData.ambassadorId)
    imageFormData.append('type', 'img')
    imageFormData.append('fileName', type)
    var xhr = new XMLHttpRequest()
    xhr.open('post', APIS.UPLOAD_AMBASSADOR_DOCUMENT, true)
    xhr.onload = function () {
      if (this.status == 200) {
        resolve(this.response)
        callBack(this.response)
      } else {
        reject(this.statusText)
        callBack(this.response)
      }
    }
    xhr.send(imageFormData)
  })
}

export const uploadAmbPdf = (
  pdfFile,
  type,
  fileName,
  password,
  ambData,
  callBack
) => {
  var self = this
  return new Promise((resolve, reject) => {
    var self = this
    let imageFormData = new FormData()
    for (let i = 0; i < pdfFile.length; i++) {
      imageFormData.append('uploadfile', pdfFile[i])
    }
    imageFormData.append('ambassadorId', ambData.ambassadorId)
    imageFormData.append('type', 'pdf')
    imageFormData.append('fileName', type)
    imageFormData.append('password', password)
    var xhr = new XMLHttpRequest()
    xhr.open('post', APIS.UPLOAD_AMBASSADOR_DOCUMENT, true)
    xhr.onload = function () {
      if (this.status == 200) {
        resolve(this.response)
        callBack(this.response)
      } else {
        reject(this.statusText)
        callBack(this.response)
      }
    }
    xhr.send(imageFormData)
  })
}

export const passwordCheck = (pdfFile, callBack) => {
  return new Promise((resolve, reject) => {
    var self = this
    let imageFormData = new FormData()
    for (let i = 0; i < pdfFile.length; i++) {
      imageFormData.append('uploadfile', pdfFile[i])
    }
    var xhr = new XMLHttpRequest()
    xhr.open('post', APIS.DOC_PASSWORD_PROTECT, true)
    xhr.onload = function () {
      if (this.status == 200) {
        resolve(this.response)
        callBack(this.response)
      } else {
        reject(this.statusText)
        callBack(this.response)
      }
    }
    xhr.send(imageFormData)
  })
}

export const fetchRoleDetail = (roleDetail, callBack) => {
  var data = {
    emailId: roleDetail.emailIdState,
    name: roleDetail.nameState,
    rolelist: roleDetail.permissionState,
    password: roleDetail.passwordState,
    role: roleDetail.roleState,
    department: roleDetail.departmentState,
    nbfcName: roleDetail.nbfcState
  }
  fetch(APIS.ROLE_ASSIGN, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}

export const getRoleAssignView = (emailId, callBack) => {
  fetch(APIS.ROLE_ASSIGN_VIEW + emailId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}

export const adminLoginApi = (adminId, Password, captcha, callBack) => {
  var data = {
    emailId: adminId,
    password: Password,
    captcha: captcha
  }
  return dispatch => {
    fetch(APIS.ADMIN_LOGIN, {
      method: 'POST',
      headers: POST_HEADER,
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(json => {
        if (json.responseCode == 0) {
        } else {
          if (json.status == 'failure') {
            LocalStrorageUtility.saveInLocalStorage('status', false)
            callBack(json)
          }
          LocalStrorageUtility.saveInLocalStorage('AdminemailId', json.emailId)
          LocalStrorageUtility.saveInLocalStorage('AdminRole', json.role)
          LocalStrorageUtility.saveInLocalStorage('adminName', json.name)

          LocalStrorageUtility.saveInLocalStorage(
            'AdminRoleList',
            json.rolelist
          )
          //   LocalStrorageUtility.saveInLocalStorage('password', Password)
          LocalStrorageUtility.saveInLocalStorage('nbfcName', json.nbfcName)
          dispatch({ type: 'GET_ADMIN_USER_DETAILS', adminDetails: json })
          callBack(json)
        }
      })
  }
}
export const getAdminUserCheck = (adminId, callBack) => {
  var data = {
    emailId: adminId
  }
  return dispatch => {
    fetch(APIS.GET_ADMIN_USER, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-api-key': 'AIzaSyB-9HOhts6vij-Lx0dNmB4KoJOfPSUN9oA'
      },
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(json => {
        if (json.responseCode == 0) {
        } else {
          if (json.status == 'failure') {
            LocalStrorageUtility.saveInLocalStorage('status', false)
            callBack(json)
          }
          LocalStrorageUtility.saveInLocalStorage('AdminemailId', json.emailId)
          LocalStrorageUtility.saveInLocalStorage('AdminRole', json.role)
          LocalStrorageUtility.saveInLocalStorage(
            'AdminRoleList',
            json.rolelist
          )
          //   LocalStrorageUtility.saveInLocalStorage('password', Password)
          LocalStrorageUtility.saveInLocalStorage('nbfcName', json.nbfcName)
          dispatch({ type: 'GET_ADMIN_USER_DETAILS', adminDetails: json })
          callBack(json)
        }
      })
  }
}

export const updateTransactionDetail = (transDetail, callBack) => {
  var data = {
    transactionId: transDetail.trnsIdState,
    prepayDate: transDetail.trnsDateState,
    paymentMode: transDetail.trnsModeState,
    prepayAmount: transDetail.trnsAmountState,
    userId: transDetail.userId,
    loanId: transDetail.loanId
  }
  fetch(APIS.UPDATE_TRANSACTION, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}

export const fetchTransaction = (
  userId,
  loanId,
  transactionId,
  prepayAmount,
  prepayDate,
  prepayMode,
  admin,
  callBack
) => {
  var data = {
    userId: userId,
    loanId: loanId,
    transactionId: transactionId,
    totalAmount: prepayAmount,
    prepayAmount: '',
    prepayDate: prepayDate,
    paymentMode: prepayMode,
    empId: admin.emailId
  }
  fetch(APIS.SAVE_TRANSACTION, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}

export const sendBankData = (imageUrl, bankName, callBack) => {
  var data = {
    phocket_pdf_store_path: imageUrl,
    phocket_pdf_comp: bankName
  }
  fetch(APIS.SEND_BANK_DATA, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}

export const bulkStatusChange = (status, idList, empId, callBack) => {
  var data = {
    loanIdList: idList,
    status: status,
    empId: empId
  }
  fetch(APIS.BULK_STATUS_CHANGE, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}

export const incompleteCommunication = (
  comData,
  dateData,
  comType,
  admin,
  body,
  userIdList,
  templateIdTosend,
  callBack
) => {
  if (comType == 'msg') {
    var data = {
      typeList: ['message'],
      mailbody: comData.body.replace(/&/g, 'and'),
      subject: comData.title,
      empId: admin.emailId,
      imageUrl: comData.url,
      userIdList: userIdList,
      templateId: templateIdTosend
    }
  } else {
    var data = {
      typeList: comData.type,
      mailbody: body,
      subject: comData.title,
      empId: admin.emailId,
      imageUrl: comData.url,
      userIdList: userIdList
    }
  }
  fetch(APIS.BULK_COMMUNICATION, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}

export const statusWiseCommunication = (
  comData,
  body,
  type,
  imgUrl,
  admin,
  filterForCommunication,
  userIdList,
  templateIdTosend,
  callBack
) => {
  if (type == 'mail') {
    var data = {
      typeList: comData.type,
      mailbody: body,
      subject: comData.title,
      empId: admin.emailId,
      imageUrl: imgUrl,
      userIdList: userIdList,
      templateId: ''
    }
  } else {
    var data = {
      typeList: comData.type,
      mailbody: comData.body.replace(/&/g, 'and'),
      subject: comData.title,
      empId: admin.emailId,
      imageUrl: imgUrl,
      userIdList: userIdList,
      templateId: templateIdTosend
    }
  }
  fetch(APIS.BULK_COMMUNICATION, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}

export const getIncomingPaymentByWeek = (count, nachType, callBack) => {
  return dispatch => {
    fetch(APIS.INCOMING_PAYMENT_BY_WEEK + count + '&nachType=' + nachType)
      .then(res => res.json())
      .then(json => {
        if (json.responseCode == 0) {
        } else {
          dispatch({
            type: 'GET_ALL_INCOMING_PAYMENT',
            allIncomingPayment: json
          })
          callBack(json)
        }
      })
  }
}

export const getBankData = (userId, callBack) => {
  fetch(APIS.GET_BANK_DATA + userId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch(e => callBack(e))
}

export const getAccountApi = (userId, callBack) => {
  fetch(APIS.GET_ACCOUNT_FINBOX + userId)
    .then(res => res.json())
    .then(json => {
      if (json.responseCode == 0) {
      } else {
        callBack(json)
      }
    })
    .catch(e => callBack(e))
}

export const getLatestDocUpload = (count, callBack) => {
  return dispatch => {
    fetch(APIS.LATEST_DOCUMENT_UPLOAD + count)
      .then(res => res.json())
      .then(json => {
        if (json.responseCode == 0) {
        } else {
          dispatch({
            type: 'GET_ALL_LATEST_DOCUMENT_UPLOAD',
            latestDocUpload: json
          })
          callBack(json)
        }
      })
  }
}

export const getEmiIncomingPaymentByWeek = (count, loanFrom, nachType, callBack) => {
  return dispatch => {
    fetch(APIS.EMI_INCOMING_PAYMENT_BY_WEEK + count + '&loanFrom=' + 'phocket' + '&nachType=' + nachType)
      .then(res => res.json())
      .then(json => {
        if (json.responseCode == 0) {
        } else {
          dispatch({
            type: 'GET_ALL_INCOMING_PAYMENT',
            allIncomingPayment: json
          })
          callBack(json)
        }
      })
  }
}

export const getIncomingPaymentByDate = (date, count, nachType, callBack) => {
  return dispatch => {
    fetch(APIS.INCOMING_PAYMENT_BY_DATE + date + '&count=' + count + '&nachType=' + nachType)
      .then(res => res.json())
      .then(json => {
        if (json.responseCode == 0) {
        } else {
          dispatch({
            type: 'GET_ALL_INCOMING_PAYMENT',
            allIncomingPayment: json
          })
          callBack(json)
        }
      })
  }
}

export const getEmiIncomingPaymentByDate = (date, count, loanFrom, nachType, callBack) => {
  return dispatch => {
    fetch(APIS.EMI_INCOMING_PAYMENT_BY_DATE + date + '&count=' + count + '&loanFrom=' + loanFrom + '&nachType=' + nachType)
      .then(res => res.json())
      .then(json => {
        if (json.responseCode == 0) {
        } else {
          dispatch({
            type: 'GET_ALL_INCOMING_PAYMENT',
            allIncomingPayment: json
          })
          callBack(json)
        }
      })
  }
}
// export const getEmiIncomingPaymentByDate = (date, count, loanFrom, nachType, callBack) => {

//     fetch(APIS.EMI_INCOMING_PAYMENT_BY_DATE + date + '&count=' + count + '&loanFrom=' + loanFrom + '&nachType=' + nachType)
//       .then(res => res.json())
//       .then(json => {
//         callBack(json)
//       })

// }
export const getDuePaymentByWeek = (count, nachType, callBack) => {
  return dispatch => {
    fetch(APIS.DUE_PAYMENT_BY_WEEK + count + '&nachType=' + nachType)
      .then(res => res.json())
      .then(json => {
        if (json.responseCode == 0) {
        } else {
          dispatch({ type: 'GET_ALL_DUE_PAYMENT', allDuePayment: json })
          callBack(json)
        }
      })
  }
}

export const getEmiDuePaymentByWeek = (count, loanFrom, nachType, callBack) => {
  return dispatch => {
    fetch(APIS.EMI_DUE_PAYMENT_BY_WEEK + count + '&loanFrom=' + 'phocket' + '&nachType=' + nachType)
      .then(res => res.json())
      .then(json => {
        if (json.responseCode == 0) {
        } else {
          dispatch({ type: 'GET_ALL_DUE_PAYMENT_EMI', allDuePaymentEmi: json })
          callBack(json)
        }
      })
  }
}

export const getDuePaymentByDate = (date, count, loanFrom, callBack) => {
  return dispatch => {
    fetch(APIS.DUE_PAYMENT_BY_DATE + date + '&count=' + count + '&loanFrom=' + loanFrom)
      .then(res => res.json())
      .then(json => {
        if (json.responseCode == 0) {
        } else {
          dispatch({ type: 'GET_ALL_DUE_PAYMENT', allDuePayment: json })
          callBack(json)
        }
      })
  }
}

export const getEmiDuePaymentByDate = (date, count, callBack) => {
  return dispatch => {
    fetch(APIS.EMI_DUE_PAYMENT_BY_DATE + date + '&count=' + count)
      .then(res => res.json())
      .then(json => {
        if (json.responseCode == 0) {
        } else {
          dispatch({ type: 'GET_ALL_DUE_PAYMENT_EMI', allDuePaymentEmi: json })
          callBack(json)
        }
      })
  }
}

export const saveChequeAndApp = (chq, app, callBack) => {
  var data = {
    applicationId: app,
    chequeNo: chq
  }
  fetch(APIS.SAVE_APPNO_CHECK, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}

export const pushNotification = (
  allDetail,
  newMsg,
  title,
  empEmailId,
  status,
  isStatusChange,
  imgUrl,
  mailType,
  whatsappMsg,
  templateId,
  callBack
) => {
  var data = {
    userId: allDetail.userId,
    loanId: allDetail.loanId,
    message: newMsg,
    title: title,
    emailId: allDetail.emailId,
    empId: empEmailId,
    type: status,
    loanId: allDetail.loanId,
    changeStatus: isStatusChange,
    applicationId: allDetail.applicationId,
    mobileNumber: allDetail.mobileNumber,
    imageUrl: imgUrl,
    mailType: mailType,
    whatsappMsg: whatsappMsg,
    giftCardId: null,
    templateId: templateId
  }

  fetch(APIS.PUSH_NOTIFICATION, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(json => json.text())
    .then(res => {
      callBack(res)
    })
}
export const giftCardpushNotification = (
  allDetail,
  newMsg,
  title,
  empEmailId,
  status,
  isStatusChange,
  imgUrl,
  mailType,
  whatsappMsg,
  callBack
) => {
  var data = {
    userId: allDetail.userId,
    message: newMsg,
    title: title,
    emailId: allDetail.emailId,
    empId: empEmailId,
    type: status,
    loanId: null,
    changeStatus: isStatusChange,
    applicationId: allDetail.applicationId,
    mobileNumber: allDetail.mobileNumber,
    imageUrl: imgUrl,
    mailType: mailType,
    whatsappMsg: whatsappMsg,
    giftCardId: allDetail.giftCardId
  }

  fetch(APIS.PUSH_NOTIFICATION, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(json => json.text())
    .then(res => {
      callBack(res)
    })
}
export const getCommentAdmin = (userId, callBack) => {
  fetch(APIS.GET_COMMENT_ADMIN + userId)
    .then(res => res.json())
    .then(res => {
      callBack(res)
    })
}

export const saveFollowUpStatus = (loanId, status, admin, callBack) => {

  fetch(
    APIS.SAVE_FOLLOW_UP_STATUS +
    loanId +
    '&status=' +
    status +
    '&empId=' +
    admin.emailId
  )
    .then(res => res.text())
    .then(res => {
      callBack(res)
    })
}
//////////////gift card Api/////////
export const saveFollowUpStatusForGiftCard = (
  giftCardId,
  status,
  admin,
  callBack
) => {
  fetch(
    APIS.CHANGE_FOLLOW_UP_GIFT_CARD +
    giftCardId +
    '&status=' +
    status +
    '&empId=' +
    admin.emailId
  )
    .then(res => res.text())
    .then(res => {
      callBack(res)
    })
}

export const getTransaction = (userId, callBack) => {
  fetch(APIS.GET_TRANSACTIONS + userId)
    .then(json => json.json())
    .then(res => {
      callBack(res)
    })
}

export const zipAndBranchDetail = (value, type, res) => {
  fetch(APIS.ZIP_DETAIL + value + '&type=' + type)
    .then(res => res.json())
    .then(json => {
      res(json)
    })
}

export const allAmbassadors = (count, callBack) => {
  return dispatch => {
    fetch(APIS.ALL_AMBASSADORS + count)
      .then(res => res.json())
      .then(json => {
        if (json.responseCode == 0) {
        } else {
          dispatch({ type: 'ALL_AMBASSADOR_DATA_ADMIN', status: json })
          callBack(json)
        }
      })
  }
}

export const getAmbassadorDetailApiAdmin = (ambasadorId, callBack) => {
  return dispatch => {
    fetch(APIS.ALL_AMBASADOR_DETAILS + ambasadorId)
      .then(res => res.json())
      .then(json => {
        dispatch({ type: 'GET_AMBASADOR_DETAILS', status: json })
        callBack(json)
      })
  }
}

export const getAmbassadorDetailApi = (ambasadorId, callBack) => {
  return dispatch => {
    fetch(APIS.AMBASSADOR_ALL_DATA + ambasadorId)
      .then(res => res.json())
      .then(json => {
        if (json.responseCode == 0) {
        } else {
          dispatch({
            type: 'GET_AMBASADOR_ALL_DETAILS',
            ambassadorAllData: json
          })
          callBack(json)
        }
      })
  }
}

export const getLoyalityDetailApi = (ambasadorId, callBack) => {
  return dispatch => {
    fetch(APIS.GET_ALL_LOYALITY_DETAIL + ambasadorId)
      .then(res => res.json())
      .then(json => {
        dispatch({ type: 'GET_LOYALITY_DETAILS', status: json })
        callBack(json)
      })
  }
}

export const filteredDataExcel = (exportData, callBack) => {
  var data = {
    gender: exportData.genderState,
    city: exportData.cityState,
    pincode: exportData.pincodeState,
    residentType: exportData.resTypeState,
    organisationName: exportData.orgNameState,
    designation: exportData.designationState,
    salary: exportData.salaryState,
    loanAmount: exportData.amountState,
    loanApplyDate: exportData.applyDateState,
    loanTenure: exportData.tenureState,
    loanStatus: exportData.loanStatusState,
    loanApprovedDate: exportData.approveDateState,
    disbursedDate: exportData.disburseDateState,
    paidDate: exportData.paidDateState,
    deviceType: exportData.deviceState,
    addressType: exportData.addressTypeState
  }
  fetch(APIS.FILTER_EXPORT_EXCEL, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.blob())
    .then(data => {
      var blob = new Blob([data], { type: 'application/vnd.ms-excel' })
      var excelBlob = new Blob(
        [data],
        { type: 'application/vnd.ms-excel' },
        'excel.xls'
      )
      var link = window.URL.createObjectURL(excelBlob)
      window.location = link
      var downloadUrl = URL.createObjectURL(blob)
      var a = document.createElement('a')
      a.href = downloadUrl
      a.download = 'data.xls'
      document.body.appendChild(a)
      a.click()
      callBack(data)
    })
}

export const updateCreditScore = (creditData, userId, callBack) => {
  var data = {
    userId: userId,
    caMorethanOneYear: creditData.CamorethanoneyearState,
    caOwned: creditData.AddressOwnState,
    caSameAsPa: creditData.CAsameAsPaState,
    caSameAsAdhaarAddress: creditData.CAsameAdhaarAddressState,
    workExpMorethanthreeyear: creditData.EXPMorethanthreeyearState,
    currentCompanyWorkExpMorethanOneyear:
      creditData.CurrentAddressmorethanoneyearState,
    company_Category: creditData.companyCategoryState,
    salaryOnTime: creditData.salaryOnTimeState,
    emiratio: creditData.EMIRatioState,
    existingLoan: creditData.existingLoanState,
    defaultEvident: creditData.defaultEvidentState,
    openingClosingBalance: creditData.openingClosingBalanceState,
    highValueTransaction: creditData.highValueTransactionState
  }
  fetch(APIS.UPDATE_CREDIT_SCORE, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(json => json.json())
    .then(res => {
      callBack(res)
    })
}

export const excelSheetByChoice = (
  reprotByChoice,
  fileName,
  admin,
  callBack
) => {
  var data = {
    startloanApplyDate: reprotByChoice.applyDateFromState,
    endloanApplyDate: reprotByChoice.applyDateToState,
    status: reprotByChoice.loanStatusState,
    columnlist: reprotByChoice.reportFields,
    dateFilterType: Number(reprotByChoice.downloadSheetBy),
    empId: admin.emailId
  }
  fetch(APIS.SHEET_BY_CHOICE, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.blob())
    .then(data => {
      var blob = new Blob([data], { type: 'application/vnd.ms-excel' })
      var excelBlob = new Blob(
        [data],
        { type: 'application/vnd.ms-excel' },
        'excel.xls'
      )
      var link = window.URL.createObjectURL(excelBlob)
      window.location = link
      var downloadUrl = URL.createObjectURL(blob)
      var a = document.createElement('a')
      a.href = downloadUrl
      a.download = fileName + '.xls'
      document.body.appendChild(a)
      a.click()
      callBack(data)
    })
}

export const excelSheetByChoiceNew = (
  reprotByChoice,
  fileName,
  admin,
  loanFrom,
  callBack
) => {
  var data = {
    startloanApplyDate: reprotByChoice.applyDateFromState,
    endloanApplyDate: reprotByChoice.applyDateToState,
    status: reprotByChoice.loanStatusState,
    columnlist: reprotByChoice.reportFields,
    dateFilterType: Number(reprotByChoice.downloadSheetBy),
    empId: admin.emailId,
    subStatus: reprotByChoice.subStatus,
    productType: reprotByChoice.productType,
    loanFrom: loanFrom
  }
  fetch(APIS.SHEET_BY_CHOICE_NEW, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.blob())
    .then(data => {
      var blob = new Blob([data], { type: 'application/vnd.ms-excel' })
      var excelBlob = new Blob(
        [data],
        { type: 'application/vnd.ms-excel' },
        'excel.xls'
      )
      var link = window.URL.createObjectURL(excelBlob)
      window.location = link
      var downloadUrl = URL.createObjectURL(blob)
      var a = document.createElement('a')
      a.href = downloadUrl
      a.download = fileName + '.xls'
      document.body.appendChild(a)
      a.click()
      callBack(data)
    })
}
export const fetchCalcUtility = (calcUtility, callBack) => {
  var data = {
    amountValue: calcUtility.calcUtilityAmount,
    disbursedDate: calcUtility.disburseDateState,
    paymentDate: calcUtility.repayDateState,
    paidDate: calcUtility.paymentRecievedDate,
    stampDuty: calcUtility.stampDutyState,
    bounce: calcUtility.chequeStatusState
  }
  fetch(APIS.CALC_UTILITY, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}

export const saveActionLogs = (actionDetail, giftCardId, callBack) => {
  var data = {
    userId: actionDetail.userIdState,
    body: actionDetail.actionState,
    loanId: actionDetail.loanIdState,
    empId: actionDetail.empIdState,
    giftCardId: giftCardId
  }

  fetch(APIS.SAVE_USER_ACTION_LOGS, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}

export const saveImportantLoans = (
  allDetail,
  loanId,
  importantLoan,
  empId,
  callBack
) => {
  var data = {
    userId: allDetail.userId,
    loanId: loanId,
    important: importantLoan,
    applicationId: allDetail.applicationId,
    empId: empId
  }
  fetch(APIS.SAVE_IMPORTANT_LOANS, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}

export const getActivityData = callBack => {
  return dispatch => {
    fetch(APIS.GET_ACTIVITY)
      .then(res => res.json())
      .then(json => {
        dispatch({ type: 'GET_ACTIVITY_DETAILS', status: json })
        callBack(json)
      })
  }
}

export const uploadStampDutyApi = (
  uploadfile,
  type,
  fileName,
  userId,
  callBack
) => {
  var self = this
  return new Promise((resolve, reject) => {
    var self = this
    let imageFormData = new FormData()
    for (let i = 0; i < uploadfile.length; i++) {
      imageFormData.append('uploadfile', uploadfile[i])
    }
    imageFormData.append('userId', userId)
    imageFormData.append('type', type)
    imageFormData.append('fileName', fileName)
    var xhr = new XMLHttpRequest()
    xhr.open('post', APIS.UPLOAD_STAMP_DUTY, true)
    xhr.setRequestHeader('x-api-key', 'AIzaSyB-9HOhts6vij-Lx0dNmB4KoJOfPSUN9oA')
    xhr.onload = function () {
      if (this.status == 200) {
        resolve(this.response)
        callBack(this.response)
      } else {
        reject(this.statusText)
      }
    }
    xhr.send(imageFormData)
  })
}

export const getStatusCountByWeek = callBack => {
  fetch(APIS.STATUS_COUNT_BY_WEEK)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}

export const getStatusCountByMonth = (year, month, callBack) => {
  fetch(APIS.STATUS_COUNT_BY_MONTH + year + '&month=' + month)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}

export const getStatusCountBetweenDates = (start, end, callBack) => {
  fetch(APIS.STATUS_COUNT_BETWEEN_DATES + start + '&enddate=' + end)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}

export const getPromotionalData = (FilterData, pageNumber, res) => {
  fetch(
    APIS.GET_PROMOTIONAL_DATA +
    pageNumber +
    '&filtercity=' +
    FilterData.city +
    '&filtersalary=' +
    FilterData.salary +
    '&filterexperience=' +
    FilterData.experience
  )
    .then(res => res.json())
    .then(json => {
      res(json)
    })
}

export const getFilterData = res => {
  fetch(APIS.GET_FILTER_DATA)
    .then(res => res.json())
    .then(json => {
      res(json)
    })
}

export const SendPromotional = (FilterData, Type) => {
  fetch(
    APIS.SEND_PROMOTIONAL +
    '?type=' +
    Type +
    '&filtercity=' +
    FilterData.city +
    '&filtersalary=' +
    FilterData.salary +
    '&filterexperience=' +
    FilterData.experience
  )
    .then(json => json.text())
    .then(json => { })
}

export const saveWebsiteContent = (webData, admin, callBack) => {
  var data = {
    loanProcessed: webData.loanProcessed,
    amountDisbursed: webData.amountDisbursed,
    appDownload: webData.appDownloads,
    repeatUser: webData.repeatUser,
    empId: admin.emailId
  }
  fetch(APIS.SAVE_WEBSITE_DATA, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}

export const saveNachDataApi = (nash, userId, admin, callBack) => {
  var data = {
    chequed: nash,
    userId: userId,
    empId: admin.emailId
  }
  fetch(APIS.SAVE_NACH, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}

// export const getFraudDetailApi = (userDetail, callBack) => {
//     if (userDetail != null) {
//         var data = {
//             state: userDetail.addressDetailsPOList.currentState != null ? userDetail.addressDetailsPOList.currentState : "",
//             pincode: userDetail.addressDetailsPOList.currentPincode != null ? userDetail.addressDetailsPOList.currentPincode : "",
//             company: userDetail.employmentDetailsPO.organizationName != null ? userDetail.employmentDetailsPO.organizationName : "",
//             userId: userDetail.userId
//         }
//     }
//     fetch(APIS.GET_FROUD_DETAIL, {
//         method: 'POST',
//         headers: POST_HEADER,
//         body: JSON.stringify(data)
//     })
//         .then(res => res.json())
//         .then(json => {
//             callBack(json);
//         }).catch(error => callBack(error));
// }

export const getFraudDetailApi = (userDetail, callBack) => {
  fetch(APIS.GET_FROUD_DETAIL + userDetail.userId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch(error => callBack(error))
}
export const getFraudDetailApiNew = (userDetail, regenerate, callBack) => {
  fetch(APIS.GET_FROUD_DETAIL_NEW + userDetail.userId + '&loanId=' + userDetail.loanId + '&regenerateParam=' + regenerate)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch(error => callBack(error))
}
export const getQualityAssets = callBack => {
  fetch(APIS.GET_ASSET_QUALITY)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch(error => callBack(error))
}

export const excelSheetDisbursed = (
  reprotByChoice,
  fileName,
  admin,
  callBack
) => {
  var data = {
    startloanApplyDate: reprotByChoice.applyDateFromState,
    endloanApplyDate: reprotByChoice.applyDateToState,
    empId: admin.emailId
  }
  fetch(APIS.GENERATE_DISBURSE_REPORT, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.blob())
    .then(data => {
      var blob = new Blob([data], { type: 'application/vnd.ms-excel' })
      var excelBlob = new Blob(
        [data],
        { type: 'application/vnd.ms-excel' },
        'excel.xls'
      )
      var link = window.URL.createObjectURL(excelBlob)
      window.location = link
      var downloadUrl = URL.createObjectURL(blob)
      var a = document.createElement('a')
      a.href = downloadUrl
      a.download = 'Disburse.xls'
      document.body.appendChild(a)
      a.click()
      callBack(data)
    })
}
export const excelSheetCibil = (reprotByChoice, callBack) => {
  fetch(
    APIS.GENERATE_CIBIL_REPORT +
    reprotByChoice.applyDateFromState +
    '&endDate=' +
    reprotByChoice.applyDateToState +
    '&type=' +
    reprotByChoice.loanStatusForCivilState
  )
    .then(res => res.blob())
    .then(data => {
      // excelSheetCibil
      var blob = new Blob([data], { type: 'application/vnd.ms-excel' })
      var excelBlob = new Blob(
        [data],
        { type: 'application/vnd.ms-excel' },
        'excel.xls'
      )
      var link = window.URL.createObjectURL(excelBlob)
      window.location = link
      var downloadUrl = URL.createObjectURL(blob)
      var a = document.createElement('a')
      a.href = downloadUrl
      a.download = 'Cibilreport.xls'
      document.body.appendChild(a)
      a.click()
      callBack(data)
    })
}

export const saveFieldBoyApi = (fieldBoyDetail, admin, callBack) => {
  var data = {
    name: fieldBoyDetail.FBFullNameState,
    phonenumber: Number(fieldBoyDetail.FBMobileState),
    address: fieldBoyDetail.FBAddressState,
    userid: fieldBoyDetail.FBEmailState,
    password: fieldBoyDetail.FBPasswordState,
    id: fieldBoyDetail.id,
    createdby: admin.emailId
  }
  fetch(APIS.SAVE_FIELDBOY_DETAILS, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}

export const saveLoanId = (loanId, userId, callBack) => {
  var data = {
    loanId: loanId,
    userId: userId
  }
  fetch(APIS.SAVE_LOAN_ID, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}

export const getlistByAppLoanId = (appLoanNo, callBack) => {
  return dispatch => {
    fetch(APIS.LOAN_LIST_BY_LOAN + appLoanNo + '&pageNumber=' + '1')
      .then(res => res.json())
      .then(json => {
        if (json.responseCode == 0) {
        } else {
          dispatch({ type: 'GET_DISBURSE_LEAD', disburseLeads: json })
          callBack(json)
        }
      })
  }
}

export const sendRecoveryMailAPI = (
  subject,
  dataForCommunication,
  mailBody,
  admin,
  templateId,
  callBack
) => {
  var data = {
    loanId: dataForCommunication.loanId,
    userId: dataForCommunication.userId,
    employeeID: admin.emailId,
    emailId: dataForCommunication.emailId,
    phoneNumber: '',
    subject: subject,
    comments: mailBody,
    applicationId: dataForCommunication.applicationId,
    notification: 0,
    templateId: templateId,

  }
  // 
  fetch(APIS.SEND_RECOVERY_COMMUNICATION, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}

export const sendRecoveryMsgAPI = (
  dataForCommunication,
  msgBody,
  admin,
  templateId,
  callBack
) => {
  var data = {
    loanId: dataForCommunication.loanId,
    userId: dataForCommunication.userId,
    employeeID: admin.emailId,
    emailId: '',
    phoneNumber: dataForCommunication.mobileNumber,
    subject: '',
    comments: msgBody,
    applicationId: dataForCommunication.applicationId,
    notification: 1,
    templateId: templateId
  }
  // 
  fetch(APIS.SEND_RECOVERY_COMMUNICATION, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}

export const saveRecoveryCommentAPI = (
  dataForCommunication,
  commentBody,
  admin,
  templateId,
  callBack
) => {
  var data = {
    loanId: dataForCommunication.loanId,
    userId: dataForCommunication.userId,
    employeeID: admin.emailId,
    emailId: '',
    phoneNumber: '',
    subject: '',
    comments: commentBody,
    applicationId: dataForCommunication.applicationId,
    notification: 2,
    templateId: templateId,

  }
  fetch(APIS.SEND_RECOVERY_COMMUNICATION, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}

export const sendRecoveryNotificationAPI = (
  subject,
  dataForCommunication,
  notificationBody,
  admin,
  templateId,
  callBack
) => {
  var data = {
    loanId: dataForCommunication.loanId,
    userId: dataForCommunication.userId,
    employeeID: admin.emailId,
    emailId: '',
    phoneNumber: '',
    subject: subject,
    comments: notificationBody,
    applicationId: dataForCommunication.applicationId,
    notification: 3,
    templateId: templateId,

  }
  fetch(APIS.SEND_RECOVERY_COMMUNICATION, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}

export const recoveryLogsApi = (userId, callBack) => {
  var data = {
    userId: userId
  }
  fetch(APIS.GET_RECOVERY_LOGS, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}

export const saveCHequeNoticeApi = (
  loanId,
  type,
  date,
  admin,
  emiId,
  callBack
) => {
  var data = {
    loanId: loanId,
    type: type,
    date: date,
    empId: admin.emailId,
    emiId: emiId
  }
  fetch(APIS.SAVE_CHEQUE_NOTICE, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}

export const updateUsingCSVApi = (pdfFile, callBack) => {
  var self = this
  return new Promise((resolve, reject) => {
    var self = this
    let imageFormData = new FormData()
    for (let i = 0; i < pdfFile.length; i++) {
      imageFormData.append('csvfile', pdfFile[i])
    }
    var xhr = new XMLHttpRequest()
    xhr.open('post', APIS.UPDATE_USING_CSV, true)
    xhr.onload = function () {
      if (this.status == 200) {
        resolve(this.response)
        callBack(this.response)
      } else {
        reject(this.statusText)
        callBack(this.response)
      }
    }
    xhr.send(imageFormData)
  })
}

export const updateDBUsingMappedDataAPI = (mappedData, callBack) => {
  fetch(APIS.UPDATE_DB_DATA, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-api-key': 'AIzaSyB-9HOhts6vij-Lx0dNmB4KoJOfPSUN9oA'
    },
    body: JSON.stringify(mappedData)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}

export const docUploadSaveMail = (name, url, userId, callBack) => {
  var data = {
    name: name,
    url: url,
    userId: userId
  }
  fetch(APIS.DOC_UPLOAD_SAVE_FROM_MAIL, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}

export const docUploadMailSave = (userId, userDetail, callBack) => {
  var data = {
    userId: userId,
    comment: userDetail.commentsState
  }
  fetch(APIS.DOC_MAIL_SAVE, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}

export const sendWhatsappMsgesApi = (allDetail, msg, admin, type, callBack) => {
  var data = {
    phoneNo: allDetail.mobileNumber,
    body: msg,
    empId: admin.emailId,
    whatsAppType: type
  }
  fetch(APIS.SEND_WHATSAPP_MSG, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}

export const getWhatsappMsgApi = (mobileNumber, type, msgType, callBack) => {
  fetch(
    APIS.GET_WHATSAPP_MSG +
    mobileNumber +
    '&whatsAppType=' +
    type +
    '&readUnreadMsg=' +
    msgType
  )
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}

export const whatsappFileApi = (
  file,
  allDetail,
  fileName,
  fileType,
  admin,
  type,
  callBack
) => {
  return new Promise((resolve, reject) => {
    let imageFormData = new FormData()
    imageFormData.append('uploadfile', file)
    imageFormData.append('mobileNo', allDetail.mobileNumber)
    imageFormData.append('type', fileType == 'pdf' ? 'pdf' : 'img')
    imageFormData.append('fileName', fileName)
    imageFormData.append('empId', admin.emailId)
    imageFormData.append('whatsAppType', type)
    var xhr = new XMLHttpRequest()
    xhr.open('post', APIS.SEND_WHATSAPP_FILE, true)
    xhr.onload = function () {
      if (this.status == 200) {
        resolve(this.response)
        callBack(this.response)
      } else {
        reject(this.statusText)
      }
    }
    xhr.send(imageFormData)
  })
}

export const assignApplicationApi = (
  fieldBoyData,
  admin,
  allDetail,
  callBack
) => {
  var data = {
    empID: fieldBoyData.boyNameState,
    address: fieldBoyData.addressState,
    timeSlot:
      moment(new Date(fieldBoyData.timeFrom)).format('HH:mm') +
      ' - ' +
      moment(new Date(fieldBoyData.timeTo)).format('HH:mm'),
    loanId: allDetail.loanId,
    assignedBy: admin.emailId
  }
  fetch(APIS.ASSIGN_APPLICATION, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}

export const updateAssignedApplicationApi = (
  fieldBoyData,
  admin,
  allDetail,
  callBack
) => {
  var data = {
    empID: fieldBoyData.boyNameState,
    address: fieldBoyData.addressState,
    timeSlot:
      moment(new Date(fieldBoyData.timeFrom)).format('HH:mm') +
      ' - ' +
      moment(new Date(fieldBoyData.timeTo)).format('HH:mm'),
    loanId: allDetail.loanId,
    assignedBy: admin.emailId
  }
  fetch(APIS.UPDATE_ASSIGN_FIELD_BOY, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}

export const companyReferralApi = (referData, admin, callBack) => {
  var data = {
    empId: admin.emailId,
    merchantName: referData.companyName,
    referralCode: referData.referralCode
  }
  fetch(APIS.REFERRAL_COMPANY, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}

export const SaveAmbCommentsApi = (ambData, comment, admin, callBack) => {
  var data = {
    empId: admin.emailId,
    ambassadorId: ambData.ambassadorId,
    body: comment,
    title: '',
    type: 'comment'
  }
  fetch(APIS.SAVE_AMBASSADOR_COMMENTS, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}
export const saveTentativeDataGiftCard = (
  tentativeData,
  paybackDates,
  userDetails,
  admin,
  company,
  callBack
) => {
  var data = {
    nbfcId: tentativeData.nbfcId,
    empId: admin.emailId,
    amount: tentativeData.tentativeAmount,
    shift: tentativeData.tentativeShift,
    giftCardId: userDetails.giftCardId,
    userId: userDetails.userId,
    paybackDates: paybackDates,
    giftCardType: company
  }

  fetch(APIS.SAVE_TENTATIVE_DATA_GIFT_CARD, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}
export const saveTentativeDataApi = (
  tentativeData,
  paybackDates,
  allDetail,
  admin,
  esignDisable,
  callBack
) => {
  var data = {
    nbfcId: tentativeData.nbfcId,
    empId: admin.emailId,
    amount: tentativeData.tentativeAmount,
    shift: tentativeData.tentativeShift,
    loanId: allDetail.loanId,
    userId: allDetail.userId,
    paybackDates: paybackDates,
    esignDisable: esignDisable
  }


  fetch(APIS.SAVE_TENTATIVE_DATA, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}

export const notificationImageUploadApi = (file, name, fileType, callBack) => {
  return new Promise((resolve, reject) => {
    let imageFormData = new FormData()
    imageFormData.append('uploadfile', file)
    imageFormData.append('type', 'img')
    imageFormData.append('fileName', name)
    imageFormData.append('fileType', fileType)
    var xhr = new XMLHttpRequest()

    xhr.open('post', APIS.NOTIFICATION_IMAGE_UPLOAD, true)
    xhr.setRequestHeader('x-api-key', 'AIzaSyB-9HOhts6vij-Lx0dNmB4KoJOfPSUN9oA')
    xhr.onload = function () {
      if (this.status == 200) {
        resolve(this.response)
        callBack(this.response)
      } else {
        reject(this.statusText)
      }
    }
    xhr.send(imageFormData)
  })
}

export const getIvrResponseApi = callBack => {
  fetch(APIS.GET_IVR_RESPONSE)
    .then(res => res.json())
    .then(res => {
      callBack(res)
    })
}

export const saveInstallmentApis = (
  installData,
  allDetail,
  admin,
  callBack
) => {
  var data = {
    emiAmount: installData.installAmountState,
    date: installData.installDateState,
    applicationId: allDetail.applicationId,
    loanId: allDetail.loanId,
    empId: admin.emailId,
    id: installData.installId
  }
  fetch(APIS.SAVE_LOAN_INSTALLMENT, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}

export const fetchAmbassadorBasicDetail = (ambassadorDetail, callBack) => {
  var data = {
    ambassadorId: ambassadorDetail.ambassadorId,
    fullName: ambassadorDetail.ambNameState,
    dateOfBirth: ambassadorDetail.ambDOB,
    emailId: ambassadorDetail.ambEmailState,
    gender: ambassadorDetail.ambGenderState,
    howToKnow: ambassadorDetail.HowToKnowState,
    mobileNumber: ambassadorDetail.ambMobileState,
    formStatus: ambassadorDetail.formStatus
  }
  fetch(APIS.AMBASSADOR_BASIC_DETAIL, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}

export const fetchAmbassadorAddressDetail = (
  ambassadorAddressDetail,
  callBack
) => {
  var data = {
    ambassadorId: ambassadorAddressDetail.ambassadorId,
    address: ambassadorAddressDetail.ambAddressState,
    pincode: ambassadorAddressDetail.ambPinCodeState,
    state: ambassadorAddressDetail.ambStateState,
    city: ambassadorAddressDetail.ambCityState,
    qualification: ambassadorAddressDetail.ambHighQualificationState,
    formStatus: ambassadorAddressDetail.formStatus
  }
  fetch(APIS.AMBASSADOR_ADDRESS_DETAIL, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}

export const saveAndCheckForExistingUserApi = (ambRegDetail, callBack) => {
  var data = {
    fullName: ambRegDetail.fullName,
    mobileNumber: ambRegDetail.mobileNumber,
    emailId: ambRegDetail.emailId,
    password: ambRegDetail.ambPasswordState
  }
  fetch(APIS.SAVE_EXISTING_USER, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}

export const userToAmbassadorApi = (ambRegDetail, callBack) => {
  fetch(APIS.USER_TO_AMBASSADOR, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(ambRegDetail)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}

export const sendverificationRequestApi = (emailID, callBack) => {
  var data = {
    emailID: emailID
  }
  fetch(APIS.SEND_MAIL_VERIFICATION_REQUEST, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}

export const userDocsByFieldBoy = (
  file,
  type,
  adminId,
  userId,
  loanId,
  callBack
) => {
  var self = this
  return new Promise((resolve, reject) => {
    var self = this
    let imageFormData = new FormData()
    imageFormData.append('uploadfile', file)
    imageFormData.append('type', type)
    imageFormData.append('userId', userId)
    imageFormData.append('loanId', loanId)
    imageFormData.append('address', adminId)
    imageFormData.append('city', adminId)
    var xhr = new XMLHttpRequest()
    xhr.open('post', APIS.FIELD_BOY_DOCUMENTS, true)
    xhr.setRequestHeader('x-api-key', 'AIzaSyB-9HOhts6vij-Lx0dNmB4KoJOfPSUN9oA')
    xhr.onload = function () {
      if (this.status == 200) {
        resolve(this.response)
        callBack(this.response)
      } else {
        reject(this.statusText)
      }
    }
    xhr.send(imageFormData)
  })
}

export const communicationCountByFilterApi = (filterComm, callBack) => {
  fetch(
    APIS.COMMUNICATION_COUNT_BY_FILTER +
    filterComm.stateFilter +
    '&status=' +
    filterComm.status +
    '&knowFrom=' +
    filterComm.HowToKnowState +
    '&startSalary=' +
    filterComm.salaryFrom +
    '&endSalary=' +
    filterComm.salaryTo +
    '&startDate=' +
    filterComm.applyDateFrom +
    '&endDate=' +
    filterComm.applyDateTo +
    '&preferredCompany=' +
    ''
  )
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}

export const calculateEmiApi = (
  amount,
  months,
  salaryDay,
  disburseDate,
  callBack
) => {

  fetch(
    APIS.GET_CALCULATIO_FOR_ADMIN +
    amount +
    '/' +
    months +
    '/' +
    salaryDay +
    '/' +
    disburseDate
  )
    .then(res => res.json())
    .then(json => {

      callBack(json)
    })
}

export const approveLoanApi = (
  allDetail,
  status,
  reason,
  approveData,
  mail,
  score,
  empId,
  paybackDates,
  nbfcName,
  esignDisable,
  callBack
) => {
  var data = {
    userId: allDetail.userId,
    loanId: allDetail.loanId,
    loanStatus: status,
    loanReason: reason,
    approveDate: approveData.approveDate,
    loanEmi: approveData.selectEMI,
    loanPayBackDate: '',
    amount: approveData.ApproveAmount,
    mail: mail,
    score: score,
    empId: empId,
    paybackDates: paybackDates,
    nbfcName: nbfcName,
    nbfcId: approveData.nbfcId,
    subStatus: approveData.subStatus,
    esignDisable: esignDisable
  };

  fetch(APIS.LOAN_SANCTION, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(res => {

      // Ensure callBack is a function before invoking it
      if (callBack && typeof callBack === 'function') {
        callBack(res);
      } else {
      }
    })
    .catch(error => {
      console.error('Error in fetch operation:', error);

      // Handle error, optionally invoke callback with an error message
      if (callBack && typeof callBack === 'function') {
        callBack('Error occurred during the API call');
      } else {
        console.error('Error: callBack is not a function or not provided');
      }
    });
};

export const giftCardSanction = (
  allDetail,
  status,
  reason,
  approveData,
  mail,
  score,
  empId,
  paybackDates,
  nbfcName,
  callBack
) => {
  var data = {
    userId: allDetail.userId,
    loanId: '',
    giftCardId: allDetail.giftCardId,
    loanStatus: status,
    loanReason: reason,
    approveDate: approveData.approveDate,
    loanEmi: approveData.selectEMI,
    loanPayBackDate: '',
    amount: approveData.ApproveAmount,
    mail: mail,
    score: score,
    empId: empId,
    paybackDates: paybackDates,
    nbfcName: nbfcName,
    nbfcId: approveData.nbfcId
  }

  fetch(APIS.GIFTCARD_SANCTION, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}
/////Gift Card Api///////////////////
export const approveGiftCardApi = (
  allDetail,
  status,
  approveData,
  mail,
  empId,
  paybackDates,
  giftCardId,
  giftCardType,
  callBack
) => {
  var data = {
    userId: allDetail.userId,
    giftCardId: giftCardId,
    nbfcId: approveData.nbfcId,
    approveDate: approveData.approveDate,
    paybackDates: paybackDates,
    empId: empId,
    loanEmi: approveData.selectEMI,
    amount: approveData.ApproveAmount,
    loanStatus: status,
    mail: mail,
    giftCardType: giftCardType,
    subStatus: approveData.subStatus
  }


  fetch(APIS.GIFT_CARD_APPROVE_API, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}

export const getLeadByMobileNumbers = (mobileArray, callBack) => {
  return dispatch => {
    fetch(APIS.GET_LEAD_BY_MOBILE_NUMBER, {
      method: 'POST',
      headers: POST_HEADER,
      body: JSON.stringify(mobileArray)
    })
      .then(res => res.json())
      .then(json => {
        if (json.responseCode == 0) {
        } else {
          dispatch({ type: 'GET_LEAD_BY_NUMBERS', leadByNumber: json })
          callBack(json)
        }
      })
  }
}

export const pincodeUploadApi = (pincodeData, callBack) => {
  fetch(APIS.PINCODE_UPLOAD, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(pincodeData)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}

export const approveToDisburseUploadApi = (csvFile, callBack) => {
  return new Promise((resolve, reject) => {
    let imageFormData = new FormData()
    imageFormData.append('csvfile', csvFile)
    // imageFormData.append('type', type);
    //imageFormData.append('empId', admin.emailId);
    var xhr = new XMLHttpRequest()
    xhr.open('post', APIS.APPROVE_T0_DISBURSE, true)
    xhr.onload = function () {
      if (this.status == 200) {
        resolve(this.response)
        callBack(this.response)
      } else {
        reject(this.statusText)
      }
    }
    xhr.send(imageFormData)
  })
}
export const convertToPaidApi = (csvFile, type, admin, callBack) => {
  return new Promise((resolve, reject) => {
    let imageFormData = new FormData()
    imageFormData.append('csvfile', csvFile)
    imageFormData.append('FileType', type)
    imageFormData.append('empId', admin.emailId)
    var xhr = new XMLHttpRequest()
    xhr.open('post', APIS.CONVERT_TO_PAID_BULK, true)
    xhr.onload = function () {
      if (this.status == 200) {
        resolve(this.response)
        callBack(this.response)
      } else {
        reject(this.statusText)
      }
    }
    xhr.send(imageFormData)
  })
}

export const getManagementLeadApi = (
  type,
  status,
  count,
  empId,
  date,
  commentType,
  salaryRange,
  bucketId,
  callBack
) => {
  return dispatch => {
    fetch(
      APIS.GET_LOAN_APPROVAL_LEAD +

      status +
      '&salaryRange=' + salaryRange +
      '&pageNumber=' +
      count +
      '&empId=' +
      empId +
      '&date=' +
      date +
      '&type=' +
      type +
      '&commentType=' + commentType +
      '&eDate=' +
      date +
      '&tabType=' +
      '&bucketId=' + bucketId,



      {
        // headers: {
        //     'Accept': 'application/json',
        //     'Content-Type': 'application/json',
        //     'Authorization': 'bearer ' + LocalStrorageUtility.getFromLocalStorage('access_token')
        // }
      }
    )
      .then(res => res.json())
      .then(json => {
        if (json.responseCode == 0) {
        } else {
          dispatch({ type: 'GET_LEAD_MANAGEMENT', LeadManagementData: json })
          callBack(json)
        }
      })
  }
}

export const bulkAssignByLoanId = (loanIdList, agentId, empId, callBack) => {
  var data = {
    agent: agentId,
    loanList: loanIdList,
    empId: empId
  }
  fetch(APIS.MANUAL_ASSIGN_BY_LOAN_ID, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}

export const bulkApplicationAssignApi = (bulkData, admin, callBack) => {
  var data = {
    state: bulkData.state,
    assignIds: bulkData.stateAgent,
    empId: admin.emailId,


  }
  fetch(APIS.BULK_APPLICATION_ASSIGN, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}

export const saveAssignByStatusAPI = (bulkAssignDetail, admin, assignIdsWithLeave, callBack) => {
  var data = {
    status: bulkAssignDetail.status,
    assignIds: bulkAssignDetail.statusAgent,
    empId: admin.emailId,
    salaryBucket: bulkAssignDetail.salaryBucket,
    assignIdsWithLeave: assignIdsWithLeave
  }
  fetch(APIS.SAVE_ASSIGN_BY_STATUS, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}

export const getLoanAddaLead = (status, count, empId, date, callBack) => {
  return dispatch => {
    fetch(
      APIS.GET_LOAN_ADDA_LEAD +
      status +
      '&pageNumber=' +
      count +
      '&empId=' +
      empId +
      '&date=' +
      date +
      '&companyName=loanadda'
    )
      .then(res => res.json())
      .then(json => {
        if (json.responseCode == 0) {
        } else {
          dispatch({ type: 'GET_LOAN_ADDAL_LEAD', loanAddaLeads: json })
          callBack(json)
        }
      })
  }
}

export const updateBulkNachStatusAPI = (nachData, callBack) => {
  fetch(APIS.NACH_UPLOAD_BULK, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(nachData)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}

export const addPriorityCompanyApi = (companies, callBack) => {
  var data = {
    companyList: companies
  }
  fetch(APIS.ADD_PRIORITY_COMPANY, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}
export const loanbreakupapi = (allDetail, date, callBack) => {
  fetch(APIS.GET_LOAN_BREAK_BY_LOANID + allDetail.loanId + '&date=' + date)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch(error => callBack('no data'))
}
export const paidLoanApi = (PaidData, allDetail, admin, callBack) => {
  var data = {
    chequeBounceAmount: PaidData.chequeBounceAmount,
    conversionCharge: PaidData.conversionCharge,
    date: PaidData.paidDate,
    empId: admin.emailId,
    enachBounceAmount: PaidData.nachBounceAmount,
    endDate: PaidData.endDate,
    flag: PaidData.flag,
    interestAmount: PaidData.interestAmount,
    loanAmount: PaidData.loanAmount,
    loanApproveAmount: PaidData.loanApproveAmount,
    loanId: allDetail.loanId,
    loanInstallment: PaidData.loanInstallment,
    numberOfDays: PaidData.loanTenure,
    paidAmount: PaidData.paidAmount,
    penaltyAmount: PaidData.penaltyAmount,
    processingFees: PaidData.processingFees,
    stampDuty: PaidData.stampDuty,
    startDate: PaidData.startDate,
    status: PaidData.status,
    totalAmountPayable: PaidData.finalPaybleAmount,
    noOfEmi: PaidData.loanEmi
  }

  fetch(APIS.PAID_STATUS, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
  // .catch(err=>{
  //   callBack('no data')
  // })
}

export const calculateEmiNbfcApi = (
  amount,
  months,
  salaryDay,
  nbfcName,
  callBack
) => {
  fetch(
    APIS.EMI_CALC_WITH_NBFC +
    amount +
    '/' +
    months +
    '/' +
    salaryDay +
    '/' +
    nbfcName
  )
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}

export const UpdateAccountInfoApi = (accInfoData, callBack) => {
  fetch(APIS.UPDATE_ACC_INFO_CSV, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(accInfoData)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}

export const nachReportDataApi = (pageNo, date, type, id, callBack) => {
  fetch(APIS.NACH_REPORT_DATA + pageNo + '&date=' + date + '&type=' + type + '&applicationId=' + id)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch(error => callBack(error))
}

export const mandateVarificationApi = (userId, loanId, type, callBack) => {
  fetch(
    APIS.VERIFY_MENDATE_SAVE_TOKEN +
    userId +
    '&loanId=' +
    loanId +
    '&type=' +
    type
  )
    .then(res => res.text())
    .then(res => {
      callBack(res)
    })
}

export const sendMailToNachFailApi = (allDetail, type, callBack) => {
  fetch(
    APIS.SEND_MAIL_TO_NACH_FAIL +
    allDetail.loanId +
    '&emailId=' +
    allDetail.emailId +
    '&name=' +
    allDetail.userName +
    '&type=' +
    type
  )
    .then(res => res.text())
    .then(res => {
      callBack(res)
    })
}
export const nachCommentApi = (paymentId, userId, callBack) => {
  fetch(
    APIS.GET_COMMENT_FOR_FAILURE +
    '?paymentId=' +
    paymentId +
    '&userId=' +
    userId
  )
    .then(res => res.text())
    .then(res => {
      callBack(res)
    })
}

export const bulkCommunicateByCsvApi = (
  csvFile,
  allData,
  requestFrom,
  imgUrl,
  body,
  admin,
  templateIdTosend,
  headerType,
  callBack
) => {

  return new Promise((resolve, reject) => {
    let imageFormData = new FormData()
    imageFormData.append('csvfile', csvFile)
    imageFormData.append('type', allData.type)
    imageFormData.append('empId', admin.emailId)
    imageFormData.append('subject', allData.title)
    imageFormData.append('requestFrom', requestFrom)
    imageFormData.append('body', body)
    imageFormData.append('imageUrl', imgUrl)
    imageFormData.append('templateId', templateIdTosend)
    imageFormData.append('headerType', headerType)

    var xhr = new XMLHttpRequest()
    xhr.open('post', APIS.BULK_COM_BY_CSV, true)
    xhr.onload = function () {
      if (this.status == 200) {
        resolve(this.response)
        callBack(this.response)
      } else {
        reject(this.statusText)
      }
    }
    xhr.send(imageFormData)
  })
}
export const bulkCommunicateByCsvMObileApi = (
  csvFile,
  requestFrom,
  body,
  admin,
  templateIdTosend,
  headerType,
  callBack
) => {
  return new Promise((resolve, reject) => {
    let imageFormData = new FormData()
    imageFormData.append('csvfile', csvFile)
    imageFormData.append('empId', admin.emailId)
    imageFormData.append('requestFrom', requestFrom)
    imageFormData.append('body', body)
    imageFormData.append('templateId', templateIdTosend)
    imageFormData.append('headerType', headerType)

    var xhr = new XMLHttpRequest()
    xhr.open('post', APIS.SEND_MSG_BY_MOBILE_CSV, true)
    xhr.onload = function () {
      if (this.status == 200) {
        resolve(this.response)
        callBack(this.response)
      } else {
        reject(this.statusText)
      }
    }
    xhr.send(imageFormData)
  })
}
export const uploadCSVFileApi = (csvFile, type, admin, callBack) => {
  return new Promise((resolve, reject) => {
    let imageFormData = new FormData()
    imageFormData.append('csvfile', csvFile)
    imageFormData.append('type', type)
    imageFormData.append('empId', admin.emailId)
    var xhr = new XMLHttpRequest()
    xhr.open('post', APIS.UPLOAD_CSV_FILE, true)
    xhr.onload = function () {
      if (this.status == 200) {
        resolve(this.response)
        callBack(this.response)
      } else {
        reject(this.statusText)
      }
    }
    xhr.send(imageFormData)
  })
}

export const uploadCibilFileApi = (cibilData, callBack) => {
  fetch(APIS.UPLOAD_CIBIL_FILE, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(cibilData)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}

export const deductUserPaymentApi = (loanId, amount, emiId, callBack) => {
  fetch(APIS.DEDUCT_PAYMENT + loanId + '&amount=' + amount + '&emiId=' + emiId)
    .then(res => res.text())
    .then(res => {
      callBack(res)
    })
}
export const deductUserPaymentByTechProcess = (
  loanId,
  amount,
  date,
  callBack
) => {
  fetch(
    APIS.DEDUCT_PAYMENT_BY_TECH_PROCESS +
    loanId +
    '&amount=' +
    amount +
    '&date=' +
    date
  )
    .then(res => res.text())
    .then(res => {
      callBack(res)
    })
}
export const getSourceOfEnach = (userId, callBack) => {
  fetch(APIS.GET_SOURCE_OF_ENACH_BY_USERID + userId)
    .then(res => res.json())
    .then(res => {
      callBack(res)
    })
}

export const uploadExternalApi = (fileName, callBack) => {
  var self = this
  return new Promise((resolve, reject) => {
    var self = this
    let imageFormData = new FormData()
    imageFormData.append('file', fileName)
    var xhr = new XMLHttpRequest()
    xhr.open('post', APIS.EXTERNAL_LINK_UPLOAD, true)
    xhr.onload = function () {
      if (this.status == 201) {
        resolve(this.response)
        callBack(this.response)
      } else {
        reject(this.statusText)
      }
    }
    xhr.send(imageFormData)
  })
}

export const getPsychometricData = (userId, callBack) => {
  var data = {
    user_id: userId
  }
  fetch(APIS.GET_PSYCHOMETRY_DATA, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}

export const pushLeadToRetraApi = (loanId, callBack) => {
  fetch(APIS.PUSH_LEAD_TO_RETRA + loanId)
    .then(res => res.text())
    .then(res => {
      callBack(res)
    })
    .catch(e => callBack(e))
}

export const bulkPushReraApi = (loanIdArray, callBack) => {
  var data = {
    loanIdList: loanIdArray
  }
  fetch(APIS.BULK_PUSH_RETRA, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}

export const loyalityOffersApi = (
  loyalityOfferState,
  loyalityStatusState,
  callBack
) => {
  var data = {
    amountOffer: loyalityOfferState,
    status: loyalityStatusState
  }
  fetch(APIS.LOYALITY_OFFER, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(json => json.text())
    .then(res => {
      callBack(res)
    })
}

export const saveOfferDataApi = (promoDetail, callBack) => {
  var data = {
    promoAmount: promoDetail.promoAmountState,
    promoCode: promoDetail.promoCodeState,
    promoendDate: promoDetail.promoEndState,
    promoStartDate: promoDetail.promoStartState
  }
  fetch(APIS.SAVE_PROMOTATIONAL_OFFER_DATA, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}

export const oauthTokenApi = callBack => {
  const formData = new URLSearchParams()
  formData.set(
    'username',
    LocalStrorageUtility.getFromLocalStorage('AdminemailId')
  )
  formData.set('password', LocalStrorageUtility.getFromLocalStorage('password'))
  formData.set('grant_type', 'password')
  formData.set('client_id', 'clientapp')
  formData.set('scope', 'read write')
  formData.set('client_secret', 123456)

  var request = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Authorization: 'Basic Y2xpZW50YXBwOjEyMzQ1Ng=='
    },
    body: formData
  }

  fetch(APIS.AUTH_TOKEN, request)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}

export const getIncomeCollection = (startDate, endDate, callBack) => {
  fetch(APIS.GET_INCOME_COLLECTION_API + startDate + '&endDate=' + endDate)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch(error => callBack(error))
}
export const generateCollectionReport = (startDate, endDate, callBack) => {
  fetch(APIS.GENERATE_COLLECTION_REPORT + startDate + '&endDate=' + endDate)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch(error => callBack(error))
}

export const changePasswordApi = (email, password, OldPassword, callBack) => {
  var data = {
    password: password,
    emailId: email,
    oldPassword: OldPassword
  }
  fetch(APIS.CHANGE_ADMIN_PASSWORD, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-api-key': 'AIzaSyB-9HOhts6vij-Lx0dNmB4KoJOfPSUN9oA'
    },
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}

export const initiateBulkPaymentByRazorpay = (csvFile, admin, callBack) => {
  return new Promise((resolve, reject) => {
    let imageFormData = new FormData()
    imageFormData.append('csvfile', csvFile)
    imageFormData.append('empId', admin.emailId)

    var xhr = new XMLHttpRequest()
    xhr.open('post', APIS.INITIATE_BULK_PAYMENT_RAZORPAY, true)
    xhr.onload = function () {
      if (this.status == 200) {
        resolve(this.response)
        callBack(this.response)
      } else {
        reject(this.statusText)
      }
    }
    xhr.send(imageFormData)
  })
}
/////////////giftcard//////////
export const giftCardEmiBreakUp = (allDetail, date, callBack) => {
  fetch(APIS.GET_GIFTCARD_EMI_BREAK_UP + allDetail.giftCardId + '&date=' + date)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch(error => callBack('no data'))
}
export const paidGiftCardApi = (PaidData, allDetail, admin, callBack) => {
  var data = {
    chequeBounceAmount: PaidData.chequeBounceAmount,
    conversionCharge: PaidData.conversionCharge,
    date: PaidData.paidDate,
    empId: admin.emailId,
    enachBounceAmount: PaidData.nachBounceAmount,
    endDate: PaidData.endDate,
    flag: PaidData.flag,
    interestAmount: PaidData.interestAmount,
    loanAmount: PaidData.loanAmount,
    loanApproveAmount: PaidData.loanApproveAmount,
    giftCardId: allDetail.giftCardId,
    loanInstallment: PaidData.loanInstallment,
    numberOfDays: PaidData.loanTenure,
    paidAmount: PaidData.paidAmount,
    penaltyAmount: PaidData.penaltyAmount,
    processingFees: PaidData.processingFees,
    stampDuty: PaidData.stampDuty,
    startDate: PaidData.startDate,
    status: PaidData.status,
    totalAmountPayable: PaidData.finalPaybleAmount,
    noOfEmi: PaidData.loanEmi
  }

  fetch(APIS.GIFTCARD_PAID, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
    .catch(err => {
      callBack('no data')
    })
}
export const getFinBitData = (userId, type, regenerate, callBack) => {
  fetch(APIS.GET_FINBIT_DATA + userId + '&type=' + type + '&regenerate=' + regenerate)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch(error =>

      callBack('no data'))
}

export const getFinBitDataNew = (userId, type, startDate, endDate, regenerate, callBack) => {
  fetch(APIS.GET_FINBIT_DATA + userId + '&type=' + type + '&startDate=' + startDate + '&endDate=' + endDate + '&regenerate=' + regenerate)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch(error =>

      callBack('no data'))
}
export const getFinDataDetails = (userId, callBack) => {
  fetch(APIS.GET_FINBIT_DATA_DETAILS + userId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch(error =>

      callBack('no data'))
}


export const saveAssigForCollectionApi = (bulkAssignDetail, admin, callBack) => {
  var data = {
    status: bulkAssignDetail.status,
    assignIds: bulkAssignDetail.statusAgent,
    empId: admin.emailId,
    overdueLimit: bulkAssignDetail.overdueLimit
  }
  // 
  fetch(APIS.SAVE_FOR_COLLECTION, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}

export const deactivateNachByLoanId = (loanId, callBack) => {
  fetch(APIS.GET_ENACH_DEACTIVATION_BY_LOANID + loanId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}

export const bulkNachBounceByCsv = (
  csvFile,
  callBack
) => {
  return new Promise((resolve, reject) => {
    let imageFormData = new FormData()
    imageFormData.append('csvfile', csvFile)

    var xhr = new XMLHttpRequest()
    xhr.open('post', APIS.BULK_NACH_BOUNCE_BY_CSV, true)
    xhr.onload = function () {
      if (this.status == 200) {
        resolve(this.response)
        callBack(this.response)
      } else {
        reject(this.statusText)
      }
    }
    xhr.send(imageFormData)
  })
}
export const newEsignApi = (loanId, esignType, aadhaar_type, callBack) => {
  fetch(APIS.ESIGN_AND_ENACH_INITIATE_NEW + loanId + '&esignType=' + esignType + '&aadhaar_type=' + aadhaar_type, {
    // fetch(APIS.ESIGN_AND_ENACH_INITIATE_NEW + loanId + '&esignType=' + esignType , {

    method: 'POST',
    headers: POST_HEADER,
    // body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}

export const widgetapi = (loanId, token, docketid, esignType, callBack) => {
  fetch(APIS.WIDGET_API + loanId + "&widget_token=" + token + "&docket_id=" + docketid + '&esignType=' + esignType)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}

export const rfdApi = (loanId, statusId, empID, userId, giftCardId, callBack) => {
  fetch(APIS.RFD_API + loanId + '&statusId=' + statusId + "&empId=" + empID + '&userId=' + userId + '&giftCardId=' + giftCardId)
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}
// export const docketApi = (loanId, callBack) => {
//   fetch(APIS.GET_DOCKET_INFO + loanId)
//     .then(res => res.json())
//     .then(json => {
//       callBack(json)
//     })
// }
// export const signatureApi = (dockid, callBack) => {
//   fetch(APIS.GET_SIGNATURE_STATUS + dockid)
//     .then(res => res.json())
//     .then(json => {
//       callBack(json)
//     })
// }
export const signatureApi = (data, loanId, esignType, callBack) => {
  fetch(APIS.GET_SIGNATURE_STATUS + data + '&loanId=' + loanId + '&esignType=' + esignType, {
    method: 'POST',
    headers: POST_HEADER,
    // body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}
export const docketApi = (data, esignType, callBack) => {
  fetch(APIS.GET_DOCKET_INFO + data + '&esignType=' + esignType, {
    method: 'POST',
    headers: POST_HEADER,
    // body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}
export const BulkPaymentICICI = (data, callBack) => {
  fetch(APIS.BULK_ICICI_PAYMENT, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)

  })

    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}
export const verifyIciciPayments = (data, callBack) => {
  fetch(APIS.BULK_VERIFY_TRANSACTION, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)

  })

    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}


export const downloadBuddyLoanReportComplete = (startDate, endDate, callBack) => {

  fetch(
    APIS.BUDDY_LOAN_COMPLETE_DATA +
    startDate +
    '&dateTo=' +
    endDate

  )
    .then(res => res.blob())
    .then(data => {
      // downloadBuddyLoanReportComplete
      var blob = new Blob([data], { type: 'application/vnd.ms-excel' })
      var excelBlob = new Blob(
        [data],
        { type: 'application/vnd.ms-excel' },
        'excel.xls'
      )
      var link = window.URL.createObjectURL(excelBlob)
      window.location = link
      var downloadUrl = URL.createObjectURL(blob)
      var a = document.createElement('a')
      a.href = downloadUrl
      a.download = 'buddyloanreport.xls'
      document.body.appendChild(a)
      a.click()
      callBack(data)
    })
}

export const downloadBuddyLoanReportApplied = (startDate, endDate, callBack) => {

  fetch(
    APIS.BUDDY_LOAN_APPLIED_DATA +
    startDate +
    '&dateTo=' +
    endDate

  )
    .then(res => res.blob())
    .then(data => {
      // downloadBuddyLoanReportApplied
      var blob = new Blob([data], { type: 'application/vnd.ms-excel' })
      var excelBlob = new Blob(
        [data],
        { type: 'application/vnd.ms-excel' },
        'excel.xls'
      )
      var link = window.URL.createObjectURL(excelBlob)
      window.location = link
      var downloadUrl = URL.createObjectURL(blob)
      var a = document.createElement('a')
      a.href = downloadUrl
      a.download = 'buddyloanreport.xls'
      document.body.appendChild(a)
      a.click()
      callBack(data)
    })
}

export const mobileVerifyApi = (userId, callBack) => {
  fetch(APIS.MOBILE_VERIFIY_API + userId)
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}

export const getCompanyPerformance = (startDate, endDate, callBack) => {
  fetch(APIS.GET_COMPANY_PERFORMANCE_COUNT + startDate + '&endDate=' + endDate)
    .then(res => res.json())
    .then(res => {
      callBack(res)
    })
}
export const deleteFacebookUserData = callBack => {
  fetch(APIS.DELETE_FACEBOOK_USER_DATA)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}
export const getRazorpayData = (startDate, endDate, callBack) => {

  fetch(
    APIS.GET_RAZORPAYDATA +
    startDate +
    '&dateTo=' +
    endDate

  )
    .then(res => res.blob())
    .then(data => {
      // getRazorpayData
      var blob = new Blob([data], { type: 'application/vnd.ms-excel' })
      var excelBlob = new Blob(
        [data],
        { type: 'application/vnd.ms-excel' },
        'excel.xls'
      )
      var link = window.URL.createObjectURL(excelBlob)
      window.location = link
      var downloadUrl = URL.createObjectURL(blob)
      var a = document.createElement('a')
      a.href = downloadUrl
      a.download = 'razorpaydata.xls'
      document.body.appendChild(a)
      a.click()
      callBack(data)
    })
}

export const userReferenceApi = (userId, callBack) => {
  var data = {
    // emailID: emailID
  }
  fetch(APIS.GET_USER_REFERENCE + userId, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}

export const getCompanyPerformanceDepartment = (startDate, endDate, department, filterType, callBack) => {
  fetch(APIS.GET_COMPANY_PERFORMANCE_COUNT_DEPARTMENT + startDate + '&endDate=' + endDate + '&department=' + department + '&filterType=' + filterType)
    .then(res => res.json())
    .then(res => {
      callBack(res)
    })
}

export const getdeactivateNachByLoanId = (loanId, callBack) => {
  fetch(APIS.GET_ENACH_DEACTIVATION_BY_LOANID + loanId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}

export const adminLoginApiBadaBazar = (adminId, Password, captcha, callBack) => {
  var data = {
    emailId: adminId,
    password: Password,
    captcha: captcha
  }

  fetch(APIS.ADMIN_LOGIN, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(json => {
      if (json && json.emailId) {
        LocalStrorageUtility.saveInLocalStorage('AdminemailId', json.emailId)
      }
      callBack(json)
    })


}

export const getAdminUserCheckBadaBazar = (adminId, callBack) => {
  var data = {
    emailId: adminId
  }
  fetch(APIS.GET_ADMIN_USER, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-api-key': 'AIzaSyB-9HOhts6vij-Lx0dNmB4KoJOfPSUN9oA'
    },
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(json => {
      if (json.responseCode == 0) {
      } else {
        if (json.status == 'failure') {
          LocalStrorageUtility.saveInLocalStorage('status', false)
          callBack(json)
        }
        LocalStrorageUtility.saveInLocalStorage('AdminemailId', json.emailId)
        LocalStrorageUtility.saveInLocalStorage('AdminRole', json.role)
        LocalStrorageUtility.saveInLocalStorage(
          'AdminRoleList',
          json.rolelist
        )
        LocalStrorageUtility.saveInLocalStorage('nbfcName', json.nbfcName)
        callBack(json)
      }
    })

}


export const downloadBydepartment = (startDate, endDate, department, callBack) => {

  fetch(
    APIS.DOWNLOAD_DEPARTMENTWISE_DATA +
    startDate +
    '&endDate=' +
    endDate +
    '&department='
    + department
  )
    .then(res => res.blob())
    .then(data => {
      // downloadBydepartment
      var blob = new Blob([data], { type: 'application/vnd.ms-excel' })
      var excelBlob = new Blob(
        [data],
        { type: 'application/vnd.ms-excel' },
        'excel.xls'
      )
      var link = window.URL.createObjectURL(excelBlob)
      window.location = link
      var downloadUrl = URL.createObjectURL(blob)
      var a = document.createElement('a')
      a.href = downloadUrl
      a.download = 'report.xls'
      document.body.appendChild(a)
      a.click()
      callBack(data)
    })
}
export const panProfileDetails = (data, callBack) => {
  fetch(APIS.GET_PAN_PROFILE_DETAILS + data)

    .then(res =>

      res.json()
    )
    .then(res => {
      callBack(res)
    })
    .catch(err => { callBack(null) })
  // .catch(e => callBack(e))


}

export const aadharVerificationDetails = (data, callBack) => {

  fetch(APIS.GET_AADHAR_VERIFICATION_DETAILS + data)
    .then(res => res.json())
    .then(res => {
      callBack(res)
    })
    .catch(error => {
      1
      callBack('')
    })
}
export const aadharXmlDetails = (data, callBack) => {

  fetch(APIS.GET_AADHAR_XML_DETAILS + data)
    .then(res => res.json())
    .then(res => {
      callBack(res)
    })
    .catch(err => { callBack(null) })


}
export const aadharXmlData = (data, callBack) => {
  fetch(APIS.GET_AADHAR_XML_DATA + data)
    .then(res => res.json())
    .then(res => {
      callBack(res)
    })
    .catch(e => callBack(e))

}
export const postCallPanDetails = (data, callBack) => {
  fetch(APIS.GET_PAN_PROFILE_POST_CALL + data, {
    method: 'POST',
    headers: POST_HEADER,
    // body: JSON.stringify(data)
  })
    .then(res => res.json()).catch(err => { callBack(null) })
    .then(json => {
      callBack(json)
    })

}
export const postCallDigilocker = (data, callBack) => {
  fetch(APIS.POST_CALL_DIGILOCKER + data, {
    method: 'POST',
    headers: POST_HEADER,
    // body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch(err => {
      callBack('')
    })

}
// export const postCallAdharVerification = (data, aadhaarNo, callBack) => {
//   fetch(APIS.POST_CALL_AADHAR_VERFICATION + data + '&aadhaar_no=' + aadhaarNo + '&source=' + 'admin', {
//     method: 'POST',
//     headers: POST_HEADER,
//     // body: JSON.stringify(data)
//   })
//     .then(res => res.json())
//     .then(json => {
//       callBack(json)
//     })
//     .catch(err => {
//       callBack('')
//     })

// }
export const postCallAdharVerification = (data, aadhaarNo, callBack) => {
  try {
    const startTime = performance.now();

    fetch(APIS.POST_CALL_AADHAR_VERFICATION + data + '&aadhaar_no=' + aadhaarNo + '&source=' + 'admin', {
      method: 'POST',
      headers: POST_HEADER,
    })
      .then(res => {
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }
        return res.json();
      })
      .then(json => {
        const endTime = performance.now();
        const responseTime = endTime - startTime;


        const milliseconds = responseTime
        const seconds = milliseconds / 1000;

        console.log('aadhaarNumberVerification api time=', seconds);
        callBack({ data: json });
      })
      .catch(err => {
        console.error('Error fetching data:', err);
        callBack({ responseTime: null, data: '' });
      });
  } catch (error) {
    console.error('Error in fetch request:', error);
    callBack({ responseTime: null, data: '' }); // Handle any errors that occur during fetch setup
  }
};


export const cardUtility = (data, callBack) => {
  var data = {
    creditAmount: Number(data.creditAmount),
    usedAmount: Number(data.usedAmount),
    creditProvidedDate: data.creditProvidedDate,
    creditUsedData: data.creditUsedData,
    currentDate: data.currentDate,
    convertToEmi: data.convertToEmi,
    loanEmi: Number(data.loanEmi),
    emiConversionDate: data.emiConversionDate,
    isMinmunDuePaid: data.isMinmunDuePaid
  }
  fetch(APIS.CARD_UTILITY, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(text => {
      callBack(text)
    })
}


export const loanLeadsManualAssigned = (
  status,
  count,
  empId,
  sDate,
  nbfcName,
  eDate,
  tabType,
  loanFrom,
  subStatus,
  callBack
) => {
  return dispatch => {

    fetch(
      APIS.GET_MANUAL_ASSIGNED_LEADS +
      status +
      '&pageNumber=' +
      count +
      '&empId=' +
      empId +
      '&date=' +
      sDate +
      '&nbfcName=' +
      nbfcName +
      '&eDate=' +
      eDate +
      '&tabType=' +
      tabType +
      '&loanFrom=' + loanFrom +
      '&subStatus=' + subStatus
    )
      .then(res => res.json())
      .then(json => {
        if (json.responseCode == 0) {
        } else {
          dispatch({ type: 'GET_LOAN_APPROVAL_LEAD', allPendingPayment: json })
          callBack(json)
        }
      })
  }
}

export const panAuthenticationApi = (userId, panNumber, callBack) => {
  fetch(APIS.PAN_AUTHENTICATION_API + userId + "&pan_number=" + panNumber + '&source=' + 'admin', {
    method: 'POST',
    headers: POST_HEADER,
    // body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch(e => callBack(null))
}
export const getCardLoanLeads = (
  status,
  count,
  empId,
  sDate,
  nbfcName,
  eDate,
  tabType,
  callBack
) => {
  return dispatch => {

    fetch(
      APIS.GET_CARD_LOAN_LEADS +
      status +
      '&pageNumber=' +
      count +
      '&empId=' +
      empId +
      '&date=' +
      sDate +
      '&nbfcName=' +
      nbfcName +
      '&eDate=' +
      eDate +
      '&tabType=' +
      tabType
    )
      .then(res => res.json())
      .then(json => {
        if (json.responseCode == 0) {
        } else {
          dispatch({ type: 'GET_LOAN_APPROVAL_LEAD', allPendingPayment: json })
          dispatch({
            type: 'GET_LOAN_APPROVAL_LEAD_GIFT_CARD',
            allPendingPayment: json
          })
          callBack(json)
        }
      })
  }
}

export const getUserDetailByLoanIdCard = (userId, loanId, callBack) => {
  fetch(APIS.USER_DETAIL_BY_LOANID_CARD + userId + '&loanId=' + '', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-api-key': 'AIzaSyB-9HOhts6vij-Lx0dNmB4KoJOfPSUN9oA'
    }
  })
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}

export const panProfileDetailsCards = (data, callBack) => {
  fetch(APIS.GET_PAN_PROFILE_DETAILS_CARDS + data)

    .then(res =>

      res.json()
    ).catch(err => { callBack(null) })
    .then(res => {
      callBack(res)
    })
  // .catch(e => callBack(e))


}
export const getCardLoanDetailByUserId = (userId, callBack) => {
  fetch(APIS.GET_CARD_LOAN_DETAIL_BY_USER_ID + userId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}
export const getTransactionCards = (userId, callBack) => {
  fetch(APIS.GET_TRANSACTIONS_CARDS + userId)
    .then(json => json.json())
    .then(res => {
      callBack(res)
    })
}

export const bankVerificationApi = (ifsc, accountNumber, accountHolderName, userId, callBack) => {
  fetch(APIS.BANK_VERIFICATION_API + ifsc + "&accountNumber=" + accountNumber + '&accountHolderName=' + accountHolderName + '&nameMatchType=' + '' + '&userId=' + userId, {
    method: 'POST',
    headers: POST_HEADER,
    // body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch(e => callBack('null'))
}

export const newEsignApiCards = (userId, esignType, callBack) => {
  fetch(APIS.ESIGN_AND_ENACH_INITIATE_NEW_CARDS + userId + '&esignType=' + esignType, {
    method: 'POST',
    headers: POST_HEADER,
    // body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}


export const widgetapiCards = (userId, token, docketid, esignType, callBack) => {
  fetch(APIS.WIDGET_API_CARDS + userId + "&widgetToken=" + token + "&docketId=" + docketid + '&esignType=' + esignType)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}

export const docketApiCards = (data, esignType, callBack) => {
  fetch(APIS.GET_DOCKET_INFO_CARDS + data + '&esignType=' + esignType, {
    method: 'POST',
    headers: POST_HEADER,
    // body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}
export const signatureApiCards = (data, loanId, esignType, callBack) => {
  fetch(APIS.GET_SIGNATURE_STATUS_CARDS + data + '&userId=' + loanId + '&esignType=' + esignType, {
    method: 'POST',
    headers: POST_HEADER,
    // body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}
export const getAllTeaGardenApi = (pageNumber, entry, callBack) => {
  fetch(APIS.GET_ALL_TEA_GARDEN_LIST + pageNumber + '&noOfEntry=' + entry)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    }
    )

}


export const approveLoanApiTeaGarden = (
  allDetail,
  status,
  reason,
  approveData,
  mail,
  score,
  empId,
  paybackDates,
  nbfcName,
  teaGardenId,
  callBack
) => {
  var data = {
    userId: allDetail.userId,
    loanId: allDetail.loanId,
    loanStatus: status,
    loanReason: reason,
    approveDate: approveData.approveDate,
    loanEmi: approveData.selectEMI,
    loanPayBackDate: '',
    amount: approveData.ApproveAmount,
    mail: mail,
    score: score,
    empId: empId,
    paybackDates: paybackDates,
    nbfcName: nbfcName,
    nbfcId: approveData.nbfcId,
    subStatus: approveData.subStatus,
    teaGardenId: teaGardenId
  }

  fetch(APIS.LOAN_SANCTION, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}
export const calculateEmiNbfcApiTea = (
  amount,
  months,
  salaryDay,
  nbfcName,
  callBack
) => {
  fetch(
    APIS.EMI_CALC_WITH_NBFC +
    amount +
    '/' +
    months +
    '/' +
    salaryDay +
    '/' +
    nbfcName
    + '?type=' + 'tea'
  )
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}
export const calculateEmiNbfcApiTeaStaff = (
  amount,
  months,
  salaryDay,
  nbfcName,
  callBack
) => {
  fetch(
    APIS.EMI_CALC_WITH_NBFC +
    amount +
    '/' +
    months +
    '/' +
    salaryDay +
    '/' +
    nbfcName
    + '?type=' + 'teaStaff'
  )
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}


export const panVerificationApiKarza = (data, panNumber, callBack) => {
  fetch(APIS.PAN_VERIFICATION_KARZA + data + '&pan_number=' + panNumber + '&source=' + 'admin', {
    method: 'POST',
    headers: POST_HEADER,
    // body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}

export const getgiftCardLeadTeaGarden = (
  status,
  count,
  empId,
  sDate,
  nbfcName,
  eDate,
  tabType,
  teagardenId,
  employeeId,
  callBack
) => {
  return dispatch => {

    fetch(
      APIS.TEA_GARDEN_GIFT_CARD_LEADS +
      status +
      '&pageNumber=' +
      count +
      '&empId=' +
      empId +
      '&date=' +
      sDate +
      '&nbfcName=' +
      nbfcName +
      '&eDate=' +
      eDate +
      '&tabType=' +
      tabType +
      '&teaGardenId=' + teagardenId + '&employeeId=' + employeeId
    )
      .then(res => res.json())
      .then(json => {
        if (json.responseCode == 0) {
        } else {
          dispatch({ type: 'GET_LOAN_APPROVAL_LEAD', allPendingPayment: json })
          dispatch({
            type: 'GET_LOAN_APPROVAL_LEAD_GIFT_CARD',
            allPendingPayment: json
          })
          callBack(json)
        }
      })
  }
}

export const getDBRcalculationApi = (userId, loanId, callBack) => {
  fetch(APIS.GET_DBR_CALCULATION_DETAILS_BY_USER_ID + userId + '&loanId=' + loanId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch(err => {
      callBack(null)
    })
}

export const saveDbrDataApi = (
  internalData,
  allDetail,
  callBack
) => {
  var data = {
    stability: Number(internalData.stability),
    residence: Number(internalData.residence),
    debts: Number(internalData.debts),
    multiplePayday: Number(internalData.multiplePayday),
    overDueMsgsTrend: Number(internalData.overDueMsgsTrend),
    bureau: Number(internalData.bureau),
    corporate: Number(internalData.corporate),
    access: Number(internalData.access),
    // salary: allDetail.employmentDetailsPO.monthlyIncome),
    // salary:Number(internalData.salary),
    // obligations:Number(internalData.obligations),
    ntc: Number(internalData.ntc),
    kyc: Number(internalData.kyc),
    userId: allDetail.userId,
    loanId: allDetail.loanId
  }
  fetch(APIS.SAVE_DBR_SCORES, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(text => {
      callBack(text)
    })
}
export const getallDBRdataApi = (userId, callBack) => {
  fetch(APIS.GET_DBR_ALL_DATA + userId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch(err => {
      callBack(null)
    })
}


export const getRepeatAutomationByUserId = (userId, loanId, empId, callBack) => {
  fetch(APIS.GET_REPEAT_AUTOMATION_USER_ID + userId + '&loanId=' + loanId + '&empId=' + empId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch(err => {
      callBack(null)
    })
}

export const uploadStampDutyApiAll = (
  uploadfile,
  empId,
  callBack
) => {
  var self = this
  return new Promise((resolve, reject) => {
    var self = this
    let imageFormData = new FormData()
    // for (let i = 0; i < uploadfile.length; i++) {
    imageFormData.append('pdf', uploadfile)
    imageFormData.append('empId', empId)

    // }

    var xhr = new XMLHttpRequest()
    xhr.open('post', APIS.UPLOAD_STAMP_DUTY_ALL, true)
    xhr.setRequestHeader('x-api-key', 'AIzaSyB-9HOhts6vij-Lx0dNmB4KoJOfPSUN9oA')
    xhr.onload = function () {
      if (this.status == 200) {
        resolve(this.response)
        callBack(this.response)
      } else {
        reject(this.statusText)
      }
    }
    xhr.send(imageFormData)
  })
}


export const uploadedStampDuty = (
  usedStatus,
  pageNo,
  noOfEntry,
  initiateEsign,
  callBack
) => {
  fetch(APIS.GET_STAMP_DUTY + usedStatus + '&pageNo=' + pageNo + '&noOfEntry=' + noOfEntry + '&initiateEsign=' + initiateEsign)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}

export const uploadedStampDutyCount = (usedStatus, initiateEsign, callBack) => {
  fetch(APIS.GET_STAMP_DUTY_COUNT + usedStatus + '&initiateEsign=' + initiateEsign)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })

}
export const triggerKycApi = (userDetails, callBack) => {
  fetch(APIS.KYC_FORM_APP + userDetails.loanId + '&userId=' + userDetails.userId + '&kycStatus=' + true)
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}


export const bulkAssignViaCSV = (csvFile, callBack) => {
  return new Promise((resolve, reject) => {
    let imageFormData = new FormData()
    imageFormData.append('file', csvFile)
    // imageFormData.append('type', type);
    //imageFormData.append('empId', admin.emailId);
    var xhr = new XMLHttpRequest()
    xhr.open('post', APIS.BULK_ASSIGN_VIA_CSV, true)
    xhr.onload = function () {
      if (this.status == 200) {
        resolve(this.response)
        callBack(this.response)
      } else {
        reject(this.statusText)
      }
    }
    xhr.send(imageFormData)
  })
}


export const saveOrUpdateBasicDetailsAdmin = (
  staffToSend,
  userData,
  staff,
  othersData,
  teaGardenId,
  userId,
  teaGardenName,
  callBack
) => {
  var dateOfBirthToSend = userData.dateOfBirth !== null ? userData.dateOfBirth.split("-").reverse().join("-") : null
  var dateOfJoining = userData.dateOfJoining !== null ? userData.dateOfJoining.split("-").reverse().join("-") : null

  var data = {
    employeeId: userData.empId !== undefined && userData.empId !== '' ? userData.empId : '',
    name: userData.name,
    gender: userData.gender,
    pan: userData.panNumber,
    aadhaar: userData.aadharNumber,
    // teaGardenId: "kCV6VBiWe9FlUQTuiBuBoASA7NsgkRAs",
    teaGardenId: teaGardenId,
    pinCode: Number(userData.pinCode),
    dateOfBirth: dateOfBirthToSend,
    ifBankAccount: userData.ifBankAccount,
    ageOfEmployment: userData.ageOfEmployment,
    employmentType: userData.employmentType,
    mobileNumber: userData.mobileNumber,
    address: userData.address,
    doj: dateOfJoining,
    staff: staffToSend,
    teaGardenName: teaGardenName,
    teaGardenComment: othersData.teaGardenComment,
    userId: userId

  }

  fetch(APIS.SAVE_OR_UPDATE_BASIC_DETAILS_TEA_FLOW, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}

export const changeEmployeeIdApi = (
  teaGardenId,
  oldEmpId,
  newEmpId,
  staff,
  callBack
) => {
  fetch(APIS.CHANGE_EMPID_TEA_GARDEN + teaGardenId + '&oldEmpId=' + oldEmpId + '&newEmpId=' + newEmpId + '&staff=' + staff)
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}


export const getBranchDataApi = (startDate, endDate, type, callBack) => {

  fetch(
    APIS.GET_BRANCH_DATA +
    startDate +
    '&eDate=' +
    endDate +
    '&type=' + type

  )
    .then(res => res.blob())
    .then(data => {
      // getBranchDataApi
      var blob = new Blob([data], { type: 'application/vnd.ms-excel' })
      var excelBlob = new Blob(
        [data],
        { type: 'application/vnd.ms-excel' },
        'excel.xls'
      )
      var link = window.URL.createObjectURL(excelBlob)
      window.location = link
      var downloadUrl = URL.createObjectURL(blob)
      var a = document.createElement('a')
      a.href = downloadUrl
      a.download = 'branchdata.xls'
      document.body.appendChild(a)
      a.click()
      callBack(data)
    })
}
export const razorpayTransferApi = (amount, callBack) => {
  fetch(APIS.PAYMENT_TRANSFER_API_RAZORPAY + amount, {
    method: 'POST',
    headers: POST_HEADER,
    // body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })

}
export const getRazorpayTransaction = (noOfEntry, pageNumber, callBack) => {
  fetch(APIS.GET_RAZORPAY_TRANSACTION + noOfEntry + '&pageNo=' + pageNumber)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch(err => {
      callBack(null)
    })
}
export const saveNbfcApi = (
  nbfcdata,
  callBack
) => {
  var data = {
    id: nbfcdata.id,
    name: nbfcdata.name,
    maxDays: nbfcdata.maxDays,
    maxMonth: nbfcdata.maxMonth,
    interestRate: nbfcdata.interestRate,
    nbfcId: nbfcdata.nbfcId,
    columnsAllowed: nbfcdata.columnsAllowed,
    apiKey: nbfcdata.apiKey,
    logoUrl: nbfcdata.logoUrl,
    dynamicEmi: nbfcdata.dynamicEmi == 'true' ? true : nbfcdata.dynamicEmi == 'false' ? false : '',
    contactPersonName: nbfcdata.contactPersonName,
    contactPersonNumber: nbfcdata.contactPersonNumber,
    city: nbfcdata.city,
    pinCode: nbfcdata.pinCode,
    state: nbfcdata.state,
    address: nbfcdata.address


  }
  fetch(APIS.SAVE_NBFC, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}

export const uploadNbfcLogo = (imageFile, name, callBack) => {
  var self = this
  return new Promise((resolve, reject) => {
    var self = this
    let imageFormData = new FormData()
    imageFormData.append('nbfcLogo', imageFile)


    var xhr = new XMLHttpRequest()
    xhr.open('post', APIS.UPLOAD_NBFC_LOGO + name, true)
    xhr.onload = function () {
      if (this.status == 200) {
        resolve(this.response)
        callBack(this.response)
      } else {
        reject(this.statusText)
      }
    }
    xhr.send(imageFormData)
  })
}

export const saveProductConfigNbfc = (
  nbfcdata,
  callBack
) => {
  var data = {
    id: nbfcdata.id,
    nbfcId: nbfcdata.nbfcId,
    roiReflectDate: _formatDateInDash(nbfcdata.roiReflectDate),
    previouslyAffect: nbfcdata.previouslyAffect === 'true' ? true : nbfcdata.previouslyAffect === 'false' ? false : '',
    stampDutyCharge: nbfcdata.stampDutyCharge,
    penaltyInterest: nbfcdata.penaltyAmount,
    penaltyAmount: nbfcdata.penaltyAmount,
    normalInterest: nbfcdata.normalInterest,
    emiPenaltyInterest: nbfcdata.emiPenaltyInterest,
    gstInterest: nbfcdata.gstInterest,
    chequeBounceCharge: nbfcdata.chequeBounceCharge,
    mandateBounceCharge: nbfcdata.mandateBounceCharge,
    interestType: nbfcdata.interestType,
    gstIncluded: nbfcdata.gstIncluded === 'true' ? true : nbfcdata.gstIncluded === 'false' ? false : '',
    emiType: nbfcdata.emiType,
    processingType: nbfcdata.processingType



  }
  fetch(APIS.NBFC_PRODUCT_CONFIG_SAVE, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}

export const getNbfcProductByNbfcId = (
  nbfcId,
  pageNo,
  noOfEntry,
  callBack
) => {
  fetch(APIS.GET_PRODUCT_BY_NBFC_ID + nbfcId + '&pageNo=' + pageNo + '&noOfEntry=' + noOfEntry)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}
export const saveChargesConfigNbfc = (
  nbfcdata,
  callBack
) => {
  var data = {
    id: nbfcdata.id,
    nbfcId: nbfcdata.nbfcId,
    gstIncluded: nbfcdata.gstIncluded === 'true' ? true : nbfcdata.gstIncluded === 'false' ? false : '',
    processingType: nbfcdata.processingType,
    processingFee: nbfcdata.processingFee,
    minLoanAmount: nbfcdata.minLoanAmount,
    maxLoanAmount: nbfcdata.maxLoanAmount,
    effectingFrom: _formatDateInDash(nbfcdata.effectingFrom),
    oldEffected: nbfcdata.oldEffected === 'true' ? true : nbfcdata.oldEffected === 'false' ? false : '',
    noOfEmi: nbfcdata.noOfEmi


  }
  fetch(APIS.SAVE_PROCESSING_CHARGES, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}
export const getNbfcChargesByNbfcId = (
  nbfcId,
  pageNo,
  noOfEntry,
  callBack
) => {
  fetch(APIS.GET_NBFC_CHARGES + nbfcId + '&pageNo=' + pageNo + '&noOfEntry=' + noOfEntry)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}
export const saveEsignConfigNbfc = (
  nbfcdata,
  callBack
) => {
  var data = {
    id: nbfcdata.id,
    nbfcId: nbfcdata.nbfcId,
    eSignRequestUrl: nbfcdata.eSignRequestUrl,
    widgetTokenUrl: nbfcdata.widgetTokenUrl,
    docketInfoUrl: nbfcdata.docketInfoUrl,
    signatureStatusUrl: nbfcdata.signatureStatusUrl,
    apiKey: nbfcdata.apiKey,
    appId: nbfcdata.appId,
    status: nbfcdata.status

  }
  fetch(APIS.SAVE_ESIGN_CONFIG, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}

export const getEsignByNbfcId = (
  nbfcId,
  pageNo,
  noOfEntry,
  callBack
) => {
  fetch(APIS.GET_ESIGN_CONFIG + nbfcId + '&pageNo=' + pageNo + '&noOfEntry=' + noOfEntry)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}
export const saveEnachConfigNbfc = (
  nbfcdata,
  callBack
) => {
  var data = {
    id: nbfcdata.id,
    nbfcId: nbfcdata.nbfcId,
    apiKey: nbfcdata.apiKey,
    apiSecret: nbfcdata.apiSecret,


  }
  fetch(APIS.SAVE_ENACH_CONFIG, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}
export const getEnachByNbfcId = (
  nbfcId,
  pageNo,
  noOfEntry,
  callBack
) => {
  fetch(APIS.GET_ENACH_CONFIG + nbfcId + '&pageNo=' + pageNo + '&noOfEntry=' + noOfEntry)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}
export const saveuserRefApi = (data, userId, callBack) => {
  var data = data
  fetch(APIS.USER_REFERENCE_SAVE_API + userId, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}

export const createBillDeskOrderApi = (
  loanId,
  callBack
) => {
  fetch(APIS.CREATE_BILL_DESK_ORDER + loanId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}
export const saveMandateDetailsBillDesk = (
  data,
  callBack
) => {

  fetch(APIS.SAVE_MANDATE_DETAILS_BILL_DESK, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}

export const createPaymentLink = (
  loanId,
  userId,
  emiId,
  amount,
  callBack
) => {
  fetch(APIS.CREATE_PAYMENT_LINK + loanId + '&userId=' + userId + '&emiId=' + emiId + '&amount=' + amount)
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}
export const getDetailsByLinkId = (
  linkId,
  callBack
) => {
  fetch(APIS.CREATE_PAYMENT_BY_LINK_ID + linkId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}
export const savePaymentDetailsBillDesk = (
  data,
  callBack
) => {

  fetch(APIS.SAVE_PAYMENT_DETAILS_BILL_DESK, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}
export const getPaymentByLinkId = (
  linkId,
  callBack
) => {
  fetch(APIS.GET_PAYMENT_DETAILS_BY_LINKID + linkId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}
export const getApiCostindDataApi = (
  callBack
) => {
  fetch(APIS.GET_API_COSTING_DATA)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}
export const updateCostingApi = (
  data,
  callBack
) => {

  fetch(APIS.UPDATE_COSTING, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}
export const getThirdParyHitData = (
  startDate, endDate,
  callBack
) => {
  fetch(APIS.GET_THIRD_PARTY_HIT_DATA + startDate + '&endDate=' + endDate)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}
export const getBillDeskTxn = (
  loanId,
  callBack
) => {
  fetch(APIS.GET_BILL_DESK_TXN_BY_LOAN_ID + loanId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const saveBuisnessDataManually = (
  buisnessData,
  callBack
) => {
  var data = {
    id: buisnessData.id,
    addedOn: buisnessData.addedOn,
    amountDisbursed: buisnessData.amountDisbursed,
    collectionAmount: buisnessData.collectionAmount,
    disbursedLeads: buisnessData.disbursedLeads,
    collectionLeads: buisnessData.collectionLeads,
    approvedLeads: buisnessData.approvedLeads,
    approvedAmount: buisnessData.approvedAmount,
    dataDate: buisnessData.dataDate,
    closedLoan: buisnessData.closedLoan

  }
  fetch(APIS.SAVE_BUISNESS_DATA_MANUAL,
    {
      method: 'POST',
      headers: POST_HEADER,
      body: JSON.stringify(data)
    })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}

export const getBuisnessData = (
  callBack
) => {
  fetch(APIS.GET_BUISNESS_DATA_MANUAL)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const getBuisnessReportList = (
  pageNo, noOfEntry,
  callBack
) => {
  fetch(APIS.GET_BUISNESS_REPORT_LIST + pageNo + '&noOfEntry=' + noOfEntry)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const nachReportDataApiBilldesk = (pageNo, noOfEntry, date, emailId, callBack) => {
  fetch(APIS.GET_BILLDESK_NACH_REPORT + pageNo + '&noOfEntry=' + noOfEntry + '&date=' + date + '&emailId=' + emailId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch(error => callBack(error))
}
export const getBranchDataApiConsolidated = (startDate, endDate, type, callBack) => {

  fetch(
    APIS.DOWNLOAD_BRANCH_REPORT + startDate + '&dateTo=' + endDate)
    .then(res => res.blob())
    .then(data => {
      // getBranchDataApi
      var blob = new Blob([data], { type: 'application/vnd.ms-excel' })
      var excelBlob = new Blob(
        [data],
        { type: 'application/vnd.ms-excel' },
        'excel.xls'
      )
      var link = window.URL.createObjectURL(excelBlob)
      window.location = link
      var downloadUrl = URL.createObjectURL(blob)
      var a = document.createElement('a')
      a.href = downloadUrl
      a.download = 'branchdata.xls'
      document.body.appendChild(a)
      a.click()
      callBack(data)
    })
}

export const getFidyPayTrnSApi = (
  loanId,
  callBack
) => {
  fetch(APIS.GET_FIDY_PAY_TRANSACTION_DETAILS + loanId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const saveEmailVerificationGenerateReportApi = (data, admin, callBack) => {
  var data = {
    empId: admin.emailId,
    columnsNameList: data.reportFields,
    startDate: data.applyDateFromState,
    endDate: data.applyDateToState,
    emailID: 'mohit.bansal@phocket.in'
  }
  fetch(APIS.SAVE_EMAIL_VERIFICATION_API, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}
export const verifyOtp = (
  empId,
  code,
  callBack
) => {
  fetch(APIS.CODE_VRIFY_MAIL + empId + '/' + code)
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const veriFyReferenceDetails = (userId, status, mobileNo, type, Id, loanId, callBack) => {
  fetch(APIS.VERIFY_REFRENCE_DETAILS + userId + '&status=' + status + '&mobileNo=' + mobileNo + '&type=' + type + '&empId=' + Id + '&loanId=' + loanId)
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}
export const getNbfcBankCodeByBankNameApi = (
  bankName,
  callBack
) => {
  fetch(APIS.GET_NBFC_BANK_CODE_BY_BANK_NAME + bankName)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}
export const createMandateSigndesk = (
  loanId,
  userId,
  authenticationMode,
  bankCode,
  type,
  callBack
) => {
  fetch(APIS.SIGN_DESK_MANDATE + loanId + '&userId=' + userId + '&authenticationMode=' + authenticationMode + '&bankCode=' + bankCode + '&netBankingMode=' + type)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}
export const getCreditLeadsApi = (
  pageNo,
  count,
  type,
  sDate, eDate,
  callBack
) => {
  fetch(APIS.GET_CREDIT_LEADS_API + pageNo + '&count=' + count + '&type=' + type + '&sDate=' + sDate + '&eDate=' + eDate)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}
export const getAnalysisTableCount = (
  sDate,
  eDate,
  callBack
) => {
  fetch(APIS.GET_ANALYSIS_COUNT_API + sDate + '&eDate=' + eDate)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}

export const saveOrUpdatePolicyDetails = (data, callBack) => {

  fetch(APIS.SAVE_OR_UPDATE_POLICY_DETAILS, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}
export const aadhaarConsentUser = (userId, name, callBack) => {
  fetch(APIS.AADHAAR_CONSENT_API + userId + '&name=' + name + '&source=' + 'admin', {
    method: 'POST',
    headers: POST_HEADER,
    // body: JSON.stringify(data)
  })
    .then(res => res.json()).catch(err => { callBack(null) })
    .then(json => {
      callBack(json)
    })

}
export const aadhaarConsentUserWithoutSource = (userId, name, callBack) => {
  fetch(APIS.AADHAAR_CONSENT_API + userId + '&name=' + name, {
    method: 'POST',
    headers: POST_HEADER,
    // body: JSON.stringify(data)
  })
    .then(res => res.json()).catch(err => { callBack(null) })
    .then(json => {
      callBack(json)
    })

}

export const InitiateaadhaarConsentUser = (userId, aadaharNumber, callBack) => {
  fetch(APIS.INITIATE_AADHAAR_CONSENT + userId + '&type=aadhaar' + '&aadhaar_no=' + aadaharNumber + '&source=' + 'admin', {
    method: 'POST',
    headers: POST_HEADER,
    // body: JSON.stringify(data)
  })
    .then(res => res.json()).catch(err => { callBack(null) })
    .then(json => {
      callBack(json)
    })

}
export const getAadhaarOtp = (userId, aadaharNumber, callBack) => {
  fetch(APIS.GET_AADHAAR_OTP + userId + '&aadhaar_no=' + aadaharNumber + '&source=' + 'admin', {
    method: 'POST',
    headers: POST_HEADER,
    // body: JSON.stringify(data)
  })
    .then(res => res.json()).catch(err => { callBack(null) })
    .then(json => {
      callBack(json)
    })

}
export const shareCodeOtp = (userId, otp, share_code, callBack) => {
  fetch(APIS.SHARE_CODE_API + userId + '&otp=' + otp + '&share_code=' + share_code + '&source=' + 'admin', {
    method: 'POST',
    headers: POST_HEADER,
    // body: JSON.stringify(data)
  })
    .then(res => res.json()).catch(err => { callBack(null) })
    .then(json => {
      callBack(json)
    })

}


export const getUserData = (
  userId,
  callBack
) => {
  fetch(APIS.GET_USER_DATA + userId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}
export const updateKyc = (
  userId,
  panNo,
  aadhaarNo,
  aadhaarLink,
  callBack
) => {
  fetch(APIS.UPDATE_KYC_DETAILS + userId + '&panNo=' + panNo + '&aadhaarNo=' + aadhaarNo + '&isAadharLinked=' + aadhaarLink)
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}

export const getPanverifiedStatusForKyc = (
  userId,
  callBack
) => {
  fetch(APIS.GET_PAN_NOTIFY + userId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const saveOrUpdateCreditConfigApi = (data, callBack) => {

  fetch(APIS.SAVE_CREDIT_CONFIG, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}
export const getConfigDataApi = (
  pageNo,
  count,
  callBack
) => {
  fetch(APIS.GET_CONFIGURATION + pageNo + '&count=' + count)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const saveMappingOfPolicy = (data, callBack) => {

  fetch(APIS.SAVE_MAPPING, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}
export const getMappedDataApi = (
  pageNo,
  count,
  policyId,
  callBack
) => {
  fetch(APIS.GET_MAPPED_DATA + pageNo + '&count=' + count + '&policyId=' + policyId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const deleteMappingapi = (
  id,
  callBack
) => {
  fetch(APIS.DELETE_MAPPING_API + id)
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const deletePolicyByPolicyId = (
  id,
  callBack
) => {
  fetch(APIS.DETELE_POLICY_API + id)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const deleteAttributeByCreditAttId = (
  id,
  callBack
) => {
  fetch(APIS.DETELE_ATTRIBUTE_API + id)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const GetLoanCountByDate = (
  startdate,
  enddate,
  callBack
) => {
  fetch(APIS.GET_LOAN_COUNT_BY_DATE + '?startDate=' + startdate + '&endDate=' + enddate)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const downloadApiTrackerFile = (startDate, endDate, type, callBack) => {
  const queryParams = `sDate=${startDate}&eDate=${endDate}&type=${type}`;

  let url = '';
  if (type === 'Crif' || type === 'FinBit Pdf Upload') {
    url = `${APIS.DOWNLOAD_API_FILE}?${queryParams}`;
  } else {
    url = `${APIS.DOWNLOAD_KARZA_REPORT_BY_TYPE}?${queryParams}`;
  }
  fetch(url, {
    method: 'GET',
    headers: POST_HEADER,
  })
    .then((res) => res.blob())
    .then((data) => {
      var blob = new Blob([data], { type: 'application/vnd.ms-excel' });
      var downloadUrl = URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = downloadUrl;
      a.download = 'report.xls';
      document.body.appendChild(a);
      a.click();
      callBack(data);
    });
};

export const saveDeviationPoint = (data, callBack) => {
  var data = {
    clarification: data,

  }
  fetch(APIS.SAVE_DEVIATION_POINT, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}

export const getConcludeDataApiForScoring = (
  userId,
  loanId,
  callBack
) => {
  fetch(APIS.GET_CONCLUDED_DATA + userId + '&loanId=' + loanId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const getUserDetailByLoanIdOptimisedApi = (loanId, userId, callBack) => {
  fetch(APIS.USER_DETAIL_BY_LOANID_OPTIMISED + loanId + '&userId=' + userId, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-api-key': 'AIzaSyB-9HOhts6vij-Lx0dNmB4KoJOfPSUN9oA'
    }
  })
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}
export const getLoanDetailByUserIdOptimised = (userId, callBack) => {
  fetch(APIS.GET_LOAN_DETAIL_BY_USERID_OPTIMISED + userId + '&type=' + 'All')
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}
export const getLoanApprovalLeadOptimized = (
  status,
  count,
  empId,
  sDate,
  nbfcName,
  eDate,
  tabType,
  loanFrom,
  subStatus,
  teaGardenId,
  teaGardenEmpId,
  dateType,
  callBack
) => {
  return dispatch => {

    fetch(
      APIS.GET_LOAN_LEADS_NEW_API +
      status +
      '&pageNumber=' +
      count +
      '&empId=' +
      empId +
      '&date=' +
      sDate +
      '&nbfcName=' +
      nbfcName +
      '&eDate=' +
      eDate +
      '&tabType=' +
      tabType +
      '&loanFrom='
      + loanFrom +
      '&subStatus='
      + subStatus
      + '&teaGardenId='
      + teaGardenId
      + '&teaGardenEmpId='
      + teaGardenEmpId
      + '&dateType=' + dateType
    )
      .then(res => res.json())
      .then(json => {
        if (json.responseCode == 0) {
        } else {
          dispatch({ type: 'GET_LOAN_APPROVAL_LEAD_OPTIMIZED', allPendingPayment: json })
          callBack(json)
        }
      })
  }
}
export const getManagementLeadApiOptimized = (
  type,
  status,
  count,
  empId,
  date,
  commentType,
  salaryRange,
  bucketStage,
  callBack
) => {
  return dispatch => {
    fetch(
      APIS.LEAD_MANAGEMENT_LEADS_API +

      status +
      '&salaryRange=' + salaryRange +
      '&pageNumber=' +
      count +
      '&empId=' +
      empId +
      '&date=' +
      date +
      '&type=' +
      type +
      '&commentType=' + commentType +
      '&eDate=' +
      date +
      '&tabType=' +
      '&bucketStage=' + bucketStage +
      '&dateType=' + 'apply'
    )
      .then(res => res.json())
      .then(json => {
        if (json.responseCode == 0) {
        } else {
          dispatch({ type: 'GET_LEAD_MANAGEMENT_OPTIMIZED', LeadManagementData: json })
          callBack(json)
        }
      })
  }
}
export const uploadFileNewImage = (imageFile, type, fileName, userId, callBack) => {
  var self = this
  return new Promise((resolve, reject) => {
    var self = this
    let imageFormData = new FormData()
    // for (let i = 0; i < imageFile.length; i++) {
    imageFormData.append('uploadfile', imageFile)
    // }
    imageFormData.append('userId', userId)
    imageFormData.append('type', 'img')
    imageFormData.append('fileName', type)
    var xhr = new XMLHttpRequest()
    xhr.open('post', APIS.UPLOAD_FILE, true)
    xhr.onload = function () {
      if (this.status == 200) {
        resolve(this.response)
        callBack(this.response)
      } else {
        reject(this.statusText)
      }
    }
    xhr.send(imageFormData)
  })
}

export const uploadPdfNew = (
  pdfFile,
  type,
  fileName,
  password,
  userId,
  callBack
) => {
  var self = this
  return new Promise((resolve, reject) => {
    var self = this
    let imageFormData = new FormData()
    // for (let i = 0; i < pdfFile.length; i++) {
    imageFormData.append('uploadfile', pdfFile)
    // }
    imageFormData.append('userId', userId)
    imageFormData.append('type', 'pdf')
    imageFormData.append('fileName', type)
    imageFormData.append('password', password)
    var xhr = new XMLHttpRequest()
    xhr.open('post', APIS.UPLOAD_FILE, true)
    xhr.onload = function () {
      if (this.status == 200) {
        resolve(this.response)
        callBack(this.response)
      } else {
        reject(this.statusText)
        callBack(this.response)
      }
    }
    xhr.send(imageFormData)
  })
}
export const passwordCheckNew = (pdfFile, callBack) => {
  return new Promise((resolve, reject) => {
    var self = this
    let imageFormData = new FormData()
    // for (let i = 0; i < pdfFile.length; i++) {
    imageFormData.append('uploadfile', pdfFile)
    // }
    var xhr = new XMLHttpRequest()
    xhr.open('post', APIS.DOC_PASSWORD_PROTECT, true)
    xhr.onload = function () {
      if (this.status == 200) {
        resolve(this.response)
        callBack(this.response)
      } else {
        reject(this.statusText)
        callBack(this.response)
      }
    }
    xhr.send(imageFormData)
  })
}
export const getCommentsApi = (type,
  status,
  count,
  empId,
  date,
  commentType,
  salaryRange,
  bucketId,
  callBack) => {
  fetch(APIS.GET_COMMENTS_API + status +
    '&salaryRange=' + salaryRange +
    '&pageNumber=' +
    count +
    '&empId=' +
    empId +
    '&date=' +
    date +
    '&type=' +
    type +
    '&commentType=' + commentType +
    '&eDate=' +
    date +
    '&tabType=' +
    '&bucketStage=' + bucketId +
    '&dateType=' + 'apply')
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}
export const newActivityLogsApi = (loanId, callBack) => {
  fetch(APIS.NEW_ACTIVITY_LOGS_API + loanId)
    .then(res => res.json())
    .then(res => {
      callBack(res)
    })
}
export const getOperationChcekDataByUserId = (userId, callBack) => {
  fetch(APIS.GET_OPERATION_CHECK_DATA + userId)
    .then(res => res.json())
    .then(res => {
      callBack(res)
    })
}
export const getOperationFlowByUserId = (userId, loanId, callBack) => {
  fetch(APIS.GET_OPERATION_FLOW + userId + '&loanId=' + loanId)
    .then(res => res.json())
    .then(res => {
      callBack(res)
    })
}
export const qualityCheckApi = (userDetails, qualityCheck, empID, callBack) => {
  fetch(APIS.QUALITY_CHECK_API + userDetails.loanId + '&userId=' + userDetails.userId + '&qualityCheck=' + qualityCheck + '&empId=' + empID)
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}

export const recentActivitiesApi = (loanId, callBack) => {
  fetch(APIS.NEW_ACTIVITY_LOGS_API_SHORT + loanId)
    .then(res => res.json())
    .then(res => {
      callBack(res)
    })
}
export const getLeadManagmentCountsApi = (empId, date, type, eDate, bucketStage, callBack) => {
  fetch(APIS.LEAD_MANAGEMENT_COUNT + empId + '&date=' + date + '&type=' + type + '&eDate=' + eDate + '&bucketStage=' + bucketStage)
    .then(res => res.json())
    .then(res => {
      callBack(res)
    })
}
export const getDashboardData = (startDate, endDate, department, filterType, callBack) => {
  fetch(APIS.GET_DASHBOARD_GRAPH_LM + startDate + '&endDate=' + endDate + '&department=' + department + '&filterType=' + filterType)
    .then(res => res.json())
    .then(res => {
      callBack(res)
    })
}
export const getEmailsByDepartment = (list, callBack) => {

  fetch(APIS.GET_EMAIL_ID_VIA_DEPARTMENT, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(list)
  })
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}
export const saveAssignByStatusAPINew = (status, emailId, admin, salaryBucket, callBack) => {
  var data = {
    status: status,
    emailId: emailId,
    empId: admin.emailId,
    salaryBucket: salaryBucket,
  }
  fetch(APIS.SAVE_ASSIGN_BY_STATUS, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}
export const deleteAssignedEntryApi = (id, callBack) => {
  fetch(APIS.DELETE_ASSIGNED_ENTRY + id)
    .then(res => res.text())
    .then(res => {
      callBack(res)
    })
}
export const saveAssignByStatusAPINewLeave = (status, emailId, emplId, salaryBucket, leaveStartDate, leaveEndDate, callBack) => {
  var data = {
    status: status,
    emailId: emailId,
    empId: emplId,
    salaryBucket: salaryBucket,
    leaveStartDate: leaveStartDate,
    leaveEndDate: leaveEndDate
  }
  fetch(APIS.SAVE_ASSIGN_BY_STATUS, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}

export const saveUnderWriterApi = (decisionType, allDetail, underWriterStatus, admin, callBack) => {
  var data = {
    decisionType: decisionType,
    loanId: allDetail.loanId,
    userId: allDetail.userId,
    underWriterStatus: underWriterStatus,
    empId: admin.emailId

  }
  fetch(APIS.SAVE_UNDERWRITTER_API, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}
export const newsimilarProfileRedux = (userDetail, callBack) => {
  return dispatch => {
    fetch(APIS.GET_FROUD_DETAIL_NEW + userDetail.userId + '&loanId=' + userDetail.loanId + '&regenerateParam=' + userDetail.regenerateParam)
      .then(res => res.json())
      .then(json => {
        json.userId = userDetail.userId
        dispatch({ type: 'GET_SIMILAR_PROFILE_DATA', internalDedupeData: json })
        callBack(json)
      })
      .catch((e) => {
        callBack('')
      })
  }
}
export const getSmsAnalysisStatusRedux = (
  userId,
  callBack
) => {
  fetch(APIS.GET_SMS_ANALYSIS_STATUS + userId)
    .then(res => res.json())
    .then(json => {
      json.userId = userId
      dispatch({ type: 'GET_SMS_ANALYSIS_DATA', smsAnalysisDataValue: json })
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const repeatUserCheckUpdated = (userId, loanId, callBack) => {
  fetch(APIS.REPEAT_USER_CHECK_UPDATED + userId + '&loanId=' + loanId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}
export const RepeatDataApi = (
  empId,
  callBack
) => {
  fetch(APIS.DOWNLOAD_REPEAT_DATA + empId)
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to download CSV report');
      }
      return response.blob();
    })
    .then(blob => {
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `report_${'Repeat.csv'}`);
      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(url);
      blob ? callBack(blob) : '';
      console.log('CSV report downloaded successfully');

    })
    .catch(error => {
      console.error('Error downloading CSV report:', error);
    });

}


export const checkMobileNumberExistsOrNot = (mobileNo, userId, callBack) => {
  fetch(APIS.CHECK_MOBILE_EXIST + mobileNo + '&userId=' + userId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}

export const readCsvFileLoanId = (file, callBack) => {
  var self = this
  return new Promise((resolve, reject) => {
    var self = this
    let imageFormData = new FormData()
    imageFormData.append('file', file)

    var xhr = new XMLHttpRequest()
    xhr.open('post', APIS.READ_LOAN_ID_CSV, true)
    xhr.onload = function () {
      if (this.status == 200) {
        resolve(this.response)
        callBack(this.response)
      } else {
        reject(this.statusText)
      }
    }
    xhr.send(imageFormData)
  })
}
export const saveCsvFileLoanId = (file, callBack) => {
  var self = this
  return new Promise((resolve, reject) => {
    var self = this
    let imageFormData = new FormData()
    imageFormData.append('file', file)

    var xhr = new XMLHttpRequest()
    xhr.open('post', APIS.SAVE_LOAN_ID_CSV, true)
    xhr.onload = function () {
      if (this.status == 200) {
        resolve(this.response)
        callBack(this.response)
      } else {
        reject(this.statusText)
      }
    }
    xhr.send(imageFormData)
  })
}
export const deleteRazorpayTokenByList = (dataList, callBack) => {
  // let data={
  //   dataList
  // }
  fetch(APIS.DELETE_RAZORPAY_TOKEN, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(dataList)

  })

    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}

export const GetThirdPartyHitsCountByApplicationId = (applicationId, callBack) => {
  fetch(APIS.GET_THIRD_PARTY_HIT_COUNT_BY_APPLICATIONID + applicationId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}
export const getBankVerifyDataApi = (userId, callBack) => {
  fetch(APIS.GET_VERIFIED_BANK_DETAILS_API + userId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}
export const startStopKtcApi = (flag, userType, callBack) => {
  fetch(APIS.KYC_START_STOP_API + flag + '&userType=' + userType)
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}
export const getKycFlagsApi = (callBack) => {
  fetch(APIS.GET_KYC_FLAGS)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}
export const getPendingForKycLeadsApi = (startDate, endDate, pageNo, size, loanStatus, type, callBack) => {
  fetch(APIS.GET_PENDING_KYC_LEADS + startDate + '&endDate=' + endDate + '&pageNo=' + pageNo + '&size=' + size + '&loanStatus=' + loanStatus + '&type=' + type)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}
export const getDigitapBasicData = (userId, callBack) => {
  fetch(APIS.GET_DIGITAB_BASIC_DATA + userId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch(error =>

      callBack('no data'))
}

export const getFraudFilterList = (userId, callBack) => {
  fetch(APIS.GET_FRAUD_FILTER_LIST + userId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch(error =>

      callBack('no data'))
}

export const getDigitapDataByType = (userId, type, subtype, callBack) => {
  fetch(APIS.GET_DIGITAP_DATA_BY_TYPE + userId + "&type=" + type + "&subType=" + subtype)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch(error =>

      callBack('no data'))
}
export const getSourceOfStatementParse = (userId, callBack) => {
  fetch(APIS.GET_BANK_STATEMENT_PARSE_SOURCE + userId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch(error =>

      callBack('no data'))
}

export const downloadKycData = (startDate, endDate, loanStatus, callBack) => {
  fetch(APIS.DOWNLOAD_KYC_PENDING_DATA + startDate + '&endDate=' + endDate + '&loanStatus=' + loanStatus)
    .then(res => res.blob())
    .then(data => {
      var blob = new Blob([data], { type: 'application/vnd.ms-excel' })
      var excelBlob = new Blob(
        [data],
        { type: 'application/vnd.ms-excel' },
        'excel.xls'
      )
      var link = window.URL.createObjectURL(excelBlob)
      window.location = link
      var downloadUrl = URL.createObjectURL(blob)
      var a = document.createElement('a')
      a.href = downloadUrl
      a.download = 'report.xls'
      document.body.appendChild(a)
      a.click()
      callBack(data)
    })
}

export const disburseDataDownloadApi = (startDate, endDate, boxtext, callBack) => {
  console.log(boxtext)
  console.log(APIS.DOWNLOAD_DISBURSE_REPORT + startDate + '&eDate=' + endDate)
  fetch(APIS.DOWNLOAD_DISBURSE_REPORT + startDate + '&eDate=' + endDate)
    .then(res => res.blob())
    .then(data => {
      var blob = new Blob([data], { type: 'application/vnd.ms-excel' })
      var excelBlob = new Blob(
        [data],
        { type: 'application/vnd.ms-excel' },
        'excel.xls'
      )
      var link = window.URL.createObjectURL(excelBlob)
      window.location = link
      var downloadUrl = URL.createObjectURL(blob)
      var a = document.createElement('a')
      a.href = downloadUrl
      a.download = boxtext
      document.body.appendChild(a)
      a.click()
      callBack(data)
    })
}

export const IncomeBreakupDownloadApi = (startDate, endDate, boxtext, callBack) => {
  fetch(APIS.DOWNLOAD_INCOME_BREAKUP_REPORT + startDate + '&eDate=' + endDate)
    .then(res => res.blob())
    .then(data => {
      var blob = new Blob([data], { type: 'application/vnd.ms-excel' })
      var excelBlob = new Blob(
        [data],
        { type: 'application/vnd.ms-excel' },
        'excel.xls'
      )
      var link = window.URL.createObjectURL(excelBlob)
      window.location = link
      var downloadUrl = URL.createObjectURL(blob)
      var a = document.createElement('a')
      a.href = downloadUrl
      a.download = boxtext
      document.body.appendChild(a)
      a.click()
      callBack(data)
    })
}

export const EmiModifyDownloadApi = (startDate, endDate, boxtext, callBack) => {
  fetch(APIS.DOWNLOAD_EMI_MODIFY_REPORT + startDate + '&eDate=' + endDate)
    .then(res => res.blob())
    .then(data => {
      var blob = new Blob([data], { type: 'application/vnd.ms-excel' })
      var excelBlob = new Blob(
        [data],
        { type: 'application/vnd.ms-excel' },
        'excel.xls'
      )
      var link = window.URL.createObjectURL(excelBlob)
      window.location = link
      var downloadUrl = URL.createObjectURL(blob)
      var a = document.createElement('a')
      a.href = downloadUrl
      a.download = boxtext
      document.body.appendChild(a)
      a.click()
      callBack(data)
    })
}

export const EmiCollectionDownloadApi = (startDate, endDate, boxtext, callBack) => {
  fetch(APIS.DOWNLOAD_EMI_COLLECTION_REPORT + startDate + '&eDate=' + endDate)
    .then(res => res.blob())
    .then(data => {
      var blob = new Blob([data], { type: 'application/vnd.ms-excel' })
      var excelBlob = new Blob(
        [data],
        { type: 'application/vnd.ms-excel' },
        'excel.xls'
      )
      var link = window.URL.createObjectURL(excelBlob)
      window.location = link
      var downloadUrl = URL.createObjectURL(blob)
      var a = document.createElement('a')
      a.href = downloadUrl
      a.download = boxtext
      document.body.appendChild(a)
      a.click()
      callBack(data)
    })
}

export const EmiDailyHitDownloadApi = (startDate, endDate, boxtext, callBack) => {
  fetch(APIS.DOWNLOAD_EMI_DAILY_HIT_REPORT + startDate + '&eDate=' + endDate)
    .then(res => res.blob())
    .then(data => {
      var blob = new Blob([data], { type: 'application/vnd.ms-excel' })
      var excelBlob = new Blob(
        [data],
        { type: 'application/vnd.ms-excel' },
        'excel.xls'
      )
      var link = window.URL.createObjectURL(excelBlob)
      window.location = link
      var downloadUrl = URL.createObjectURL(blob)
      var a = document.createElement('a')
      a.href = downloadUrl
      a.download = boxtext
      document.body.appendChild(a)
      a.click()
      callBack(data)
    })
}
export const outstandingReportApi = (disbursedDate, collectionDate, format, status, filename, callBack) => {
  fetch(APIS.GET_OUTSTANDING_DUE_REPORT + disbursedDate + '&collectionDate=' + collectionDate + '&format=' + format + '&status=' + status)
    .then(res => res.blob())
    .then(data => {
      var blob = new Blob([data], { type: 'application/vnd.ms-excel' })
      var excelBlob = new Blob(
        [data],
        { type: 'application/vnd.ms-excel' },
        'excel.xls'
      )
      var link = window.URL.createObjectURL(excelBlob)
      window.location = link
      var downloadUrl = URL.createObjectURL(blob)
      var a = document.createElement('a')
      a.href = downloadUrl
      a.download = filename
      document.body.appendChild(a)
      a.click()
      callBack(data)
    })
}
export const getRejectionReportApi = (startDate, endDate, boxtext, callBack) => {
  fetch(APIS.GET_REJECTION_REPORT + startDate + '&eDate=' + endDate)
    .then(res => res.blob())
    .then(data => {
      var blob = new Blob([data], { type: 'application/vnd.ms-excel' })
      var excelBlob = new Blob(
        [data],
        { type: 'application/vnd.ms-excel' },
        'excel.xls'
      )
      var link = window.URL.createObjectURL(excelBlob)
      window.location = link
      var downloadUrl = URL.createObjectURL(blob)
      var a = document.createElement('a')
      a.href = downloadUrl
      a.download = boxtext
      document.body.appendChild(a)
      a.click()
      callBack(data)
    })
}
export const dailyHitEmiReportApi = (startDate, endDate, boxtext, callBack) => {
  fetch(APIS.GET_DAILY_EMIT_HIT_REPORT + startDate + '&eDate=' + endDate)
    .then(res => res.blob())
    .then(data => {
      var blob = new Blob([data], { type: 'application/vnd.ms-excel' })
      var excelBlob = new Blob(
        [data],
        { type: 'application/vnd.ms-excel' },
        'excel.xls'
      )
      var link = window.URL.createObjectURL(excelBlob)
      window.location = link
      var downloadUrl = URL.createObjectURL(blob)
      var a = document.createElement('a')
      a.href = downloadUrl
      a.download = boxtext
      document.body.appendChild(a)
      a.click()
      callBack(data)
    })
}
export const disbursedCreditReport = (startDate, endDate, boxtext, callBack) => {
  fetch(APIS.GET_DISBURSE_CREDIT_DATA + startDate + '&eDate=' + endDate)
    .then(res => res.blob())
    .then(data => {
      var blob = new Blob([data], { type: 'application/vnd.ms-excel' })
      var excelBlob = new Blob(
        [data],
        { type: 'application/vnd.ms-excel' },
        'excel.xls'
      )
      var link = window.URL.createObjectURL(excelBlob)
      window.location = link
      var downloadUrl = URL.createObjectURL(blob)
      var a = document.createElement('a')
      a.href = downloadUrl
      a.download = boxtext
      document.body.appendChild(a)
      a.click()
      callBack(data)
    })
}
export const getDrpRegisteredNach = (laonSatus, page, callBack) => {
  fetch(APIS.GET_APPLICATION_ID_LIST_REGISTERED_WITH_DRP + laonSatus + "&page=" + page)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch(error =>

      callBack('no data'))
}
export const bulkDeactivateNachDrp = (data, callBack) => {
  var Tosend = {
    userId: data
  }
  console.log(Tosend)
  fetch(APIS.BULK_DEACTIVATE_DRP_NACH, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)

  })

    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}
export const uploadCsvSmsApi = (imageFile, callBack) => {
  var self = this
  return new Promise((resolve, reject) => {
    var self = this
    let imageFormData = new FormData()

    imageFormData.append('file', imageFile)


    var xhr = new XMLHttpRequest()
    xhr.open('post', APIS.UPLOAD_OVERDUE_SMS_CSV, true)
    xhr.onload = function () {
      if (this.status == 200) {
        resolve(this.response)
        callBack(this.response)
      } else {
        reject(this.statusText)
      }
    }
    xhr.send(imageFormData)
  })
}
export const sendOtpBulk = (imageFile, applicationIdList, callBack) => {
  var self = this
  return new Promise((resolve, reject) => {
    var self = this
    let imageFormData = new FormData()

    imageFormData.append('file', imageFile)
    imageFormData.append('applicationIdList', applicationIdList)



    var xhr = new XMLHttpRequest()
    xhr.open('post', APIS.SEND_MSG_BULK, true)
    xhr.onload = function () {
      if (this.status == 200) {
        resolve(this.response)
        callBack(this.response)
      } else {
        reject(this.statusText)
      }
    }
    xhr.send(imageFormData)
  })
}
export const getEmiAmountReceivedDataApi = (startDate, endDate, boxtext, callBack) => {
  fetch(APIS.GET_RECEIVED_EMI_AMOUNT_REPORT + startDate + '&eDate=' + endDate)
    .then(res => res.blob())
    .then(data => {
      var blob = new Blob([data], { type: 'application/vnd.ms-excel' })
      var excelBlob = new Blob(
        [data],
        { type: 'application/vnd.ms-excel' },
        'excel.xls'
      )
      var link = window.URL.createObjectURL(excelBlob)
      window.location = link
      var downloadUrl = URL.createObjectURL(blob)
      var a = document.createElement('a')
      a.href = downloadUrl
      a.download = boxtext
      document.body.appendChild(a)
      a.click()
      callBack(data)
    })
}

// export const GeneratePaymentLink = (obj,nachBounceWaived,penaltyWaived, callBack) => {
//   fetch(APIS.GENERATE_PAYMENT_LINK, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify({
//       loanId: obj.loanId,
//       userId: obj.userId,
//       amount: obj.amount,
//       emiId: obj.emiId,
//       empId: obj.empId,
//       paymentType: obj.paymentType,
//       sendSms: obj.sendSms,
//       sendMail: obj.sendMail,
//       nbfcId: 'RTcTsJVH',
//       penaltyWaived:penaltyWaived,
//       nachBounceWaived:nachBounceWaived
//     })
//   })
//     .then(res => res.json())
//     .then(json => {
//       callBack(json);
//     })
//     .catch(error => {
//       console.error('Error:', error);
//     });
// };
export const GeneratePaymentLink = (obj, nachBounceWaived, penaltyWaived, callBack) => {
  var data = {
    loanId: obj.loanId,
    userId: obj.userId,
    amount: obj.amount,
    emiId: obj.emiId,
    empId: obj.empId,
    paymentType: obj.paymentType,
    sendSms: obj.sendSms,
    sendMail: obj.sendMail,
    nbfcId: 'RTcTsJVH',
    penaltyWaived: penaltyWaived,
    nachBounceWaived: nachBounceWaived
  }
  fetch(APIS.GENERATE_PAYMENT_LINK, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(text => {
      callBack(text)
    })
}



export const GetGeneratedPaymentLinkHistory = (
  loanId,
  callBack
) => {
  fetch(APIS.GET_GENERATED_PAYMENT_LINK_HISTORY + loanId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}

export const DeleteGeneratedPaymentLink = (
  loanId,
  empId,
  callBack
) => {
  fetch(APIS.DELETE_GENERATED_PAYMENT_LINK + loanId + "&empId=" + empId)
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}
export const dailyAccountingDisbuseReport = (startDate, endDate, boxtext, callBack) => {
  fetch(APIS.GET_ACCOUNTING_DISBURSE_DATA + startDate + '&eDate=' + endDate)
    .then(res => res.blob())
    .then(data => {
      var blob = new Blob([data], { type: 'application/vnd.ms-excel' })
      var excelBlob = new Blob(
        [data],
        { type: 'application/vnd.ms-excel' },
        'excel.xls'
      )
      var link = window.URL.createObjectURL(excelBlob)
      window.location = link
      var downloadUrl = URL.createObjectURL(blob)
      var a = document.createElement('a')
      a.href = downloadUrl
      a.download = boxtext
      document.body.appendChild(a)
      a.click()
      callBack(data)
    })
}
export const getBillDeskApi = (callBack) => {
  fetch(APIS.GET_BILL_DESK_LEADS)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch(error =>

      callBack('no data'))
}
export const createInvoiceBilldeskApi = (userData, PaymentDate, callBack) => {
  fetch(APIS.CREATE_INVOICE_BILLDESK + userData.loanId + '&userId=' + userData.userId + '&amount=' + userData.emiAmt + '&emiId=' + userData.emiId + '&paymentDate=' + PaymentDate)
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
    .catch(error =>

      callBack('no data'))
}
export const checkUserDoc = (userId, callBack) => {
  fetch(APIS.CHECK_USER_DOC + userId)
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}
export const stamuploadedhistoryapi = (
  pageNo,
  eDate,
  sDate,
  size,
  callBack
) => {
  fetch(APIS.STAMP_DUTY_UPLOAD_HISTORY + pageNo + '&sDate=' + sDate + '&eDate=' + eDate + '&size=' + size)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const stampUsagehistoryapi = (
  sDate,
  eDate,
  isRepeat,
  pageNo,
  size,
  callBack
) => {
  fetch(APIS.STAMP_USED_HISTORY + sDate + '&eDate=' + eDate + '&isRepeat=' + isRepeat + '&pageNo=' + pageNo + '&size=' + size)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const getAmountPaymentApi = (obj, nachBounceWaived, penaltyWaived, callBack) => {
  var data = {
    loanId: obj.loanId,
    userId: obj.userId,
    amount: obj.amount,
    emiId: obj.emiId,
    empId: obj.empId,
    paymentType: obj.paymentType,
    sendSms: obj.sendSms,
    sendMail: obj.sendMail,
    nbfcId: 'RTcTsJVH',
    penaltyWaived: penaltyWaived,
    nachBounceWaived: nachBounceWaived
  }
  fetch(APIS.GET_CALULATED_AMOUNT, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(text => {
      callBack(text)
    })
}
export const amiAmountReakupApi = (
  loanId,
  callBack
) => {
  fetch(APIS.GET_EMI_BREAKUP_FOR_ONE_EMI + loanId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const getPaymentApi = (
  startDate,
  endDate,
  callBack
) => {
  fetch(APIS.GET_ALL_PAYMENTS_API + startDate + '&endDate=' + endDate)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const getTxnBrreakDownApi = (
  data,

  callBack
) => {
  fetch(APIS.GET_DETAILS_OF_TXN + data.transactionId + '&loanId=' + data.loanId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}

export const getBankstatementStatus = (
  userId,
  callBack
) => {
  fetch(APIS.GET_BANK_STATEMENT_STATUS + userId, {
    method: 'POST',
    headers: POST_HEADER
  })
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}

export const getBankNameList = (
  callBack
) => {
  fetch(APIS.GET_BANK_NAME_LIST)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}

export const getCompleteApplicationCountsApi = (startDate, endDate, callBack) => {

  fetch(APIS.GET_COMPLETE_APPLICATION_STATUS + startDate + '&eDate=' + endDate)
    .then(res => res.json())
    .then(res => {
      callBack(res)
    })
    .catch(error => {
      1
      callBack('')
    })
}


export const getAllAutomatedProcessApi = (callBack) => {

  fetch(APIS.GET_ALL_AUTOMATED_PROCESS)
    .then(res => res.json())
    .then(res => {
      callBack(res)
    })
    .catch(error => {
      // callBack('')
    })
}

export const SaveUpdateAutomatedProcess = (data, callBack) => {

  fetch(APIS.SAVE_UPDATE_AUTOMATED_PROCESS, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(res => {
      callBack(res)
    })
    .catch(error => {
      callBack('')
    })
}

export const UpdatedAutomatedProcessActive = (processName, active, callBack) => {

  fetch(APIS.UPDATE_AUTOMATED_PROCESS_STATUS + processName + '&active=' + active)
    .then(res => res.text())
    .then(res => {
      callBack(res)
    })
    .catch(error => {
      callBack('')
    })
}

export const DeleteProcessByProcessName = (processName, callBack) => {

  fetch(APIS.DELETE_PROCESS_BY_PROCESS_NAME + processName, {
    method: 'DELETE',
  })
    .then(res => res.text())
    .then(res => {
      callBack(res)
    })
    .catch(error => {
      callBack('')
    })
}

export const GetAutomatedProcessByFilter = (type, value, callBack) => {

  fetch(APIS.GET_AUTOMATED_PROCESS_BY_FILTER + type + '=' + value)
    .then(res => res.json())
    .then(res => {
      callBack(res)
    })
    .catch(error => {
      callBack('')
    })
}

export const getAllProcessNames = (callBack) => {

  fetch(APIS.GET_ALL_PROCESS_NAMES)
    .then(res => res.json())
    .then(res => {
      callBack(res)
    })
    .catch(error => {
      callBack('')
    })
}

export const getAllLoanUtility = (pageNo, pageSize, callBack) => {

  fetch(APIS.GET_ALL_LOAN_UTILITY + pageNo + '&pageSize=' + pageSize)
    .then(res => res.json())
    .then(res => {
      callBack(res)
    })
    .catch(error => {
      callBack('')
    })
}

export const SaveUpdateLoanUtility = (data, callBack) => {
  fetch(APIS.SAVE_UPDATE_LOAN_UTILITY, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(res => {
      callBack(res)
    })
    .catch(error => {
      callBack('')
    })
}

export const DeleteLoanUtility = (id, callBack) => {

  fetch(APIS.DELETE_LOAN_UTILITY + id, {
    method: 'DELETE',
  })
    .then(res => res.text())
    .then(res => {
      callBack(res)
    })
    .catch(error => {
      callBack('')
    })
}

export const getLoanUtilityByProductId = (productId, callBack) => {

  fetch(APIS.GET_LOAN_UTILITY_BY_PRODUCTID + productId)
    .then(res => res.json())
    .then(res => {
      callBack(res)
    })
    .catch(error => {
      callBack('')
    })
}

export const getAllProcessingFee = (pageNo, pageSize, callBack) => {

  fetch(APIS.GET_ALL_PROCESSING_FEE + pageNo + '&pageSize=' + pageSize)
    .then(res => res.json())
    .then(res => {
      callBack(res)
    })
    .catch(error => {
      callBack('')
    })
}

export const DeleteProcessingFee = (id, callBack) => {

  fetch(APIS.DELETE_PROCESSING_FEE + id, {
    method: 'DELETE',
  })
    .then(res => res.text())
    .then(res => {
      callBack(res)
    })
    .catch(error => {
      callBack('')
    })
}

export const getProcessingFeeByProductId = (productId, callBack) => {

  fetch(APIS.GET_PROCESSING_FEE_BY_PRODUCTID + productId)
    .then(res => res.json())
    .then(res => {
      callBack(res)
    })
    .catch(error => {
      callBack('')
    })
}

export const SaveUpdateProcessingFee = (data, callBack) => {
  fetch(APIS.SAVE_UPDATE_PROCESSING_FEE, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(res => {
      callBack(res)
    })
    .catch(error => {
      callBack('')
    })
}
export const restartProcessApi = (processName, empId, callBack) => {
  fetch(APIS.RESTART_PROCESS + processName + '&initiatedBy=' + empId)
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
}
export const getLogsByExecutorName = (processName, page, pageSize, callBack) => {
  fetch(APIS.GET_LOGS_BY_EXECUTOR_NAME + processName + '&pageNo=' + page + '&pageSize=' + pageSize)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}
export const saveEmpDataApi = (
  employeeData,
  callBack
) => {


  fetch(APIS.SAVE_EMPLOYEE_DATA_FOR_TARGET, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(employeeData)
  })
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
}
export const nbfcListNew = (callBack) => {

  fetch(APIS.NBFC_DATA_NEW)
    .then(res => res.json())
    .then(res => {
      callBack(res)
    })
    .catch(error => {
      callBack('')
    })
}
export const getEmailKeyData = (callBack) => {

  fetch(APIS.GET_EMAIL_KEYS)
    .then(res => res.json())
    .then(res => {
      callBack(res)
    })
    .catch(error => {
      callBack('')
    })
}
export const deleteEmailKeyData = (key, callBack) => {
  fetch(`${APIS.DELETE_EMAIL_KEY}${key}`, {
    method: 'DELETE',
  })
    .then(res => res.text())
    .then(res => {
      callBack(res);
    })
    .catch(error => {
      console.error('Error deleting email key:', error);
      callBack('');
    });
};
export const getCountSatusWise = (stateDate, endDate, dateType, callBack) => {
  fetch(APIS.APPLICATION_STATUS_COUNT + stateDate + '&endDate=' + endDate + '&dateType=' + dateType)
    .then(res => res.json())
    .then(res => {
      callBack(res)
    })
    .catch(e => callBack(e))
}
export const GetLoanCountByDateNew = (
  startdate,
  enddate,
  callBack
) => {
  fetch(APIS.APPLICATION_STATUS_COUNT + startdate + '&endDate=' + enddate + '&dateType=' + '')
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const getCountsIncomingpaymentApi = (startDate, emiType, callBack) => {
  fetch(APIS.INCOMING_PAYMENT_COUNT + startDate + '&emiType=' + emiType)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch(error => callBack(error))
}
export const emiOverdueListApi = (
  start, end,
  callBack
) => {
  fetch(APIS.EMI_OVERDUE_LIST  + start + '&endDate='+end)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}