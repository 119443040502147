import React,{useState} from 'react'
import moment from "moment";
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
    Scatter, ComposedChart, Line, AreaChart, Area,
} from 'recharts';
import Pagination from '@mui/material/Pagination';
export default function TablePopup({ tablePopupData, close, tabName, tablePopupDataType }) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
      };

      const paginatedData =  tabName == 'DebtManagement' ? tablePopupData?.data?.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      ) : '';
    return (
        <>
            <div className="background-blur " onClick={close}></div>
            {
                tabName == 'DebtManagement' ?
                    <div className="popup-main small-popup container-fluid dbl-border" style={{ marginTop: '-20px', width: '100%' ,maxWidth:'90%'}}>
                        <div className="popup-content income">
                            <h4 className="text-center">{tablePopupData?.month}</h4>
                            <div className="tables">
                                <div className="phocket-table-new newTable2" style={{ overflowY: 'auto', maxHeight: '550px' }}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className="text-center">Debtor Name</th>
                                                <th className="text-center">Outstanding Balance</th>
                                                <th className="text-center">Overdue Amount</th>
                                                <th className="text-center">Principal</th>
                                                <th className="text-center">Interest</th>
                                                <th className="text-center">Penalty</th>
                                                <th className="text-center">Days Overdue</th>
                                                <th className="text-center">EMI Due Date</th>
                                                <th className="text-center">Paid</th>
                                                <th className="text-center">Any EMI Paid</th>
                                                <th className="text-center">Tenure</th>
                                                <th className="text-center">Processing Fee</th>
                                                <th className="text-center">Total Overdue Count</th>
                                                <th className="text-center">Bounce Charge</th>
                                                <th className="text-center">Paid Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paginatedData?.map((data, index) => (
                                                <tr key={index}>
                                                    <td  style={{minWidth:'200px'}}>{data?.debtorName}</td>
                                                    <td className="text-center">{data?.outstandingBalance}</td>
                                                    <td className="text-center">{data?.overdueAmount}</td>
                                                    <td className="text-center">{data?.principal}</td>
                                                    <td className="text-center">{data?.interest}</td>
                                                    <td className="text-center">{data?.penalty}</td>
                                                    <td className="text-center">{data?.daysOverdue}</td>
                                                    <td className="text-center" style={{minWidth:'120px'}}>{data?.emiDueDate}</td>
                                                    <td className="text-center">{data?.paid ? 'Yes' :'No'}</td>
                                                    <td className="text-center">{data?.anyEmiPaid ? 'Yes' :'No'}</td>
                                                    <td className="text-center">{data?.tenure}</td>
                                                    <td className="text-center">{data?.processingFee}</td>
                                                    <td className="text-center">{data?.totalOverdueCount}</td>
                                                    <td className="text-center">{data?.bounceCharge}</td>
                                                    <td className="text-center">{data?.paidAmount}</td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>
                                </div>
                                <div className="" style={{ display: 'flex', justifyContent: 'flex-end',marginTop:'20px' }}>
                <Pagination
                  count={Math.ceil(tablePopupData?.data?.length / itemsPerPage)}
                  page={currentPage} 
                  onChange={handlePageChange} 
                  color="primary"
                />
              </div>
                            </div>
                        </div>
                    </div>
                    : ''}
            {console.log("tablePopupData", tablePopupData)}
            {
                tabName == 'DebtManagementGraph' ?
                    <div className="popup-main small-popup container-fluid dbl-border" style={{ marginTop: '40px', width: '100%' ,maxWidth:'90%'}}>
                        <div className="popup-content income">
                        <div className='text-center'>
                                <h4>{tablePopupData?.month + ' (OverDue Amount Vs Debtor Name)'}</h4>
                            </div>
                            <div className="tables" style={{marginTop:'20px'}}>
                                <div className="phocket-table-new newTable2" style={{ overflowY: 'auto', maxHeight: '400px' }}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className='text-center'>Avg. Overdue</th>
                                                <th className='text-center'>Avg. Overdue Days</th>
                                                <th className='text-center'>Total Overdue</th>
                                                <th className='text-center'>Total Paid</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(tablePopupData?.totalData)?.length > 0 ?
                                                <tr >
                                                    <td className='text-center'>{tablePopupData?.totalData.avgOverdue.toFixed(2)}</td>
                                                    <td className='text-center'>{tablePopupData?.totalData.avgOverdueDays.toFixed(2)}</td>
                                                    <td className='text-center'>{tablePopupData?.totalData.totalOverdue.toFixed(2)}</td>
                                                    <td className='text-center'>{tablePopupData?.totalData.totalOverdue.toFixed(2)}</td>
                                                </tr>
                                            :''}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            

                            <div className="charts" style={{ marginTop: '50px', border: '1px solid rgba(0,0,0,0.25)', borderRadius: '6px', padding: '10px' }}>
                                <div className="chartbar" style={{ height: window?.innerWidth > 1800 ? '600px' : '300px' }}>
                                    <ResponsiveContainer width="100%" height="100%">
                                        {/* <ComposedChart
                                            width={500}
                                            height={150}
                                            data={tablePopupData.data}
                                            barSize={window?.innerWidth > 1800 ? 40 : 20 }
                                            margin={{
                                                top: 20,
                                                right: 0,
                                                left: 0,
                                                bottom: 5,
                                            }}
                                        >
                                            <CartesianGrid stroke='none' />
                                            <XAxis dataKey="debtorName" />
                                            <YAxis />
                                            <Tooltip />
                                            <Bar dataKey="overdueAmount" fill="#8884d8" />
                                            <Line type="monotone" dataKey="overdueAmount" stroke="#223D64" strokeWidth={2} dot={function customDot() { return null }} />
                                        </ComposedChart> */}
                                        <AreaChart
                                            width={500}
                                            height={150}
                                            data={tablePopupData.data}
                                            margin={{
                                                top: 10,
                                                right: 10,
                                                left: 0,
                                                bottom: 0,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="debtorName"/>
                                            <YAxis />
                                            <Tooltip />
                                            <Area type="monotone" dataKey="overdueAmount" stroke="#8884d8" fill="#8884d8" />
                                        </AreaChart>
                                    </ResponsiveContainer>
                                </div>

                            </div>
                        </div>
                    </div>
                    : ''}
            {
                tabName == 'DebtManagementDashboard' ?
                    <div className="popup-main small-popup container-fluid dbl-border" style={{ marginTop: '40px', width: '800px' }}>
                        <div className="popup-content income">
                            <h4 className="text-center">{tablePopupDataType?.name}</h4>
                            <div className="tables">
                                <div className="phocket-table-new newTable2">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Creditors</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tablePopupData?.data?.map((data, index) => (
                                                <tr key={index}>
                                                    <td>{data?.creditors}</td>
                                                    <td>{data?.amount}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''}
            {
                tabName == 'KPILoanManagementDashboard' ?
                    <div className="popup-main small-popup container-fluid dbl-border" style={{ marginTop: '40px', width: '800px' }}>
                        <div className="popup-content income">
                            <div className="tables">
                                <div className="phocket-table-new newTable2">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Reference Number</th>
                                                <th>Customer Name</th>
                                                <th>Date</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tablePopupData?.data?.map((data, index) => (
                                                <tr key={index}>
                                                    <td>{data.referenceNumber}</td>
                                                    <td>{data.name}</td>
                                                    <td>{moment(data.date).format('DD-MM-YYYY')}</td>
                                                    <td>{data.amount}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''}
        </>
    )
}
